import React, { useEffect, useState } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { Segment, Grid, Table, Form, Button, Dropdown, TableRow } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import { getStatistics } from '../../../core/services/golfclub.service';
import { todaysDate, startOfYear, endOfYear, getFormattedNumber } from '../../../utils';
import * as api from '../../../utils/api';
import { ErrorMessage } from '../../ErrorMessage';

function StatisticsReportTab({ translate, active }) {
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState([]);
  const [startDate, setStartDate] = useState(startOfYear(todaysDate()));
  const [endDate, setEndDate] = useState(endOfYear(todaysDate()));
  const [golfClubs, setGolfClubs] = useState([]);
  const [summary, setSummary] = useState(null);
  const [selectedGolfClubs, setSelectedGolfClubs] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    api.fetchGolfClubsSimple().then(res => {
      if (res && res.data) {
        setGolfClubs(
          res.data.map(gc => ({
            key: gc.id,
            text: gc.name,
            value: gc.id
          }))
        );
      }
    });
  }, []);

  const validateDatetimeChange = (_e, { name, value }) => {
    if (name === 'startDate') {
      setStartDate(value);
    } else {
      setEndDate(value);
    }
  };

  const fetchStats = async () => {
    setLoading(true);
    setError(null);

    try {
      const statsData = await getStatistics({
        startDate,
        endDate,
        golfClubIds: selectedGolfClubs
      });

      if ('data' in statsData) {
        const sum = statsData.data.reduce(
          (prev, next) => {
            return {
              bookings: prev.bookings + next.bookings,
              commission: prev.commission + next.commission,
              nights: prev.nights + next.nights,
              price: prev.price + next.price,
              soldBookings: prev.soldBookings + next.soldBookings,
              soldCommission: prev.soldCommission + next.soldCommission,
              soldNights: prev.soldNights + next.soldNights,
              soldPrice: prev.soldPrice + next.soldPrice
            };
          },
          {
            bookings: 0,
            commission: 0,
            nights: 0,
            price: 0,
            soldBookings: 0,
            soldCommission: 0,
            soldNights: 0,
            soldPrice: 0
          }
        );

        setStats(statsData.data);
        setSummary(sum);
      } else if (statsData.error) {
        setError(statsData.error.errorData.title);
      }
    } finally {
      setLoading(false);
    }
  };

  const onGolfClubsChange = (e, data) => {
    setSelectedGolfClubs(data.value);
  };

  if (!active) {
    return null;
  }

  return (
    <Segment>
      <div className="p-1">
        <Form>
          <Form.Group>
            <Form.Field>
              <label>
                <Translate id="default.start-date" />
              </label>
              <DateInput
                closable
                dateFormat="YYYY-MM-DD"
                name="startDate"
                placeholder={translate('default.start-date')}
                value={startDate}
                onChange={validateDatetimeChange}
                localization="sv"
              />
            </Form.Field>
            <Form.Field>
              <label>
                <Translate id="default.end-date" />
              </label>
              <DateInput
                closable
                dateFormat="YYYY-MM-DD"
                minDate={startDate}
                name="endDate"
                placeholder={translate('default.end-date')}
                value={endDate}
                onChange={validateDatetimeChange}
                localization="sv"
              />
            </Form.Field>
            <Form.Field width={8}>
              <label>
                <Translate id="club-admin.report-tabs.clubs" />
              </label>
              <Dropdown
                placeholder="Golf club"
                fluid
                multiple
                search
                selection
                options={golfClubs}
                onChange={onGolfClubsChange}
                value={selectedGolfClubs}
              />
            </Form.Field>
            <Form.Field>
              <div style={{ position: 'absolute', bottom: '0px' }}>
                <Button onClick={fetchStats} disabled={loading}>
                  {translate('default.fetch')}
                </Button>
              </div>
            </Form.Field>
          </Form.Group>
        </Form>

        <ErrorMessage error={error} />

        <Grid>
          <Grid.Column floated="left" width="5">
            <Translate id="club-admin.statistics" />
          </Grid.Column>
        </Grid>

        <Segment loading={loading}>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <Translate id="club-admin.report-tabs.year" />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                  <Translate id="club-admin.report-tabs.bookings" />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                  <Translate id="club-admin.report-tabs.nights" />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                  <Translate id="club-admin.report-tabs.price" />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                  <Translate id="club-admin.report-tabs.commission" />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                  <Translate id="club-admin.report-tabs.sold-bookings" />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                  <Translate id="club-admin.report-tabs.sold-nights" />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                  <Translate id="club-admin.report-tabs.sold-price" />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                  <Translate id="club-admin.report-tabs.sold-commission" />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {stats?.map((stat, i) => (
                <Table.Row key={i}>
                  <Table.Cell>{stat.year}</Table.Cell>
                  <Table.Cell textAlign="right">{stat.bookings}</Table.Cell>
                  <Table.Cell textAlign="right">{stat.nights}</Table.Cell>
                  <Table.Cell singleLine textAlign="right">
                    {getFormattedNumber(stat.price)}
                  </Table.Cell>
                  <Table.Cell singleLine textAlign="right">
                    {getFormattedNumber(stat.commission)}
                  </Table.Cell>
                  <Table.Cell textAlign="right">{stat.soldBookings}</Table.Cell>
                  <Table.Cell textAlign="right">{stat.soldNights}</Table.Cell>
                  <Table.Cell singleLine textAlign="right">
                    {getFormattedNumber(stat.soldPrice)}
                  </Table.Cell>
                  <Table.Cell singleLine textAlign="right">
                    {getFormattedNumber(stat.soldCommission)}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            {summary && (
              <Table.Footer>
                <TableRow>
                  <Table.Cell>&nbsp;</Table.Cell>
                  <Table.Cell textAlign="right">
                    <strong>{summary.bookings}</strong>
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <strong>{summary.nights}</strong>
                  </Table.Cell>
                  <Table.Cell singleLine textAlign="right">
                    <strong>{getFormattedNumber(summary.price)}</strong>
                  </Table.Cell>
                  <Table.Cell singleLine textAlign="right">
                    <strong>{getFormattedNumber(summary.commission)}</strong>
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <strong>{summary.soldBookings}</strong>
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <strong>{summary.soldNights}</strong>
                  </Table.Cell>
                  <Table.Cell singleLine textAlign="right">
                    <strong>{getFormattedNumber(summary.soldPrice)}</strong>
                  </Table.Cell>
                  <Table.Cell singleLine textAlign="right">
                    <strong>{getFormattedNumber(summary.soldCommission)}</strong>
                  </Table.Cell>
                </TableRow>
              </Table.Footer>
            )}
          </Table>
        </Segment>
      </div>
    </Segment>
  );
}

export default withLocalize(StatisticsReportTab);
