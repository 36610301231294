import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { Button, Dimmer, Icon, Loader, Modal, Popup, Table } from 'semantic-ui-react';
import {
  calculatePackagePrice,
  getAvailablePackages,
  IPackageSelect,
  IPackageSelectProductAddon,
  IPackageSelectPricing
} from '../../core/services/product.service';
import { IBooking } from '../../pages/AccommodationOverView';
import CountSelector from '../CountSelector';

export interface PackageSelectModalProps extends LocalizeContextProps {
  booking: IBooking;
  golfClubCurrency: string;
  checkinDay: number;
  isHoliday: boolean;
  onClose(): void;
  onConfirm(selectedPackage: IPackageSelect[]): void;
  show: boolean;
}

function PackageSelectModal(props: PackageSelectModalProps) {
  const [activeRequest, setActiveRequest] = useState<boolean>(false);
  const [bookingPackages, setBookingPackages] = useState<IPackageSelect[]>([]);
  const [packagesPrice, setPackagesPrice] = useState(0);

  const { checkinDay } = props;

  const nights = moment(props.booking.checkOutDate).diff(moment(props.booking.checkInDate), 'days');

  const calculatePackagesPrice = (packages: IPackageSelect[]) => {
    let newPrice = 0;

    const selectedPackages = packages.filter(p => p.count > 0);
    if (selectedPackages) {
      selectedPackages.forEach(p => {
        const packagePrice = calculatePackagePrice(p, props.booking.checkInDate, props.isHoliday);

        p.price = packagePrice;
        newPrice += packagePrice;
      });
    }

    setPackagesPrice(newPrice);
  };

  useEffect(() => {
    if (props.show) {
      setActiveRequest(true);

      getAvailablePackages(
        props.booking.checkInDate,
        props.booking.checkOutDate,
        props.booking.accommodation.priceCategoryId
      ).then(results => {
        results.forEach(res => {
          const pkg = props.booking.packages?.find(p => p.id === res.id);

          res.count = pkg?.count || 0;
        });

        setBookingPackages(results);

        setActiveRequest(false);
      });
    }
  }, [
    props.show,
    props.booking.accommodation.priceCategoryId,
    props.booking.checkInDate,
    props.booking.checkOutDate,
    props.booking.packages
  ]);

  useEffect(() => {
    if (bookingPackages) {
      let newPrice = 0;

      const selectedPackages = bookingPackages.filter(p => p.count > 0);
      if (selectedPackages) {
        selectedPackages.forEach(
          p => (newPrice += calculatePackagePrice(p, props.booking.checkInDate, props.isHoliday))
        );
      }

      setPackagesPrice(newPrice);
    }
  }, [bookingPackages, props.booking.checkInDate, props.isHoliday]);

  const onPackageCountValueChanged = (e: React.FormEvent<HTMLInputElement>, addedPackage: IPackageSelect) => {
    // need to clone the addons
    if (bookingPackages) {
      const oldPackages = [...bookingPackages];

      const oldIndex = oldPackages.findIndex(a => a.id === addedPackage.id);
      if (oldIndex !== -1) {
        oldPackages[oldIndex].count = Number(e.currentTarget.value);

        calculatePackagesPrice(oldPackages);
        setBookingPackages(oldPackages);
      }
    }
  };

  const onPackageCountIncreased = (addedPackage: IPackageSelect) => {
    // need to clone the addons
    if (bookingPackages) {
      const oldPackages = [...bookingPackages];

      const oldIndex = oldPackages.findIndex(a => a.id === addedPackage.id);
      if (oldIndex !== -1) {
        oldPackages[oldIndex].count += 1;

        calculatePackagesPrice(oldPackages);
        setBookingPackages(oldPackages);
      }
    }
  };

  const onPackageCountDecreased = (addedPackage: IPackageSelect) => {
    // need to clone the addons
    if (bookingPackages) {
      const oldPackages = [...bookingPackages];

      const oldIndex = oldPackages.findIndex(a => a.id === addedPackage.id);
      if (oldIndex !== -1) {
        oldPackages[oldIndex].count -= 1;

        calculatePackagesPrice(oldPackages);
        setBookingPackages(oldPackages);
      }
    }
  };

  const getPriceString = (selPackage: IPackageSelect, pricing: IPackageSelectPricing): string => {
    if (!pricing) {
      return '';
    }

    // do we have high season pricing and the checkin date is within high season?
    if (selPackage.highSeasonStart && pricing.highSeasonPrice) {
      const checkIn = moment(props.booking.checkInDate.substring(0, 10));
      const start = moment(selPackage.highSeasonStart.substring(0, 10));
      const end = moment(selPackage.highSeasonEnd.substring(0, 10));

      // we do, return high season price
      if (checkIn.isBetween(start, end, 'days', '[]')) {
        return pricing.highSeasonPrice.toFixed(2).toString().replace('.', ',');
      }
    }

    return pricing.price.toFixed(2).toString().replace('.', ',');
  };

  const getPackagePrice = (selPackage: IPackageSelect): string => {
    let price: string = '';

    if (selPackage.chargeType === 0) {
      const pricing = selPackage.pricings.find(pr => pr.minCount <= nights && pr.maxCount >= nights);
      if (pricing) {
        price = getPriceString(selPackage, pricing);
      }
    } else if (selPackage.chargeType === 1) {
      const pricing = selPackage.pricings.find(pr => pr.minCount === selPackage.count);
      if (pricing) {
        price = getPriceString(selPackage, pricing);

        return `${price} ${props.golfClubCurrency} / ${props.translate('packages.per-person')}`;
      }

      return '';
    } else if (selPackage.chargeType === 2) {
      if (props.isHoliday) {
        const pricing = selPackage.pricings.find(pr => pr.minCount === 8);
        if (pricing) {
          price = getPriceString(selPackage, pricing);
        }
      }

      if (!price) {
        // no holiday pricing, try to find common "by checkin day" pricing
        const pricing = selPackage.pricings.find(pr => pr.minCount <= checkinDay && pr.maxCount >= checkinDay);
        if (pricing) {
          price = getPriceString(selPackage, pricing);
        }
      }
    }

    if (price) {
      return `${price} ${props.golfClubCurrency}`;
    }

    return 'NO PRICE';
  };

  const { translate } = props;

  return (
    <Modal centered open={props.show} size="large" className="modal-compact">
      <Modal.Header>
        <Translate id="packages.modal-title" /> ({nights}{' '}
        {translate(`packages.${nights === 1 ? 'night' : 'nights'}`)
          .toString()
          .toLocaleLowerCase()}
        , {props.isHoliday ? 'holiday ' : moment.weekdays(checkinDay)}{' '}
        {translate('default.checkin').toString().toLocaleLowerCase()})
      </Modal.Header>
      <Modal.Content>
        <Dimmer active={activeRequest} inverted>
          <Loader />
        </Dimmer>
        <Table>
          <Table.Header className="mobile-hidden">
            <Table.Row>
              <Table.HeaderCell>
                <Translate id="product-addons.headers.name" /> / <Translate id="packages.headers.addons" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Translate id="product-addons.headers.price" />
              </Table.HeaderCell>
              <Table.HeaderCell style={{ width: '25%' }}>&nbsp;</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {bookingPackages?.map((bookingPackage: IPackageSelect) => (
              <Table.Row key={bookingPackage.id}>
                <Table.Cell verticalAlign="top">
                  {bookingPackage.name}
                  {bookingPackage.description && (
                    <Popup trigger={<Icon name="info circle" />}>{bookingPackage.description}</Popup>
                  )}
                  <div style={{ paddingLeft: 20 }}>
                    {bookingPackage.productAddons.map((addon: IPackageSelectProductAddon) => (
                      <p key={addon.id} style={{ margin: 0 }}>{`${addon.count}x ${translate(
                        `product-addons.type-names.${addon.name}`
                      )}`}</p>
                    ))}
                  </div>
                </Table.Cell>
                <Table.Cell verticalAlign="top">{getPackagePrice(bookingPackage)}</Table.Cell>
                {false && (
                  <Table.Cell verticalAlign="top">
                    <span style={{ width: '55%', display: 'inline-block', fontWeight: 'bold' }}>
                      {bookingPackage.chargeType === 0
                        ? translate('packages.headers.nights')
                        : bookingPackage.chargeType === 2
                        ? translate('packages.headers.checkin-day')
                        : translate('packages.headers.persons')}
                    </span>
                    <span style={{ width: '25%', display: 'inline-block', fontWeight: 'bold', textAlign: 'center' }}>
                      {translate('default.price')}
                    </span>
                    {bookingPackage.pricings.map(pricing => (
                      <div key={pricing.minCount}>
                        <span
                          style={{
                            width: '55%',
                            display: 'inline-block',
                            whiteSpace: 'nowrap',
                            fontWeight:
                              (bookingPackage.chargeType === 0 &&
                                pricing.minCount <= nights &&
                                pricing.maxCount >= nights) ||
                              (props.isHoliday && pricing.minCount === 8) ||
                              (!props.isHoliday && pricing.minCount <= checkinDay && pricing.maxCount >= checkinDay)
                                ? 'bold'
                                : 'normal'
                          }}
                        >
                          {bookingPackage.chargeType === 2
                            ? pricing.minCount === 8
                              ? translate('default.public-holidays')
                              : moment.weekdays(pricing.minCount)
                            : pricing.minCount}{' '}
                          {bookingPackage.chargeType === 0 ? `- ${pricing.maxCount}` : ''}
                          {bookingPackage.chargeType === 2 && pricing.maxCount !== 8
                            ? `- ${moment.weekdays(pricing.maxCount)}`
                            : ''}
                        </span>
                        <span
                          style={{
                            width: '25%',
                            textAlign: 'right',
                            display: 'inline-block',
                            fontWeight:
                              (bookingPackage.chargeType === 0 &&
                                pricing.minCount <= nights &&
                                pricing.maxCount >= nights) ||
                              (props.isHoliday && pricing.minCount === 8) ||
                              (!props.isHoliday && pricing.minCount <= checkinDay && pricing.maxCount >= checkinDay)
                                ? 'bold'
                                : 'normal',
                            whiteSpace: 'nowrap'
                          }}
                        >
                          {`${pricing.price} ${props.golfClubCurrency}`}
                        </span>
                      </div>
                    ))}
                  </Table.Cell>
                )}
                <Table.Cell singleLine verticalAlign="top" textAlign="right">
                  <CountSelector
                    item={bookingPackage}
                    minusClick={onPackageCountDecreased}
                    minusDisabled={bookingPackage.count === 0}
                    name="count"
                    plusClick={onPackageCountIncreased}
                    plusDisabled={
                      bookingPackage.chargeType === 1 &&
                      bookingPackage.count === Math.max(...bookingPackage.pricings.map(o => o.minCount))
                    }
                    value={bookingPackage.count}
                    valueChanged={onPackageCountValueChanged}
                    valueMin="0"
                    valueMax={bookingPackage.chargeType === 1 ? '4' : ''}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={props.onClose}>
          <Translate id="default.close" />
        </Button>
        <Button
          primary
          onClick={() => {
            props.onConfirm(bookingPackages);
          }}
        >
          <Translate id="packages.confirm-package" data={{ amount: packagesPrice, currency: props.golfClubCurrency }} />
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default withLocalize(PackageSelectModal);
