import React, { useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import {
  Button,
  Icon,
  //   Image,
  List,
  ListContent,
  //  ListDescription,
  ListHeader,
  ListItem,
  Modal,
  Segment
} from 'semantic-ui-react';
import { accommodationBookingService } from '../core/services';
import { ErrorMessage } from './ErrorMessage';
import { PriceType } from '../core/enums';
import { getBookingPrice } from '../utils';
import { CaravanIcon } from '../utils/icons';

function AccommodationSearchResults({ activeRequest, dateRange, golfClubCurrency, golfClubId, onClickItem, onClose }) {
  const [accommodationData, setAccommodationData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const extractavailabilityData = availability =>
    availability?.accommodations.map(t => ({
      id: t.accommodation.id,
      name: t.accommodation.name,
      advanceDays: t.accommodation.advanceDays,
      available: t.available,
      hasElectricity: t.accommodation.hasElectricity,
      hasDrain: t.accommodation.hasDrain,
      numberOfBeds: t.accommodation.numberOfBeds,
      accommodationTypeId: t.accommodation.typeId,
      price: getBookingPrice({
        availability,
        priceCategoryId: t.accommodation.priceCategoryId,
        priceType: PriceType.SELL
      }),
      info: t.accommodation.info
    }));

  useEffect(() => {
    async function getAccommodations() {
      try {
        setLoading(true);
        setError(null);

        const { error: fetchError, data: availability } = await accommodationBookingService.getAvailableAccommodations(
          golfClubId,
          dateRange.startDate.format('YYYY-MM-DD'),
          dateRange.endDate.format('YYYY-MM-DD'),
          true
        );

        if (fetchError) {
          setError(fetchError);
        } else {
          const formattedAvailabilityData = extractavailabilityData(availability);

          setAccommodationData(formattedAvailabilityData.filter(acc => acc.available));
        }
      } finally {
        setLoading(false);
      }
    }

    if (dateRange && dateRange.startDate && dateRange.endDate) {
      getAccommodations();
    }
  }, [dateRange, golfClubId]);

  return (
    <Modal open size="large" className="position-unset">
      <Modal.Header>
        <Translate id="accommodation.available-for" /> {dateRange.startDate.format('YYYY-MM-DD')} -{' '}
        {dateRange.endDate.format('YYYY-MM-DD')}
        <small style={{ fontWeight: 'normal', fontSize: 14, display: 'block' }}>
          <Translate id="accommodation.available-info" />
        </small>
      </Modal.Header>
      <Modal.Content scrolling>
        <ErrorMessage error={error} />
        <Segment loading={loading || activeRequest}>
          {accommodationData && Array.isArray(accommodationData) && (
            <List size="large" divided animated relaxed verticalAlign="middle">
              {accommodationData.map(accommodation => (
                <ListItem onClick={() => onClickItem(accommodation.id, dateRange)} key={accommodation.id}>
                  <ListContent floated="right">
                    <Button>
                      {accommodation.price.price} {golfClubCurrency}
                    </Button>
                  </ListContent>
                  <ListContent floated="right">
                    {accommodation.numberOfBeds && (
                      <>
                        {accommodation.numberOfBeds}x <Icon name="bed" />
                      </>
                    )}
                    {accommodation.hasElectricity && (
                      <>
                        <Icon name="plug" />
                      </>
                    )}
                    {accommodation.hasDrain && (
                      <>
                        <Icon name="tint" />
                      </>
                    )}
                  </ListContent>
                  <ListContent floated="left" verticalAlign="middle">
                    {accommodation.accommodationTypeId === 1 ? (
                      <CaravanIcon />
                    ) : accommodation.accommodationTypeId === 7 ? (
                      <Icon name="bed" color="black" />
                    ) : (
                      <Icon name="home" color="black" />
                    )}
                  </ListContent>
                  <ListContent floated="left">
                    <ListHeader>{accommodation.name}</ListHeader>
                    {accommodation.info}
                  </ListContent>
                </ListItem>
              ))}
            </List>
          )}
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>
          <Translate id="default.close" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default AccommodationSearchResults;
