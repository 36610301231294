import moment from 'moment';
import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { Flag } from 'semantic-ui-react';

function LanguageSwitcher({ setActiveLanguage }) {
  // TODO: persist current language
  const switchLanguage = language => {
    setActiveLanguage(language);

    moment.locale(language);
  };
  return (
    <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
      <Flag name="se" onClick={() => switchLanguage('sv')} />
      <Flag name="uk" onClick={() => switchLanguage('en')} />
      <Flag name="de" onClick={() => switchLanguage('de')} />
    </div>
  );
}

export default withLocalize(LanguageSwitcher);
