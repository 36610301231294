import React, { useEffect, useState } from 'react';
import {
  Loader,
  Button,
  Modal,
  Form,
  Input,
  Search,
  Label,
  Grid,
  Icon,
  Radio,
  Segment,
  SegmentGroup
} from 'semantic-ui-react';
import { Translate } from 'react-localize-redux';
import qs from 'qs';
import moment from 'moment';
import { Buffer } from 'buffer';
import { debounce, isEqual } from 'lodash-es';
import { ErrorMessage } from '../ErrorMessage';
import {
  createCallbackUrl,
  emailRegex,
  formatSwedishPhoneNumber,
  golfIdRegex,
  isValidSwedishPhoneNumber
} from '../../utils';
import { PAYMENT_ERROR, RentalUnitType } from '../../core/constants';
import { ActiveRequest, BookingStatus, PriceType, Source, TransactionType } from '../../core/enums';
import SwishLogo from '../../assets/swish-logo.svg';
import CardsLogo from '../../assets/cards.svg';
import {
  processOrder,
  refundTransaction,
  fetchAccommodationOrder,
  cancelInvoice,
  resendInvoice,
  cancelCustomerOrder
} from '../../core/services';
import Transactions from '../Orders/Transactions';
import PaymentActions from '../Orders/PaymentActions';
import Booking from './Booking';
import LanguageSwitcher from '../LanguageSwitcher';
import useIsAppleMobileDevice from '../../utils/useIsAppleMobileDevice';

export const hasOrderChanged = (order, customer) => {
  if (
    order === null &&
    customer?.usedId === undefined &&
    customer?.name === '' &&
    customer?.email === '' &&
    customer?.phone === ''
  ) {
    // new prebooked booking, no change so far
    return false;
  }

  const isChanged =
    (order?.customerName || '') !== (customer?.name || '') ||
    (order?.customerEmail || '') !== (customer?.email || '') ||
    (order?.customerPhone || '') !== (customer?.phone || '') ||
    (order?.customerUserId || '') !== (customer?.userId || '') ||
    (order &&
      order.orderItems &&
      order.orderItems.some(item => item.accommodationBooking && item.accommodationBooking.isChanged));

  return isChanged;
};

export const getOrderChanges = (order, customer, translate) => {
  let message = '<div><p>' + translate('edit-booking.changes-not-saved') + '</p><ul>';

  message +=
    (order?.customerName || '') !== (customer?.name || '') ||
    (order?.customerEmail || '') !== (customer?.email || '') ||
    (order?.customerPhone || '') !== (customer?.phone || '') ||
    (order?.customerUserId || '') !== (customer?.userId || '')
      ? '<li>' + translate('edit-booking.customer-information') + '</li>'
      : '';

  const items = order?.orderItems.filter(item => item.accommodationBooking?.isChanged);

  if (items && items.length > 0) {
    message += '<li>' + translate('edit-booking.bookings');

    items.forEach(item => (message += ` ${item.accommodationBooking.id}, `));

    message = message.replace(/,\s*$/, '');

    message += '</ul>';
    message += '</li>';
  }

  message += '</ul></div>';
  return message;
};

export const hasBookingChanged = (parentBooking, activeBooking, customer, parentBookingDiscount, bookingDiscount) =>
  parentBooking.checkInDate !== activeBooking.checkInDate ||
  parentBooking.checkOutDate !== activeBooking.checkOutDate ||
  parentBooking.accommodation.id !== activeBooking.accommodation.id ||
  parentBooking.paid !== activeBooking.paid ||
  parentBooking.checkedIn !== activeBooking.checkedIn ||
  parentBooking.priceType !== activeBooking.priceType ||
  parentBookingDiscount?.discount.id !== bookingDiscount?.discount.id ||
  parentBooking.comment !== activeBooking.comment ||
  /* parentBooking.bookingUser.name !== customer.name ||
  parentBooking.bookingUser.email !== customer.email ||
  parentBooking.bookingUser.golfId !== customer.golfId ||
  parentBooking.bookingUser.phone !== customer.phone ||
  parentBooking.bookingUser.plateNumber !== customer.plateNumber ||
  parentBooking.numberOfGuests !== activeBooking.numberOfGuests ||
  */
  isEqual(parentBooking.productAddons, activeBooking.productAddons) === false;

export const getBookingChanges = (parentBooking, activeBooking, customer) => {
  const [parentBookingDiscount] = parentBooking.accommodationBookingDiscounts;
  const [bookingDiscount] = activeBooking.accommodationBookingDiscounts;

  let message = '<div><p>Följande ändringar kommer inte sparas</p><ul>';
  message += parentBooking.checkInDate !== activeBooking.checkInDate ? '<li>Incheckningsdatum</li>' : '';
  message += parentBooking.checkOutDate !== activeBooking.checkOutDate ? '<li>Utcheckningsdatum</li>' : '';
  message += parentBooking.accommodation.id !== activeBooking.accommodation.id ? '<li>Objekttyp</li>' : '';
  message += parentBooking.priceType !== activeBooking.priceType ? '<li>Pristyp</li>' : '';
  message += parentBookingDiscount?.discount.id !== bookingDiscount?.discount.id ? '<li>Rabattkod</li>' : '';
  message += parentBooking.paid !== activeBooking.paid ? '<li>Betalningsstatus</li>' : '';
  message += parentBooking.checkedIn !== activeBooking.checkedIn ? '<li>Incheckningsstatus</li>' : '';
  message += parentBooking.comment !== activeBooking.comment ? '<li>Kommentar</li>' : '';
  message +=
    parentBooking.bookingUser.name !== customer.name ||
    parentBooking.bookingUser.email !== customer.email ||
    parentBooking.bookingUser.golfId !== customer.golfId ||
    parentBooking.bookingUser.phone !== customer.phone ||
    parentBooking.bookingUser.plateNumber !== customer.plateNumber
      ? '<li>Kundinformation</li>'
      : '';

  message += isEqual(parentBooking.productAddons, activeBooking.productAddons) ? '' : '<li>Tillägg</li>';

  message += '</ul></div>';
  return message;
};

export const getAvailableObjects = (availability, booking) => {
  const avs = availability.accommodations.map(a => ({
    text: a.accommodation.name,
    value: a.accommodation.id,
    disabled: !(
      a.available ||
      // If only one booking exists and it's the active booking then count object as available
      (a.accommodation.id === booking?.accommodation.id && a.bookings.length === 1 && a.bookings[0].id === booking?.id)
    ),
    golfersonly: availability.pricings.find(p =>
      p.pricings.find(pr => pr.priceCategory.id === a.accommodation.priceCategoryId && pr.isGolfersOnly)
    )
      ? 1
      : 0
  }));

  return avs;
};

let searchablePlayers = [];

function BookingModalView({
  activeBooking,
  golfclub,
  publicHolidays,
  updateBooking,
  onClose,
  error,
  setError,
  isWidgetRequest,
  translate,
  pendingTransaction,
  activeOrder,
  switchBooking,
  setActiveOrder,
  onWidgetPaymentOptionsClose = null,
  isCustomerDeleteRequest = false,
  svgData = null,
  token = null,
  enableSwishPayment = false,
  isSuperAdmin
}) {
  const isAppleMobileDevice = useIsAppleMobileDevice();

  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [bookingChanged, setBookingChanged] = useState(false);
  const [confirmCancelBooking, setConfirmCancelBooking] = useState(false);
  const [confirmCancelInvoiceId, setConfirmCancelInvoiceId] = useState(null);
  const [confirmCancelOrderBookingId, setConfirmCancelOrderBookingId] = useState(null);
  const [confirmCloseWhenChanged, setConfirmCloseWhenChanged] = useState(null);
  const [customer, setCustomer] = useState({ name: '', email: '', golfId: '', phone: '', plateNumber: '' });
  const [customerError, setCustomerErrors] = useState({ name: false, email: false, golfId: false });
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [noGolfId, setNoGolfId] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [activeRequest, setActiveRequest] = useState(null);
  const [openActionsModal, setOpenActionsModal] = useState(false);
  const [openTransactionModal, setOpenTransactionModal] = useState(false);
  const [isCancellingBooking, setIsCancellingBooking] = useState(false);
  const [orderPrice, setOrderPrice] = useState(0);
  const [isChanged, setIsChanged] = useState(false);
  const [isGolfersOnly, setIsGolfersOnly] = useState(null);
  const [bookingChangeInfo, setBookingChangeInfo] = useState({});
  const [processingRefund, setProcessingRefund] = useState(null);
  const [showWidgetPaymentOptions, setShowWidgetPaymentOptions] = useState(null);
  const [swishPhoneNumber, setSwishPhoneNumber] = useState(activeOrder?.customerPhone || customer?.phone || '');
  const [swishPaymentType, setSwishPaymentType] = useState('qrCode');
  const [internalSvgData, setInternalSvgData] = useState(null);

  const callbackUrl = createCallbackUrl(window.location.href);

  useEffect(() => {
    if (isAppleMobileDevice) {
      setSwishPaymentType('phoneNumber');
    }
  }, [isAppleMobileDevice]);

  useEffect(() => {
    // Format users data to fit search field
    searchablePlayers = golfclub.players.map(p => ({
      key: p.id,
      title: `${p.player.firstName} ${p.player.lastName}`,
      searchTitle: `${p.player.firstName?.toLowerCase()} ${p.player.lastName?.toLowerCase()}`,
      description: p.player.email
    }));

    if (activeBooking && activeBooking.source === Source.WIDGET && !isWidgetRequest) {
      // for widget bookings in admin UI we need to set noGolfId to select correct price
      setNoGolfId(!activeBooking.bookingUser.golfId);
    }

    // setActiveRequest(null);
  }, [activeBooking, golfclub, isWidgetRequest]);

  useEffect(() => {
    const bookingUser = {
      userId: activeOrder?.customerUserId,
      golfId: '',
      name: activeOrder?.customerName || '',
      email: activeOrder?.customerEmail || '',
      phone: activeOrder?.customerPhone || ''
    };

    /*
    if (!bookingUser.golfId && isWidgetRequest && pendingTransaction) {
      setNoGolfId(true);
    }
    */

    if (activeOrder?.orderItems) {
      const act = activeOrder.orderItems[0].accommodationBooking;

      bookingUser.plateNumber = act.bookingUser.plateNumber;
      bookingUser.golfId = act.bookingUser.golfId;
    }

    setCustomer(bookingUser);

    const orderTransactionDiffExists = activeOrder?.orderTotal !== activeOrder?.transactionTotal;
    const isNotPrebooked =
      activeOrder?.orderItems &&
      activeOrder?.orderItems.every(item => item.accommodationBooking.status !== BookingStatus.PREBOOKED);
    const showPaymentView =
      !isWidgetRequest &&
      orderTransactionDiffExists &&
      golfclub.useOnlinePayment &&
      isNotPrebooked &&
      activeBooking.status !== BookingStatus.CANCELLED;
    setOpenActionsModal(showPaymentView);
  }, [activeOrder, activeBooking, golfclub.useOnlinePayment, isWidgetRequest, pendingTransaction]);

  useEffect(() => {
    validateCustomer(customer, noGolfId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, noGolfId]);

  useEffect(() => {
    getOrderPrice();

    validateBookingChanged();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBooking, customer]);

  useEffect(() => {
    if (error) {
      setActiveRequest(null);
    }
  }, [error]);

  useEffect(() => {
    function doCallback() {
      const original_start = new Date();
      const lag = 1250;

      const deeplink_url = `swish://paymentrequest?token=${token}&callbackurl=${callbackUrl}";`;
      window.location.href = deeplink_url;

      setTimeout(function () {
        const time_spent_waiting = new Date() - original_start;
        if (time_spent_waiting > 2.0 * lag) {
          // We can assume they have the app, so do nothing.
        } else {
          // That was too fast so we can assume they don't have the app.
          setInternalSvgData(svgData);
        }
      }, lag);
      return false;
    }

    if (svgData) {
      doCallback();
    }
  }, [callbackUrl, svgData, token]);

  const validateBookingChanged = () => {
    setBookingChanged(hasOrderChanged(activeOrder, customer));
  };

  const validateCustomer = (cust, noGID) => {
    setCustomerErrors({
      name: cust.name?.length < 3 || cust.name === '' || cust.name === undefined,
      email: cust.email?.length === 0 || !emailRegex.test(cust.email),
      phone: isWidgetRequest && (cust.phone?.length < 7 || cust.phone === '' || cust.phone === undefined),
      golfId:
        (!isWidgetRequest && cust.golfId && !golfIdRegex.test(cust.golfId)) ||
        (isWidgetRequest && !noGID && !cust.golfId)
    });
  };

  const customerNameChanged = (event, { value }) => {
    const searchValue = (value || '').toLowerCase();
    const customers = searchablePlayers
      .filter(p => p.searchTitle.includes(searchValue))
      .map(item => ({
        key: item.key,
        title: item.title,
        description: item.description
      }));
    const updatedCustomer = { ...customer, name: value, userId: null };
    setCustomer(updatedCustomer);
    setFilteredCustomers(customers);

    onBookingChanged('customer');
  };

  const customerEmailChanged = (event, { value }) => {
    const customers = searchablePlayers
      .filter(p => p.description.includes(value))
      .map(item => ({
        key: item.key,
        title: item.title,
        description: item.description
      }));
    const updatedCustomer = { ...customer, email: value, userId: null };
    setCustomer(updatedCustomer);
    setFilteredCustomers(customers);
    onBookingChanged('customer');
  };

  const customerSelected = (e, { result }) => {
    const selectedCustomer = golfclub.players.find(p => p.id === result.key);
    setCustomer({
      userId: selectedCustomer.userId,
      name: `${selectedCustomer.player.firstName} ${selectedCustomer.player.lastName}`,
      email: selectedCustomer.player.email || '',
      golfId: selectedCustomer.player.golfId || '',
      phone: selectedCustomer.player.phone || '',
      plateNumber: selectedCustomer.player.plateNumber || ''
    });

    onBookingChanged('customer');
  };

  const customerDataChanged = (e, { name, value }) => {
    setCustomer({ ...customer, [name]: value });
    onBookingChanged('customer');
  };

  const swishPhoneNumberChanged = (event, { value }) => {
    setSwishPhoneNumber(value);

    validatePhoneNumber(formatSwedishPhoneNumber(value));
  };

  const validatePhoneNumber = phoneNumber => {
    const isValid = isValidSwedishPhoneNumber(phoneNumber);

    setPhoneNumberError(!isValid);
  };

  const swishPaymentTypeChanged = (event, { value }) => {
    setSwishPaymentType(value);

    if (value === 'phoneNumber') {
      validatePhoneNumber(formatSwedishPhoneNumber(swishPhoneNumber));
    }
  };

  const btnCloseClicked = () => {
    if (!hasOrderChanged(activeOrder, customer) || isWidgetRequest) {
      onClose();
      return;
    }

    const message = getOrderChanges(activeOrder, customer, translate);
    setConfirmCloseWhenChanged(message);
  };

  const onUpdateBookingClick = async (status, keepOrder, isOrderConfirmed, transactionType) => {
    if (activeBooking.hasErrors) {
      setError('Please correct booking errors first');
      return;
    }

    if (activeOrder && status === BookingStatus.UPDATED) {
      // this runs just for the complete order update
      const bookingsToUpdate = activeOrder.orderItems.filter(orderItem => orderItem.accommodationBooking?.isChanged);

      setActiveRequest(ActiveRequest.BOOKING_UPDATE);

      for (const item of bookingsToUpdate) {
        await updateBooking({
          status: item.accommodationBooking.status,
          booking: item.accommodationBooking,
          customer,
          order: activeOrder,
          keepOrder,
          isOrderConfirmed,
          isUpdatingOrder: true,
          bookingChangeInfo
        });
      }

      // the order is not changed now
      setIsChanged(false);
      setActiveRequest(null);
    } else {
      // and this runs for "add more bookings" and "confirm order"
      setActiveRequest(ActiveRequest.BOOKING_UPDATE);

      const booking = { ...activeBooking };

      if (isWidgetRequest) {
        // for widget requests just copy golfId and plateNumber to the booking
        booking.bookingUser.golfId = customer.golfId;
        booking.bookingUser.plateNumber = customer.plateNumber;
      }

      await updateBooking({
        status,
        booking,
        customer,
        order: activeOrder,
        keepOrder,
        isOrderConfirmed,
        transactionType,
        swishPhoneNumber,
        swishPaymentType
      });

      if (transactionType === TransactionType.SwishPayment) {
        setActiveRequest(ActiveRequest.WAITING_SWISH_PAYMENT);
      } else {
        setActiveRequest(null);
      }
    }
  };

  /** Changes an active booking for a changing order */
  const setActiveBookingFromOrder = order => {
    if (order) {
      const newActiveBooking = order.orderItems.find(
        item => item.accommodationBookingId === activeBooking.id
      ).accommodationBooking;
      if (newActiveBooking) {
        switchBooking(newActiveBooking);
      }
    }
  };

  // TODO: move somewhere to share logic with rentalUnitBooking
  const onProcessChargeClick = async ({ transactionType, refundTransactionObj, invoicePriceIncreaseCancel }) => {
    setActiveRequest(ActiveRequest.BOOKING_UPDATE);
    if (isCancellingBooking) {
      setProcessingRefund(true);
      const result = await refundTransaction({ ...refundTransactionObj, isCancellingBooking });
      const orderData = result?.data?.data;

      if (orderData) {
        // need to change active booking to a orderData one
        setActiveBookingFromOrder(orderData);

        setActiveOrder(orderData);
        if (orderData.transactionTotal === 0) {
          cancelBooking(false);
          resetBookingCancellation();
        }
      } else {
        resetBookingCancellation();
        setError(translate(PAYMENT_ERROR));
      }
    } else if (transactionType === TransactionType.ManualRefund || transactionType === TransactionType.StripeRefund) {
      setProcessingRefund(true);
      const result = await refundTransaction(refundTransactionObj);
      setProcessingRefund(false);

      const orderData = result?.data?.data;

      if (orderData) {
        // need to change active booking to a orderData one
        setActiveBookingFromOrder(orderData);

        setActiveOrder(orderData);
        if (orderData.transactionTotal === orderData.orderTotal) {
          setOpenActionsModal(false);
        }
      } else {
        setError(translate(PAYMENT_ERROR));
        setOpenActionsModal(false);
      }
    } else {
      if (invoicePriceIncreaseCancel) {
        await refundTransaction(invoicePriceIncreaseCancel);
      }

      const updatedOrder = await processOrder({
        accommodationBookingId: activeBooking.id,
        golfClubId: golfclub.id,
        customer,
        order: activeOrder,
        transactionType,
        bookingChangeInfo
      });

      if (updatedOrder) {
        // need to change active booking to a orderData one
        setActiveBookingFromOrder(updatedOrder);

        setActiveOrder(updatedOrder);
      } else {
        setError(translate(PAYMENT_ERROR));
      }
      setOpenActionsModal(false);
    }
    setActiveRequest(null);
  };

  const resetBookingCancellation = () => {
    setConfirmCancelBooking(false);
    setOpenActionsModal(false);
    // setIsCancellingBooking(false);
  };

  const onCancelBookingClick = () => {
    if (isCustomerDeleteRequest) {
      cancelCustomerBooking();
    } else if (activeOrder?.transactionTotal > 0) {
      setIsCancellingBooking(true);
      setOpenActionsModal(true);
      setProcessingRefund(false);
    } else {
      cancelBooking(true);
    }
  };

  const onCancelOrderBookingClick = () => {
    if (activeOrder) {
      setActiveRequest(ActiveRequest.BOOKING_UPDATE);

      let orderItem = activeOrder.orderItems.find(oi => oi.accommodationBookingId === confirmCancelOrderBookingId);

      if (orderItem) {
        orderItem.accommodationBooking.status = BookingStatus.CANCELLED;
        orderItem.accommodationBooking.isChanged = true;
      }

      setConfirmCancelOrderBookingId(null);
      getOrderPrice();

      // switch to another booking if cancelling active booking
      if (activeBooking && activeBooking.id === confirmCancelOrderBookingId) {
        orderItem = activeOrder.orderItems.find(oi => oi.accommodationBooking.status !== BookingStatus.CANCELLED);

        if (orderItem) {
          switchBooking(orderItem.accommodationBooking);
        }
      }

      // the order has changed for sure
      setIsChanged(true);

      setActiveRequest(null);
    }
  };

  const cancelBooking = isCloseOrder => {
    setActiveRequest(ActiveRequest.BOOKING_CANCEL);
    setConfirmCancelBooking(false);

    updateBooking({ status: BookingStatus.CANCELLED, booking: { ...activeBooking, closeAllBookings: isCloseOrder } });
  };

  const cancelCustomerBooking = () => {
    const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const { id, gcid, email, odid } = params;

    const cancelOrderRequestData = {
      id,
      gcid,
      email,
      odid
    };

    setActiveRequest(ActiveRequest.BOOKING_CANCEL);
    setConfirmCancelBooking(false);

    cancelCustomerOrder(cancelOrderRequestData).then(result => {
      if (result.error) {
        setError(result.error.error);
      } else {
        onClose(true);
      }

      setActiveRequest(null);
    });
  };

  const voidInvoice = async id => {
    setActiveRequest(ActiveRequest.BOOKING_UPDATE);
    setConfirmCancelInvoiceId(null);
    const { data } = await cancelInvoice(id);
    if (data.isSuccess) {
      const { data: orderData } = await fetchAccommodationOrder(activeBooking.id);
      setActiveOrder(orderData);
      if (!isWidgetRequest && orderData && orderData.orderTotal !== orderData.transactionTotal) {
        setOpenActionsModal(true);
      }
    }
    setActiveRequest(null);
    setOpenTransactionModal(false);
  };

  const onCancelInvoice = id => {
    setConfirmCancelInvoiceId(id);
  };

  const onResendInvoice = async id => {
    setActiveRequest(ActiveRequest.BOOKING_UPDATE);
    await resendInvoice(id);
    setActiveRequest(null);
    setOpenTransactionModal(false);
  };

  const onBookingChanged = changedPart => {
    setBookingChangeInfo({ ...bookingChangeInfo, [changedPart]: true });
  };

  const hasActiveRequest = !!activeRequest;
  const customerDataIsInvalid =
    customerError.name || customerError.email || customerError.golfId || customerError.phone;

  const getOrderPrice = () => {
    let sumPrice = activeBooking.priceInclVat;

    if (activeOrder) {
      sumPrice += activeOrder.orderItems
        .filter(
          item =>
            item.accommodationBookingId !== activeBooking?.id &&
            item.accommodationBooking.status !== BookingStatus.CANCELLED
        )
        .reduce((prevValue, currentValue) => prevValue + currentValue.accommodationBooking.priceInclVat, 0);
    }

    setOrderPrice(sumPrice);

    return sumPrice;
  };

  const onTermsAcceptedCheckedChanged = () => {
    setTermsAccepted(!termsAccepted);
  };

  const onNoGolfIdCheckedChanged = () => {
    setNoGolfId(!noGolfId);
  };

  const setGolfers = isGolfers => {
    setIsGolfersOnly(isGolfers);
  };

  const paySwish = () => {
    onUpdateBookingClick(BookingStatus.PREBOOKED, false, true, TransactionType.SwishPayment);
  };

  const payStripe = () => {
    onUpdateBookingClick(BookingStatus.PREBOOKED, false, true, null);
  };

  const confirmBooking = () => {
    if (isWidgetRequest && enableSwishPayment && golfclub.useOnlinePayment && activeBooking.priceInclVat > 0) {
      setSwishPhoneNumber(customer.phone);
      validatePhoneNumber(formatSwedishPhoneNumber(customer.phone));

      setShowWidgetPaymentOptions(true);
      return;
    }

    onUpdateBookingClick(
      isWidgetRequest && golfclub.useOnlinePayment && activeBooking.priceInclVat > 0
        ? BookingStatus.PREBOOKED
        : BookingStatus.CONFIRMED,
      false,
      true
    );
  };

  const closeWidgetPaymentOptions = () => {
    setShowWidgetPaymentOptions(null);
    setActiveRequest(null);
    setInternalSvgData(null);

    if (onWidgetPaymentOptionsClose) {
      onWidgetPaymentOptionsClose();
    }
  };

  // TODO: handle this better using react-hook-form
  const validMaxGuests =
    (activeBooking?.accommodation?.type?.id === RentalUnitType.LODGE.id ||
      activeBooking?.accommodation?.type?.id === RentalUnitType.ROOMS.id) &&
    activeBooking?.accommodation?.maxGuests
      ? activeBooking?.numberOfGuests <= activeBooking?.accommodation?.maxGuests
      : true;

  const now = moment();
  const checkOutDate = moment(activeBooking?.checkOutDate);
  const customerCancelAllowed = moment(activeBooking?.checkInDate).diff(now.startOf('day'), 'days') > 2;

  const readonlyBooking = isSuperAdmin
    ? activeBooking?.status === BookingStatus.CANCELLED
    : checkOutDate.isBefore(now) || Number(activeBooking?.source || -1) === 0 || isCustomerDeleteRequest;

  const buff = new Buffer.from(internalSvgData || '');
  const base64data = buff.toString('base64');

  /*
  const isGolfersOnly =
    isWidgetRequest && accommodationAvailability?.find(a => a.value === activeBooking?.accommodation.id)?.golfersonly;
    */

  // const readonlyBooking =
  //   checkOutDate.startOf('day').isBefore(now.startOf('day')) ||
  //   Number(activeBooking?.source || -1) === 0 ||
  //   isCustomerDeleteRequest;

  // console.log('activeOrder?.orderTotal: ' + activeOrder?.orderTotal);
  // console.log('activeOrder?.transactionTotal: ' + activeOrder?.transactionTotal);

  return activeBooking ? (
    <>
      <Modal
        onClose={onClose}
        open
        size="fullscreen"
        centered
        closeOnEscape={false}
        closeOnDimmerClick={false}
        className="modal-compact position-unset"
      >
        <>
          <Modal.Header>
            <Grid>
              <Grid.Column width={8}>Order {activeOrder && <span>{activeOrder.id}</span>}</Grid.Column>
              <Grid.Column width={8} textAlign="right">
                <Label color={activeBooking.status === BookingStatus.CANCELLED ? 'red' : 'green'}>
                  Status: {translate(`booking-status.${activeBooking.status?.toLowerCase()}`)}
                </Label>
                {!isWidgetRequest && (
                  <>
                    {activeBooking.sourceText && (
                      <Label>
                        <Translate id="default.source" />:
                        <Label.Detail>{translate(`default.${activeBooking.sourceText}`)}</Label.Detail>
                      </Label>
                    )}
                  </>
                )}
                {activeOrder && (
                  <>
                    {!!activeOrder.pendingPayment && (
                      <Label color="orange">
                        {translate('default.pending-payment')}: {activeOrder.pendingPayment}
                      </Label>
                    )}
                    {!activeOrder.pendingPayment &&
                      activeOrder.orderTotal > activeOrder.transactionTotal &&
                      activeBooking.status === BookingStatus.CONFIRMED && (
                        <Label color="orange">
                          {translate('default.payment-is-due')}: {activeOrder.orderTotal - activeOrder.transactionTotal}
                        </Label>
                      )}
                    {!activeOrder.pendingPayment && activeOrder.orderTotal < activeOrder.transactionTotal && (
                      <Label color="orange">
                        {translate('default.refund-is-due')}: {activeOrder.transactionTotal - activeOrder.orderTotal}
                      </Label>
                    )}
                    {!activeOrder.pendingPayment && activeOrder.orderTotal === activeOrder.transactionTotal && (
                      <Label color="green">{translate('default.paid-in-full')}</Label>
                    )}
                  </>
                )}
              </Grid.Column>
              {!isCustomerDeleteRequest &&
                activeBooking.status !== BookingStatus.CONFIRMED &&
                activeBooking.status !== BookingStatus.CANCELLED && (
                  <span style={{ fontSize: '16px', fontWeight: 'normal' }}>
                    <Translate id="edit-booking.modal-info" options={{ renderInnerHtml: true }} />
                  </span>
                )}
            </Grid>
          </Modal.Header>
          <Modal.Content>
            <Grid divided={!isWidgetRequest} columns={2}>
              <Grid.Row>
                <Grid.Column computer={10} mobile={16} className="col-mobile-no-padding">
                  <Segment basic>
                    <Form>
                      <Booking
                        booking={activeBooking}
                        golfClub={golfclub}
                        publicHolidays={publicHolidays}
                        isWidgetRequest={isWidgetRequest}
                        isCustomerDeleteRequest={isCustomerDeleteRequest}
                        pendingTransaction={pendingTransaction}
                        translate={translate}
                        calculateOrderPrice={getOrderPrice}
                        setIsChanged={setIsChanged}
                        setGolfers={setGolfers}
                        noGolfId={noGolfId}
                        onChange={onBookingChanged}
                        isSuperAdmin={isSuperAdmin}
                      />

                      <div style={{ display: 'none' }}>
                        {activeBooking.status && (
                          <Grid>
                            <Grid.Column computer={16} textAlign="right">
                              {!isWidgetRequest && (
                                <>
                                  <Label>
                                    <Translate id="default.created" />:
                                    <Label.Detail>
                                      {moment.utc(activeBooking.created).local().format('YYYY-MM-DD HH:mm')}
                                    </Label.Detail>
                                  </Label>
                                  {activeBooking.sourceText && (
                                    <Label>
                                      <Translate id="default.source" />:
                                      <Label.Detail>{translate(`default.${activeBooking.sourceText}`)}</Label.Detail>
                                    </Label>
                                  )}
                                </>
                              )}
                              <Label color="green">
                                {translate('edit-booking.price')}: {activeBooking.originalPrice}
                                {activeBooking.originalPrice > activeBooking.priceInclVat && (
                                  <>
                                    {' '}
                                    / {translate('edit-booking.discount-price')}: {activeBooking.priceInclVat}
                                  </>
                                )}{' '}
                                {/* varav {(activeBooking.priceInclVat - activeBooking.priceExclVat).toFixed(2)} kr moms */}
                              </Label>
                            </Grid.Column>

                            {noGolfId && activeBooking.priceType === PriceType.RACK && (
                              <Grid.Column computer={16} textAlign="right">
                                <Label color="yellow">
                                  Notera att genom inte ange golf-id får ni i vissa fall ett högre pris
                                </Label>
                              </Grid.Column>
                            )}
                            {activeBooking.status === BookingStatus.CONFIRMED && bookingChanged && (
                              <Grid.Column computer={16} textAlign="right">
                                <Label color="yellow">{translate('edit-booking.confirmed-booking-changed')}</Label>
                              </Grid.Column>
                            )}
                            {activeRequest === ActiveRequest.FETCHING_ORDER && (
                              <Grid.Column computer={16} textAlign="right">
                                <Loader
                                  active
                                  inline
                                  size="tiny"
                                  className="loader-bug-fix"
                                  style={{ marginRight: '.5rem' }}
                                />
                                <span>
                                  <Translate id="edit-booking.checking-payment-status" />
                                </span>
                              </Grid.Column>
                            )}
                          </Grid>
                        )}
                      </div>
                    </Form>
                  </Segment>
                </Grid.Column>
                <Grid.Column computer={6} mobile={16} className="col-mobile-no-box">
                  {activeOrder && activeOrder.orderItems?.length > 0 && (
                    <Segment vertical>
                      <strong>
                        <Translate id="default.order-items" />
                      </strong>
                    </Segment>
                  )}
                  {activeOrder &&
                    activeOrder.orderItems?.length > 0 &&
                    activeOrder.orderItems.map(orderItem => (
                      <Segment
                        vertical
                        key={orderItem.id}
                        secondary={activeBooking.id === orderItem.accommodationBooking.id}
                        color={activeBooking.id === orderItem.accommodationBooking.id ? 'green' : undefined}
                      >
                        <Grid>
                          <Grid.Row>
                            <Grid.Column width={8}>
                              <strong
                                className={
                                  orderItem.accommodationBooking.status === BookingStatus.CANCELLED
                                    ? 'red'
                                    : orderItem.accommodationBooking.isChanged
                                    ? 'purple'
                                    : ''
                                }
                              >
                                {orderItem.accommodationBooking.isChanged && <Icon name="edit outline" />}
                                {`${orderItem.accommodationBooking.checkInDate} - ${orderItem.accommodationBooking.checkOutDate}`}
                              </strong>
                            </Grid.Column>
                            <Grid.Column width={4}>{`(${orderItem.accommodationBooking.id})`}</Grid.Column>
                            <Grid.Column width={4} textAlign="right">
                              <Button.Group size="mini">
                                {activeBooking.id !== orderItem.accommodationBooking.id &&
                                  !readonlyBooking &&
                                  orderItem.accommodationBooking.status !== BookingStatus.CANCELLED && (
                                    <Button
                                      icon
                                      onClick={() => {
                                        if (activeBooking.hasErrors) {
                                          setError('Please correct booking errors first');
                                          return;
                                        }

                                        switchBooking(orderItem.accommodationBooking);
                                      }}
                                    >
                                      <Icon name="edit" />
                                    </Button>
                                  )}
                                {orderItem.accommodationBooking.status !== BookingStatus.CANCELLED &&
                                  !readonlyBooking &&
                                  activeOrder.orderItems?.filter(
                                    item => item.accommodationBooking.status !== BookingStatus.CANCELLED
                                  )?.length > 1 && (
                                    <Button
                                      icon
                                      negative
                                      onClick={() => {
                                        if (activeBooking.hasErrors) {
                                          setError('Please correct booking errors first');
                                          return;
                                        }

                                        setConfirmCancelOrderBookingId(orderItem.accommodationBookingId);
                                      }}
                                    >
                                      <Icon name="trash" />
                                    </Button>
                                  )}
                              </Button.Group>
                            </Grid.Column>
                            <Grid.Column width={12} className="p-0">
                              {`${orderItem.accommodationBooking.accommodation.name}`}
                            </Grid.Column>
                            <Grid.Column width={4} textAlign="right" className="p-0">
                              {orderItem.accommodationBooking.priceInclVat} {golfclub.currency}
                            </Grid.Column>
                            {orderItem.accommodationBooking.packages &&
                              orderItem.accommodationBooking.packages.map(pkg => (
                                <React.Fragment key={`package-${pkg.id}`}>
                                  <Grid.Column width={16} className="p-0">
                                    {`${pkg.name} (${pkg.count}x)`}
                                  </Grid.Column>
                                  {/*
                                  <Grid.Column width={6} textAlign="right" className="p-0">
                                    {pkg.price} {golfclub.currency}
                                  </Grid.Column>
                                  */}
                                </React.Fragment>
                              ))}
                            {orderItem.accommodationBooking.productAddons?.map((addonItem, addonIndex) => (
                              <React.Fragment key={`addon-${addonIndex}`}>
                                <Grid.Column width={12} className="p-0">
                                  {translate(`product-addons.type-names.${addonItem.product.name}`)}
                                  {` (${addonItem.itemsCount}x)`}
                                </Grid.Column>
                                {/* <Grid.Column width={4} textAlign="right" className="p-0">
                                  {addonItem.chargeAmount} {golfclub.currency}
                                </Grid.Column> */}
                              </React.Fragment>
                            ))}
                          </Grid.Row>
                        </Grid>
                      </Segment>
                    ))}
                  <Segment vertical>
                    <h4>
                      <Translate id="default.customer" />
                    </h4>
                  </Segment>
                  <Segment vertical>
                    <Form>
                      {isWidgetRequest ? (
                        <Form.Field
                          control={Input}
                          required
                          type="text"
                          name="name"
                          label={`${translate('default.name')}`}
                          placeholder={`${translate('default.name')}`}
                          onChange={customerDataChanged}
                          error={!!customer.name && customerError.name}
                          value={customer.name}
                          disabled={hasActiveRequest}
                          readOnly={isCustomerDeleteRequest}
                        />
                      ) : (
                        <Form.Field
                          control={Search}
                          type="text"
                          name="name"
                          required
                          mincharacters={2}
                          label={translate('default.name')}
                          placeholder={translate('default.name')}
                          onSearchChange={debounce(customerNameChanged, 500, { leading: true })}
                          onResultSelect={customerSelected}
                          results={filteredCustomers}
                          error={!!customer.name && customerError.name}
                          value={customer.name}
                          disabled={hasActiveRequest}
                          readOnly={readonlyBooking || isCustomerDeleteRequest}
                        />
                      )}
                      {isWidgetRequest ? (
                        <Form.Field
                          control={Input}
                          required
                          label={translate('default.email')}
                          type="text"
                          name="email"
                          inputMode="email"
                          placeholder={`${translate('default.email')}`}
                          onChange={customerDataChanged}
                          error={!!customer.email && customerError.email}
                          value={customer.email}
                          disabled={hasActiveRequest}
                          readOnly={isCustomerDeleteRequest}
                        />
                      ) : (
                        <Form.Field
                          control={Search}
                          type="email"
                          name="email"
                          required
                          mincharacters={2}
                          label={translate('default.email')}
                          placeholder={translate('default.email')}
                          onSearchChange={debounce(customerEmailChanged, 500, { leading: true })}
                          onResultSelect={customerSelected}
                          results={filteredCustomers}
                          error={!!customer.email && customerError.email}
                          value={customer.email}
                          disabled={hasActiveRequest}
                          readOnly={readonlyBooking || isCustomerDeleteRequest}
                        />
                      )}

                      <Form.Field
                        control={Input}
                        type="phone"
                        name="phone"
                        label={translate('default.phone')}
                        inputMode="numeric"
                        required={isWidgetRequest}
                        placeholder={translate('default.phone')}
                        onChange={customerDataChanged}
                        value={customer.phone}
                        disabled={hasActiveRequest}
                        readOnly={readonlyBooking || isCustomerDeleteRequest}
                      />

                      {activeBooking.source === Source.WIDGET && (
                        <Grid style={{ marginBottom: 5 }}>
                          <Grid.Row>
                            {!noGolfId && (
                              <Grid.Column computer={8} mobile={16}>
                                <Form.Field
                                  required={isWidgetRequest}
                                  control={Input}
                                  type="text"
                                  name="golfId"
                                  label={`${translate('default.golf-id')}`}
                                  placeholder={translate('default.golf-id')}
                                  onChange={customerDataChanged}
                                  error={!!customer.golfId && customerError.golfId}
                                  value={customer.golfId || ''}
                                  disabled={hasActiveRequest}
                                  readOnly={readonlyBooking || isCustomerDeleteRequest}
                                />
                              </Grid.Column>
                            )}

                            {isWidgetRequest && isGolfersOnly !== undefined && !isGolfersOnly && (
                              <Grid.Column computer={8} mobile={16} style={{ paddingTop: noGolfId ? '0' : '2em' }}>
                                <Form.Checkbox
                                  label={translate('default.no-golfid')}
                                  checked={noGolfId}
                                  onClick={onNoGolfIdCheckedChanged}
                                />
                              </Grid.Column>
                            )}
                          </Grid.Row>
                        </Grid>
                      )}
                      {activeBooking.source === Source.WIDGET &&
                        activeBooking.accommodation.typeId === RentalUnitType.CAMPING.id && (
                          <Form.Field
                            control={Input}
                            type="text"
                            name="plateNumber"
                            label={translate('default.plate-number')}
                            placeholder={translate('default.plate-number')}
                            onChange={customerDataChanged}
                            value={customer.plateNumber || ''}
                            disabled={hasActiveRequest || isCustomerDeleteRequest}
                            readOnly={readonlyBooking}
                          />
                        )}
                      {isWidgetRequest && !isCustomerDeleteRequest && (
                        <Grid.Column computer={8} mobile={16} style={{ paddingTop: '0.5em' }}>
                          <Form.Checkbox
                            label={
                              <label>
                                <Translate id="edit-booking.terms-accept" />
                                <a
                                  href={`https://www.ontee.com/${moment.locale()}/terms/user/`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  aria-label={translate('edit-booking.terms-conditions')}
                                >
                                  <Translate id="edit-booking.terms-conditions" />
                                </a>
                              </label>
                            }
                            checked={termsAccepted}
                            onClick={onTermsAcceptedCheckedChanged}
                          />
                        </Grid.Column>
                      )}
                    </Form>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            <ErrorMessage error={error} />
            {isCustomerDeleteRequest && !customerCancelAllowed && (
              <ErrorMessage error={translate('edit-booking.cancel-not-allowed')} />
            )}
            {!validMaxGuests && (
              <ErrorMessage
                error={`${translate('default.number-of-guests-error')} ${activeBooking.accommodation.maxGuests}`}
              />
            )}
            <Grid>
              <Grid.Row>
                <Grid.Column width={1} textAlign="left" verticalAlign="middle">
                  {isWidgetRequest && <LanguageSwitcher />}
                </Grid.Column>
                <Grid.Column computer={15} mobile={16} className="mobile-buttons">
                  <Button color="black" onClick={btnCloseClicked} disabled={hasActiveRequest} className="button-mobile">
                    <Translate id="default.close" />
                  </Button>
                  {activeBooking.status === BookingStatus.PREBOOKED && !isWidgetRequest && (
                    <Button
                      onClick={() => onUpdateBookingClick(BookingStatus.PREBOOKED, true, false)}
                      secondary
                      disabled={hasActiveRequest}
                      loading={activeRequest === ActiveRequest.BOOKING_UPDATE}
                    >
                      <Translate id="default.add-more-bookings" />
                    </Button>
                  )}
                  {activeBooking.status === BookingStatus.PREBOOKED && (
                    <Button
                      onClick={confirmBooking}
                      className="button-mobile"
                      positive
                      disabled={
                        hasActiveRequest ||
                        customerDataIsInvalid ||
                        !validMaxGuests ||
                        (isWidgetRequest && !isCustomerDeleteRequest && !termsAccepted)
                      }
                      loading={activeRequest === ActiveRequest.BOOKING_UPDATE}
                    >
                      <Translate id="default.confirm-order" /> ({orderPrice} {golfclub.currency})
                    </Button>
                  )}
                  {activeBooking.status === BookingStatus.CONFIRMED && (
                    <>
                      {(!isCustomerDeleteRequest || customerCancelAllowed) && (
                        <Button
                          onClick={() => {
                            setConfirmCancelBooking(true);
                            setError(null);
                          }}
                          negative
                          disabled={hasActiveRequest || (readonlyBooking && !isCustomerDeleteRequest)}
                          loading={activeRequest === ActiveRequest.BOOKING_CANCEL}
                        >
                          <Translate id="default.cancel-order" />
                        </Button>
                      )}
                      {!isCustomerDeleteRequest && (
                        <Button
                          onClick={() => onUpdateBookingClick(BookingStatus.UPDATED, false)}
                          positive
                          disabled={hasActiveRequest || customerDataIsInvalid || !isChanged || !validMaxGuests}
                          loading={activeRequest === ActiveRequest.BOOKING_UPDATE}
                        >
                          {false && hasActiveRequest && (
                            <Loader inline size="tiny" inverted style={{ display: 'inline', marginRight: '10px' }} />
                          )}
                          <Translate id="default.update-order" /> ({orderPrice} {golfclub.currency})
                        </Button>
                      )}
                    </>
                  )}

                  <Modal open={showWidgetPaymentOptions}>
                    <Modal.Header>
                      <Translate id="payments.payment-options-header" />
                    </Modal.Header>
                    <Modal.Content>
                      <SegmentGroup raised>
                        <Segment>
                          {activeRequest === ActiveRequest.WAITING_SWISH_PAYMENT && (
                            <Label as="a" color="blue" ribbon style={{ display: 'inline-flex', alignItems: 'center' }}>
                              <Loader
                                active
                                inverted
                                inline
                                size="tiny"
                                className="loader-bug-fix-inverted"
                                style={{ marginRight: '.5rem' }}
                              />
                              <span>
                                <Translate id="payments.payment-options-awaiting-payment" />
                              </span>
                            </Label>
                          )}
                          {error && (
                            <Label as="a" color={error === 'payments.successfull' ? 'green' : 'red'} ribbon>
                              {error === 'payments.successfull' ? translate(error) : error}
                            </Label>
                          )}
                          <div
                            style={{
                              width: '100%',
                              display: 'inline-flex',
                              alignItems: 'center',
                              fontWeight: 700,
                              fontSize: '1.28571429rem',
                              lineHeight: '1.28571429rem',
                              marginTop: 5,
                              marginBottom: 15
                            }}
                          >
                            Swish <img src={SwishLogo} height={20} alt="" style={{ marginLeft: 10 }} />
                          </div>
                          <div>
                            {translate('payments.payment-options-swish-description')}
                            <ul>
                              {!isAppleMobileDevice && (
                                <li>{translate('payments.payment-options-swish-description-qr')}</li>
                              )}
                              <li>{translate('payments.payment-options-swish-description-phone')}</li>
                            </ul>
                          </div>
                          <Form>
                            <div style={{ marginBottom: 10 }}>
                              {!isAppleMobileDevice && (
                                <Radio
                                  label={translate('payments.payment-options-swish-qr-code')}
                                  name="swishPaymentSelector"
                                  value="qrCode"
                                  checked={swishPaymentType === 'qrCode'}
                                  onChange={swishPaymentTypeChanged}
                                  disabled={activeRequest === ActiveRequest.WAITING_SWISH_PAYMENT}
                                />
                              )}
                              <Radio
                                label={translate('payments.payment-options-swish-phone')}
                                name="swishPaymentSelector"
                                value="phoneNumber"
                                checked={swishPaymentType === 'phoneNumber'}
                                onChange={swishPaymentTypeChanged}
                                style={{ marginLeft: 20 }}
                                disabled={activeRequest === ActiveRequest.WAITING_SWISH_PAYMENT}
                              />
                            </div>
                            {internalSvgData && (
                              <>
                                <div style={{ textAlign: 'center' }}>
                                  <img
                                    src={`data:image/svg+xml;base64,${base64data}`}
                                    width={200}
                                    height={200}
                                    alt="Swish QR code"
                                  />
                                  {false && (
                                    <div>
                                      <Button
                                        positive
                                        onClick={() => {
                                          window.location = `swish://paymentrequest?token=${token}&callbackurl=${callbackUrl}";`;
                                        }}
                                      >
                                        Open app
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              </>
                            )}

                            {activeRequest === ActiveRequest.WAITING_SWISH_PAYMENT && (
                              <>
                                <h4 style={{ marginTop: 10, marginBottom: 10, textAlign: 'center' }}>
                                  <Translate id="payments.payment-options-awaiting-payment-open" />
                                </h4>
                                <p style={{ marginTop: 10, marginBottom: 10, textAlign: 'center' }}>
                                  <Translate id="payments.payment-options-awaiting-payment-redirect" />
                                </p>
                              </>
                            )}

                            {swishPaymentType === 'phoneNumber' && (
                              <Form.Field>
                                <Input
                                  computer={8}
                                  mobile={16}
                                  control={Input}
                                  type="text"
                                  inputmode="numeric"
                                  error={phoneNumberError}
                                  name="swishPhoneNumber"
                                  placeholder={translate('payments.payment-options-swish-phone-number')}
                                  onChange={swishPhoneNumberChanged}
                                  value={swishPhoneNumber}
                                  disabled={activeRequest === ActiveRequest.WAITING_SWISH_PAYMENT}
                                />
                              </Form.Field>
                            )}
                          </Form>
                          <div style={{ textAlign: 'right', marginRight: 20, marginTop: 10 }}>
                            <Button
                              onClick={paySwish}
                              positive
                              loading={activeRequest === ActiveRequest.BOOKING_UPDATE}
                              disabled={
                                activeRequest === ActiveRequest.WAITING_SWISH_PAYMENT ||
                                activeRequest === ActiveRequest.BOOKING_UPDATE ||
                                (swishPaymentType === 'phoneNumber' && phoneNumberError)
                              }
                            >
                              <Translate
                                id={`payments.${
                                  swishPaymentType === 'qrCode'
                                    ? 'payment-options-pay-swish'
                                    : 'payment-options-pay-swish'
                                }`}
                              />{' '}
                              ({orderPrice} {golfclub.currency})
                            </Button>
                          </div>
                        </Segment>
                        <Segment>
                          <h3 style={{ display: 'inline-flex', alignItems: 'center' }}>
                            <Translate id="payments.payment-options-card" />{' '}
                            <img src={CardsLogo} height={20} alt="" style={{ marginLeft: 10 }} />
                          </h3>
                          <div>
                            <Translate id="payments.payment-options-card-description" />
                          </div>
                          <div style={{ textAlign: 'right', marginRight: 20, marginTop: 10 }}>
                            <Button
                              onClick={payStripe}
                              positive
                              loading={activeRequest === ActiveRequest.BOOKING_UPDATE}
                              disabled={activeRequest === ActiveRequest.BOOKING_UPDATE}
                            >
                              <Translate id="payments.payment-options-pay-card" /> ({orderPrice} {golfclub.currency})
                            </Button>
                          </div>
                        </Segment>
                      </SegmentGroup>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button color="black" onClick={closeWidgetPaymentOptions}>
                        <Translate id="default.close" />
                      </Button>
                    </Modal.Actions>
                  </Modal>

                  <Modal
                    onClose={() => setOpenActionsModal(false)}
                    onOpen={() => setOpenActionsModal(true)}
                    open={openActionsModal}
                    trigger={
                      activeBooking.status !== BookingStatus.CANCELLED &&
                      activeOrder?.orderTotal !== activeOrder?.transactionTotal &&
                      activeOrder?.orderItems &&
                      activeOrder.orderItems.every(
                        item => item.accommodationBooking.status !== BookingStatus.PREBOOKED
                      ) &&
                      !isCancellingBooking &&
                      !isWidgetRequest && (
                        <Button
                          disabled={hasActiveRequest || customerDataIsInvalid || bookingChanged}
                          loading={activeRequest === ActiveRequest.BOOKING_UPDATE}
                        >
                          <Translate id="default.show-actions" />
                        </Button>
                      )
                    }
                  >
                    <Modal.Header>
                      <Translate id="default.select-an-action" />
                    </Modal.Header>
                    <Modal.Content>
                      <PaymentActions
                        isCancellingBooking={isCancellingBooking}
                        isProcessingRefund={processingRefund}
                        useOnlinePayment={golfclub.useOnlinePayment}
                        golfClubCurrency={golfclub.currency}
                        order={activeOrder}
                        disableButton={hasActiveRequest || customerDataIsInvalid}
                        loading={activeRequest === ActiveRequest.BOOKING_UPDATE}
                        onProcessChargeClick={onProcessChargeClick}
                        onClose={() => setOpenActionsModal(false)}
                        translate={translate}
                      />
                    </Modal.Content>
                    <Modal.Actions>
                      <Button color="black" onClick={() => setOpenActionsModal(false)}>
                        <Translate id="default.close" />
                      </Button>
                    </Modal.Actions>
                  </Modal>

                  {activeOrder && activeOrder.transactions?.length > 0 && !isWidgetRequest && (
                    <Modal
                      onClose={() => setOpenTransactionModal(false)}
                      onOpen={() => setOpenTransactionModal(true)}
                      open={openTransactionModal}
                      size="large"
                      trigger={
                        <Button
                          disabled={hasActiveRequest || customerDataIsInvalid}
                          loading={activeRequest === ActiveRequest.BOOKING_UPDATE}
                        >
                          <Translate id="default.show-transactions" />
                        </Button>
                      }
                    >
                      <Modal.Header>
                        <Translate id="default.transactions" />
                      </Modal.Header>
                      <Modal.Content>
                        <Transactions
                          order={activeOrder}
                          onCancel={onCancelInvoice}
                          onResend={onResendInvoice}
                          activeRequest={activeRequest}
                          setActiveRequest={setActiveRequest}
                          golfClubCurrency={golfclub.currency}
                        />
                      </Modal.Content>
                      <Modal.Actions>
                        <Button color="black" onClick={() => setOpenTransactionModal(false)}>
                          <Translate id="default.close" />
                        </Button>
                      </Modal.Actions>
                    </Modal>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Actions>
        </>
      </Modal>

      <MyConfirm
        open={!!confirmCancelInvoiceId}
        size="mini"
        onCancel={() => {
          setConfirmCancelInvoiceId(null);
        }}
        onConfirm={() => {
          voidInvoice(confirmCancelInvoiceId);
        }}
        header={translate('default.void-invoice')}
        content={translate('default.void-invoice-to-proceed')}
        cancelButton={translate('default.no')}
        confirmButton={translate('default.yes')}
      />

      <MyConfirm
        open={!!confirmCancelOrderBookingId}
        size="mini"
        onCancel={() => {
          setConfirmCancelOrderBookingId(null);
        }}
        onConfirm={() => {
          onCancelOrderBookingClick();
        }}
        header={`Vill du avboka bokningen ${confirmCancelOrderBookingId}?`}
        content="Bekräfta avbokningen genom att klicka Ja"
        cancelButton={translate('default.no')}
        confirmButton={translate('default.yes')}
      />

      <MyConfirm
        open={confirmCancelBooking}
        size="mini"
        onCancel={() => {
          setConfirmCancelBooking(null);
          setIsCancellingBooking(false);
        }}
        onConfirm={() => {
          onCancelBookingClick();
        }}
        header={translate('default.cancel-bookings-header')}
        content={translate('default.cancel-bookings-content')}
        cancelButton={translate('default.no')}
        confirmButton={translate('default.yes')}
      />

      <MyConfirm
        open={!!confirmCloseWhenChanged}
        size="tiny"
        onCancel={() => setConfirmCloseWhenChanged(null)}
        onConfirm={() => {
          setConfirmCloseWhenChanged(null);
          onClose();
        }}
        header={translate('edit-booking.close-order-without-saving')}
        content={confirmCloseWhenChanged}
        cancelButton={translate('edit-booking.close-order-no')}
        confirmButton={translate('edit-booking.close-order-yes')}
      />

      <MyConfirm
        open={
          activeBooking.isConfirmed &&
          !(isWidgetRequest && golfclub.useOnlinePayment && activeBooking.priceInclVat !== 0)
        }
        size="tiny"
        onCancel={btnCloseClicked}
        // onConfirm={() => {
        //   // setActiveBooking({ ...activeBooking, isConfirmed: false });
        //   activeBooking.isConfirmed = false;
        // }}
        header={
          <>
            <Icon name="check circle outline" color="green" size="large" />
            <span className="green">{translate('default.booking-confirmed')}</span>
          </>
        }
        content={`
              <table><tbody>
                <tr><td><b>${translate('default.booked-by')}:</b></td><td>${activeBooking.bookingUser?.name}</td></tr>
                <tr><td><b>${translate('edit-booking.booking-number')}:</b></td><td>${activeBooking.id}</td></tr>
                <tr><td><b>Produkt:</b></td><td>${
                  activeBooking.accommodation?.name || activeBooking.accommodationName
                }</td></tr>
                <tr><td><b>${translate('default.checkin')}:</b></td><td>${activeBooking.checkInDate}</td></tr>
                <tr><td><b>${translate('default.checkout')}:</b></td><td>${activeBooking.checkOutDate}</td></tr>
                <tr><td><b>${translate('default.price')}:</b></td><td>${activeBooking.priceInclVat} ${
          golfclub.currency
        }</td></tr>
                <tr><td colspan="2" class="pt-1">${translate('default.confirmation-sent-to')} <b>${
          customer?.email || activeBooking.bookingUser?.email
        }</b></td></tr>
              </tbody></table>`}
        cancelButton={translate('default.close')}
        confirmButton={translate('default.edit-booking')}
        confirmButtonColor="green"
      />
    </>
  ) : null;
}

export default BookingModalView;

function MyConfirm({
  header,
  content,
  open,
  size,
  onCancel,
  onConfirm,
  cancelButton,
  confirmButton,
  confirmButtonColor
}) {
  return (
    <Modal open={open} size={size}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </Modal.Content>
      <Modal.Actions>
        {onCancel && <Button onClick={onCancel}>{cancelButton}</Button>}
        {onConfirm && (
          <Button onClick={onConfirm} color={confirmButtonColor || 'red'}>
            {confirmButton}
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
}
