import React, { Fragment } from 'react';
import { Translate } from 'react-localize-redux';
import { debounce, escapeRegExp, filter } from 'lodash-es';
import {
  Icon,
  Label,
  Form,
  Table,
  Message,
  Search,
  Input,
  Dropdown,
  TextArea,
  Loader,
  Checkbox
} from 'semantic-ui-react';
import { TimeInput, DateInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import {
  isValidTime,
  getTimeHHMM,
  isValidDate,
  calculatePrice,
  calculateDiscountedPrice,
  golfIdRegex,
  emailRegex
} from '../utils';
import { ErrorMessage } from './ErrorMessage';
import { RentalUnitType } from '../core/constants';
import { PriceType, Source } from '../core/enums';

import 'moment/locale/sv';
import 'moment/locale/de';

import DiscountSelection from './Discounts/DiscountSelection';
import { accommodationBookingService } from '../core/services';
// moment.locale('sv');
moment.updateLocale('en', {
  week: {
    dow: 1 // Monday is the first day of the week.
  }
});

class EditBooking extends React.Component {
  state = {
    selectedNrPlayers: 0,
    selectedPlayers: [
      { name: '', email: '' },
      { name: '', email: '' },
      { name: '', email: '' },
      { name: '', email: '' }
    ],
    rateQuantity: new Map(),
    searchResults: [
      { name: [], email: [] },
      { name: [], email: [] },
      { name: [], email: [] },
      { name: [], email: [] }
    ],
    valid: false
  };

  componentDidMount = () => {
    if (!this.props.data) {
      this.props.dataIsValid(false);
      return;
    }
    // If we edit an existing booking set values in input fields
    const { teetime } = this.props.editProps;
    const { rates } = teetime;
    const booking = this.props.data;
    const { selectedPlayers, rateQuantity } = this.state;
    rates.forEach(rate => rateQuantity.set(rate, this.findRateQuantity(rate.id, booking)));
    const players = [...booking.players.filter(p => p.name !== null || p.email !== null)];
    players.forEach((p, i) => {
      selectedPlayers[i] = { name: p.name, email: p.email };
      this.playersNameChanged(null, { name: `name${i}`, value: p.name });
      this.playersEmailChanged(null, { name: `email${i}`, value: p.email });
    });
    const selectedNrPlayers = rates.reduce((val, rate) => rateQuantity.get(rate) * rate.numberOfPlayers + val, 0);
    this.setState({
      ...this.state,
      selectedPlayers,
      selectedNrPlayers,
      rateQuantity
    });
    this.publishValid({ selectedPlayers, selectedNrPlayers });
  };

  rateQuantityChanged = (e, { name, value }) => {
    const { teetime } = this.props.editProps;
    const { rateQuantity, selectedPlayers } = this.state;
    const { rates } = teetime;
    rates.forEach(rate => rateQuantity.set(rate, rate.id === name ? value : rateQuantity.get(rate) || 0));
    const selectedNrPlayers = rates.reduce((val, rate) => rateQuantity.get(rate) * rate.numberOfPlayers + val, 0);
    this.reshufflePlayers(selectedPlayers);
    this.setState({ rateQuantity, selectedNrPlayers, selectedPlayers });
    this.publishValid({ selectedPlayers, selectedNrPlayers });
  };

  reshufflePlayers = players => {
    for (let i = 2; i >= 0; i--) {
      if (players[i].name === '' && players[i].email === '') {
        players.splice(i, 1);
        players[3] = {
          name: '',
          nameError: false,
          email: '',
          emailError: false
        };
      }
    }
  };

  numberOfPlayersWithInfo = players => {
    const result = players.reduce((i, p) => i + (p.name === '' && p.email === '' ? 0 : 1), 0);
    return result;
  };

  getGolfClubPlayers = () => {
    const { players } = this.props.editProps;
    return players.map(x => ({
      title: `${x.player.firstName} ${x.player.lastName}`,
      description: x.player.email,
      key: `${x.player.firstName} ${x.player.lastName}${x.player.email}`
    }));
  };

  playersNameChanged = (e, { name: nameid, value }) => {
    const id = nameid.substring(4);
    const { selectedPlayers, searchResults } = this.state;
    const golfClubPlayers = this.getGolfClubPlayers();
    const player = selectedPlayers[id];
    selectedPlayers[id] = {
      ...player,
      name: value,
      nameError: id === 0 ? !value || value.length < 2 : !!value && value.length < 2
    };
    setTimeout(() => {
      const re = new RegExp(escapeRegExp(selectedPlayers[id].name), 'i');
      const isMatch = result => re.test(result.title);
      searchResults[id].name = filter(golfClubPlayers, isMatch);

      this.setState({ searchResults });
    }, 300);

    if (selectedPlayers[id].name === '' && selectedPlayers[id].email === '') {
      selectedPlayers.splice(id, 1);
      searchResults.splice(id, 1);
      selectedPlayers[3] = {
        name: '',
        nameError: false,
        email: '',
        emailError: false
      };
      searchResults[3] = { name: [], email: [] };
    }

    this.setState({ selectedPlayers });
    this.publishValid({
      selectedPlayers,
      selectedNrPlayers: this.state.selectedNrPlayers
    });
  };

  playersEmailChanged = (e, { name: emailid, value }) => {
    const id = emailid.substring(5);
    const { selectedPlayers, searchResults } = this.state;
    const golfClubPlayers = this.getGolfClubPlayers();
    const player = selectedPlayers[id];
    selectedPlayers[id] = {
      ...player,
      email: value,
      emailError: id === 0 ? !value || !emailRegex.test(value) : !!value && !emailRegex.test(value)
    };
    setTimeout(() => {
      const re = new RegExp(escapeRegExp(selectedPlayers[id].email), 'i');
      const isMatch = result => re.test(result.description);

      searchResults[id].email = filter(golfClubPlayers, isMatch);
      this.setState({ searchResults });
    }, 300);

    if (selectedPlayers[id].name === '' && selectedPlayers[id].email === '') {
      selectedPlayers.splice(id, 1);
      searchResults.splice(id, 1);
      selectedPlayers[3] = {
        name: '',
        nameError: false,
        email: '',
        emailError: false
      };
      searchResults[3] = { name: [], email: [] };
    }

    this.setState({ selectedPlayers });
    this.publishValid({
      selectedPlayers,
      selectedNrPlayers: this.state.selectedNrPlayers
    });
  };

  handleResultSelected = (data, id) => {
    const name = data.result.title;
    const email = data.result.description;

    const { selectedPlayers, searchResults } = this.state;
    const golfClubPlayers = this.getGolfClubPlayers();
    const player = selectedPlayers[id];
    selectedPlayers[id] = {
      ...player,
      name,
      nameError: id === 0 ? !name || name.length < 2 : !!name && name.length < 2,
      email,
      emailError: id === 0 ? !email || !emailRegex.test(email) : !!email && !emailRegex.test(email)
    };
    setTimeout(() => {
      const re = new RegExp(escapeRegExp(selectedPlayers[id].name), 'i');
      const isMatch = result => re.test(result.title);
      searchResults[id].name = filter(golfClubPlayers, isMatch);
      const re2 = new RegExp(escapeRegExp(selectedPlayers[id].email), 'i');
      const isMatch2 = result => re2.test(result.description);
      searchResults[id].email = filter(golfClubPlayers, isMatch2);
      this.setState({ searchResults });
    }, 300);

    this.setState({ selectedPlayers });
    this.publishValid({
      selectedPlayers,
      selectedNrPlayers: this.state.selectedNrPlayers
    });
  };

  removeSelectedPlayer = slot => {
    const { selectedPlayers } = this.state;
    selectedPlayers.splice(slot, 1);
    selectedPlayers[3] = {
      name: '',
      nameError: false,
      email: '',
      emailError: false
    };
    this.setState({ selectedPlayers });
    this.publishValid({
      selectedPlayers,
      selectedNrPlayers: this.state.selectedNrPlayers
    });
  };

  createBooking = async () => {
    const { selectedPlayers, rateQuantity } = this.state;
    const { rates, golfCourseId, startTime } = this.props.editProps.teetime;

    const ratesToBook = rates.filter(rate => rateQuantity.get(rate) > 0);
    const nrPlayersInRates = ratesToBook.reduce((val, rate) => rateQuantity.get(rate) * rate.numberOfPlayers + val, 0);
    const playersToBook = selectedPlayers.slice(0, nrPlayersInRates).map(player => ({
      name: this.nullIfEmpty(player.name),
      email: this.nullIfEmpty(player.email)
    }));

    const golfCourse = { Id: golfCourseId };
    const products = ratesToBook.map(rate => {
      const Quantity = rateQuantity.get(rate);
      return { Quantity, rate };
    });
    const booking = {
      golfCourse,
      products,
      players: playersToBook,
      startDate: this.props.editProps.date,
      startTime,
      numberOfPlayers: nrPlayersInRates,
      status: 'Confirmed'
    };
    if (this.props.data) {
      booking.Id = this.props.data.id;
    }
    this.props.bookingAction(booking, this.props.onSubmit);
  };

  nullIfEmpty = s => (s === '' ? null : s);

  findRateQuantity = (rateId, booking) => {
    if (!booking) {
      return 0;
    }
    const index = booking.products.findIndex(p => p.rate.id === rateId);
    return index >= 0 ? booking.products[index].quantity : 0;
  };

  publishValid = ({ selectedNrPlayers, selectedPlayers }) => {
    const { valid } = this.validInfo({ selectedNrPlayers, selectedPlayers });
    this.setState({ valid });
    this.props.dataIsValid(valid);
  };

  validInfo = ({ selectedNrPlayers, selectedPlayers }) => {
    const validBookingPlayer =
      selectedPlayers[0] &&
      selectedPlayers[0].name &&
      !selectedPlayers[0].nameError &&
      selectedPlayers[0].email &&
      !selectedPlayers[0].emailError;
    const playersWithInfo = this.numberOfPlayersWithInfo(this.state.selectedPlayers);
    const playersMatchQuantity = selectedNrPlayers >= playersWithInfo;
    const validOtherPlayers =
      !selectedPlayers[1].nameError &&
      !selectedPlayers[1].emailError &&
      !selectedPlayers[2].nameError &&
      !selectedPlayers[2].emailError &&
      !selectedPlayers[3].nameError &&
      !selectedPlayers[3].emailError;
    const valid = validBookingPlayer && validOtherPlayers && playersMatchQuantity;
    return {
      valid,
      validBookingPlayer,
      validOtherPlayers,
      playersWithInfo,
      playersMatchQuantity
    };
  };

  render = () => {
    const { error, data: existingBooking } = this.props;
    const { teetime } = this.props.editProps;
    const { selectedNrPlayers, selectedPlayers, rateQuantity } = this.state;
    const rates = teetime ? teetime.rates : null;
    const createBookingVisible = existingBooking || (teetime && teetime.availableSlots > 0) || error;
    const { valid, validBookingPlayer, validOtherPlayers, playersWithInfo, playersMatchQuantity } = this.validInfo(
      this.state
    );
    const rateOptions = new Map();

    // Create dropdown options for each rate
    if (rates) {
      const playersInThisBooking =
        existingBooking && !existingBooking.currentUserPrebooking ? existingBooking.numberOfPlayers : 0;
      // let playersInThisBooking = 0;
      // rateQuantity.forEach((value, key) => playersInThisBooking += key.numberOfPlayers * value);
      const availableSlots = teetime.availableSlots + playersInThisBooking - selectedNrPlayers;
      rates.forEach(rate =>
        rateOptions.set(
          rate,
          [...Array(Math.ceil(4 / rate.numberOfPlayers) + 1).keys()].map(qty => {
            const playersInRateOption = rate.numberOfPlayers * qty;
            const availableSlotsLeft =
              availableSlots + rate.numberOfPlayers * (rateQuantity.has(rate) ? rateQuantity.get(rate) : 0);
            const disable = playersInRateOption > availableSlotsLeft;
            const rateOption = {
              key: qty,
              text: qty,
              value: qty,
              disabled: disable
            };
            return rateOption;
          })
        )
      );
    }

    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, { disabled: !valid })
    );
    const playerInputsToShow = Math.max(selectedNrPlayers, playersWithInfo);

    return (
      <Translate>
        {({ translate }) => (
          <>
            {createBookingVisible && (
              <>
                <Label attached="top right" color="green">
                  <Translate id="edit-booking.players" />: <span>{selectedNrPlayers}</span>
                </Label>
                <h3>
                  <Translate id="default.rates" />
                </h3>
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>
                        <Translate id="default.name" />
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        <Translate id="default.nr-players" />
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        <Translate id="default.price" />
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        <Translate id="default.qty" />
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {rates &&
                      rates.map(rate => (
                        <Table.Row key={rate.id}>
                          <Table.Cell>{rate.description}</Table.Cell>
                          <Table.Cell>{rate.numberOfPlayers}</Table.Cell>
                          <Table.Cell>{rate.sellPrice}</Table.Cell>
                          <Table.Cell>
                            <Form.Select
                              fluid
                              disabled={this.props.activeRequest}
                              name={rate.id}
                              onChange={this.rateQuantityChanged}
                              options={rateOptions.get(rate)}
                              placeholder={rateOptions.get(rate)[0].value.toString()}
                              value={this.state.rateQuantity.get(rate)}
                            />
                          </Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>

                <h3>
                  <Translate id="default.players" />
                </h3>
                {!this.state.bookingSuccess && (
                  <Form id={this.props.id} onSubmit={this.createBooking} error={!!error}>
                    {[...Array(playerInputsToShow).keys()].map(slot => (
                      <Fragment key={slot}>
                        <Form.Group widths={2} key={slot} style={{ display: 'flex', alignItems: 'center' }}>
                          {playerInputsToShow > selectedNrPlayers && (
                            <Icon
                              name="delete"
                              link
                              size="large"
                              color="red"
                              onClick={() => this.removeSelectedPlayer(slot)}
                            />
                          )}
                          <Form.Field
                            control={Search}
                            icon={null}
                            type="text"
                            name={`name${slot}`}
                            mincharacters={2}
                            placeholder={translate('edit-booking.players-name')}
                            onSearchChange={debounce(this.playersNameChanged, 500, { leading: true })}
                            onResultSelect={(_e, data) => this.handleResultSelected(data, slot)}
                            results={this.state.searchResults[slot].name}
                            error={selectedPlayers[slot] && selectedPlayers[slot].nameError}
                            value={selectedPlayers[slot].name}
                          />
                          <Form.Field
                            control={Search}
                            icon={null}
                            type="email"
                            name={`email${slot}`}
                            mincharacters={2}
                            placeholder={translate('edit-booking.players-email')}
                            onSearchChange={debounce(this.playersEmailChanged, 500, { leading: true })}
                            onResultSelect={(_e, data) => this.handleResultSelected(data, slot)}
                            results={this.state.searchResults[slot].email}
                            error={selectedPlayers[slot] && selectedPlayers[slot].emailError}
                            value={selectedPlayers[slot].email}
                          />
                        </Form.Group>
                      </Fragment>
                    ))}

                    {selectedNrPlayers === 0 && (
                      <Message color="green" visible content={translate('edit-booking.choose-rates')} />
                    )}

                    {selectedNrPlayers > 0 && !validBookingPlayer && (
                      <Message color="orange" visible content={translate('edit-booking.first-player-info')} />
                    )}
                    {selectedNrPlayers > 0 && !validOtherPlayers && (
                      <Message color="orange" visible content={translate('edit-booking.player-info-invalid')} />
                    )}
                    {selectedNrPlayers > 0 && !playersMatchQuantity && (
                      <Message color="orange" visible>
                        <Translate id="edit-booking.players-rate-match" />
                        <Message.Item>
                          <Translate id="edit-booking.players-acc-rates" />: <b>{selectedNrPlayers}</b>
                        </Message.Item>
                        <Message.Item>
                          <Translate id="edit-booking.players-acc-rates" />: <b>{playersWithInfo}</b>
                        </Message.Item>
                      </Message>
                    )}

                    {/* <ErrorMessage error={error} /> */}
                    {childrenWithProps}
                  </Form>
                )}
              </>
            )}
          </>
        )}
      </Translate>
    );
  };
}

// TODO: refactor this and use one shared component (BookingDetails) used by Admin booking and Widget booking
class EditAccommodationBooking extends React.Component {
  state = {
    selectedNrPlayers: 0,
    selectedPlayers: [
      { name: '', email: '', golfId: '' },
      { name: '', email: '', golfId: '' },
      { name: '', email: '', golfId: '' },
      { name: '', email: '', golfId: '' }
    ],
    rateQuantity: new Map(),
    searchResults: [
      { name: [], email: [] },
      { name: [], email: [] },
      { name: [], email: [] },
      { name: [], email: [] }
    ],
    checkinDate: '',
    checkinDateError: null,
    checkoutDate: '',
    checkoutDateError: null,
    comment: '',
    valid: false,
    selectedIndex: 0,
    accommodations: [{ key: 0, text: '', value: 0 }],
    price: null,
    fetchingAvailability: false,
    appliedDiscounts: [],
    originalPrice: 0,
    discountedPrice: 0,
    noGolfId: false
  };

  componentDidMount = () => {
    if (!this.props.data) {
      this.props.dataIsValid(false);
      return;
    }

    const booking = this.props.data;
    const checkinDate = booking.checkInDate;
    const checkoutDate = booking.checkOutDate;
    const comment = booking.comment || '';
    const { selectedPlayers } = this.state;
    const players = [booking.bookingUser];
    players.forEach((p, i) => {
      const { name, email, phone, golfId, plateNumber } = p;
      selectedPlayers[i] = { name, email, phone, golfId, plateNumber };
      this.playersNameChanged(null, { name: `name${i}`, value: name });
      this.playersEmailChanged(null, { name: `email${i}`, value: email });
    });
    const selectedNrPlayers = 1;
    const { accommodations } = this.props.editProps;
    const accommodationsAvailable = this.getAvailableAccommodations(accommodations, booking, true);
    const selectedIndex = accommodationsAvailable.findIndex(x => x.value === booking.accommodation.id);
    const price = booking.priceInclVat;
    this.setState({
      ...this.state,
      selectedPlayers,
      selectedNrPlayers,
      checkinDate,
      checkoutDate,
      comment,
      accommodations: accommodationsAvailable,
      selectedIndex,
      price
    });

    if (booking.accommodationBookingDiscounts?.length > 0) {
      const accommodationBookingDiscounts = booking.accommodationBookingDiscounts.map(x => x.discount);

      this.setState({ appliedDiscounts: accommodationBookingDiscounts });
      this.props.setPriceWithDiscount({
        originalPrice: booking.originalPrice,
        discountedPrice: booking.priceInclVat
      });
    }

    this.publishValid({ selectedPlayers, noGolfId: this.state.noGolfId });
  };

  getAvailableAccommodations = (accommodations, booking, initial) => {
    const accs = accommodations.map(x => {
      const a = x.accommodation;
      // const priceCat = a.priceCategory;
      let disabled = !x.available;
      if (
        !x.available &&
        booking.accommodation.id === a.id &&
        !x.blockedPeriod &&
        (initial || (x.bookings.length === 1 && x.bookings[0].id === booking.id))
      ) {
        disabled = false;
      }
      return {
        key: a.id,
        text: a.name,
        value: a.id,
        disabled,
        accommodation: a,
        className: disabled ? 'dropdown-strike' : ''
      };
    });
    return accs;
  };

  getGolfClubPlayers = () => {
    const { players } = this.props.editProps;
    return players.map(x => ({
      title: `${x.player.firstName} ${x.player.lastName}`,
      description: x.player.email,
      key: `${x.player.firstName} ${x.player.lastName}${x.player.email}`
    }));
  };

  playersNameChanged = e => {
    if (!e) {
      return;
    }
    const { name: nameid, value } = e?.target || e;
    const id = nameid.substring(4);
    const { selectedPlayers, searchResults } = this.state;
    const golfClubPlayers = this.getGolfClubPlayers();
    const player = selectedPlayers[id];
    selectedPlayers[id] = {
      ...player,
      name: value,
      nameError: id === 0 ? !value || value.length < 2 : !!value && value.length < 2
    };
    setTimeout(() => {
      const re = new RegExp(escapeRegExp(selectedPlayers[id].name), 'i');
      const isMatch = result => re.test(result.title);
      searchResults[id].name = filter(golfClubPlayers, isMatch);

      this.setState({ searchResults });
    }, 300);

    if (selectedPlayers[id].name === '' && selectedPlayers[id].email === '') {
      selectedPlayers.splice(id, 1);
      searchResults.splice(id, 1);
      selectedPlayers[3] = {
        name: '',
        nameError: false,
        email: '',
        emailError: false
      };
      searchResults[3] = { name: [], email: [] };
    }

    this.setState({ selectedPlayers });
    this.publishValid({ selectedPlayers, noGolfId: this.state.noGolfId });
  };

  publishValid = ({ selectedPlayers, noGolfId = false, selectedIndex = null }) => {
    const { valid } = this.validInfo({ selectedPlayers, noGolfId, selectedIndex });
    this.setState({ valid });
    this.props.dataIsValid(valid);
  };

  validInfo = ({ selectedPlayers, noGolfId = false, selectedIndex: selIndex }) => {
    const { checkinDate, checkoutDate, accommodations, selectedIndex } = this.state;
    const validBookingPlayer =
      selectedPlayers[0] &&
      selectedPlayers[0].name &&
      !selectedPlayers[0].nameError &&
      selectedPlayers[0].email &&
      !selectedPlayers[0].emailError &&
      (noGolfId || (selectedPlayers[0].golfId && !selectedPlayers[0].golfIdError));
    const validOtherPlayers =
      !selectedPlayers[1].nameError &&
      !selectedPlayers[1].emailError &&
      !selectedPlayers[2].nameError &&
      !selectedPlayers[2].emailError &&
      !selectedPlayers[3].nameError &&
      !selectedPlayers[3].emailError;
    const checkin = checkinDate || this.props.data.checkInDate;
    const validCheckinDate = isValidDate(checkin);
    const checkout = checkoutDate || this.props.data.checkOutDate;
    const validCheckoutDate = isValidDate(checkout) && validCheckinDate && checkout > checkin;
    const validSelectedIndex = !accommodations[selIndex || selectedIndex].disabled;
    const valid =
      validBookingPlayer && validOtherPlayers && validCheckinDate && validCheckoutDate && validSelectedIndex;
    return { valid, validBookingPlayer, validOtherPlayers };
  };

  playersEmailChanged = e => {
    if (!e) {
      return;
    }
    const { name: emailid, value } = e?.target || e;
    const id = emailid.substring(5);
    const { selectedPlayers, searchResults } = this.state;
    const golfClubPlayers = this.getGolfClubPlayers();
    const player = selectedPlayers[id];
    selectedPlayers[id] = {
      ...player,
      email: value,
      emailError: id === 0 ? !value || !emailRegex.test(value) : !!value && !emailRegex.test(value)
    };

    const re = new RegExp(escapeRegExp(selectedPlayers[id].email), 'i');
    const isMatch = result => re.test(result.description);

    searchResults[id].email = filter(golfClubPlayers, isMatch);
    this.setState({ searchResults });

    if (selectedPlayers[id].name === '' && selectedPlayers[id].email === '') {
      selectedPlayers.splice(id, 1);
      searchResults.splice(id, 1);
      selectedPlayers[3] = {
        name: '',
        nameError: false,
        email: '',
        emailError: false
      };
      searchResults[3] = { name: [], email: [] };
    }

    this.setState({ selectedPlayers });
    this.publishValid({ selectedPlayers, noGolfId: this.state.noGolfId });
  };

  handleResultSelected = (data, id) => {
    const name = data.result.title;
    const email = data.result.description;

    const { selectedPlayers, searchResults } = this.state;
    const golfClubPlayers = this.getGolfClubPlayers();
    const player = selectedPlayers[id];
    selectedPlayers[id] = {
      ...player,
      name,
      nameError: id === 0 ? !name || name.length < 2 : !!name && name.length < 2,
      email,
      emailError: id === 0 ? !email || !emailRegex.test(email) : !!email && !emailRegex.test(email)
    };
    setTimeout(() => {
      const re = new RegExp(escapeRegExp(selectedPlayers[id].name), 'i');
      const isMatch = result => re.test(result.title);
      searchResults[id].name = filter(golfClubPlayers, isMatch);
      const re2 = new RegExp(escapeRegExp(selectedPlayers[id].email), 'i');
      const isMatch2 = result => re2.test(result.description);
      searchResults[id].email = filter(golfClubPlayers, isMatch2);
      this.setState({ searchResults });
    }, 300);

    this.setState({ selectedPlayers });
    this.publishValid({ selectedPlayers, noGolfId: this.state.noGolfId });
  };

  // removeSelectedPlayer = (slot) => {
  //   const { selectedPlayers } = this.state;
  //   selectedPlayers.splice(slot, 1);
  //   selectedPlayers[3] = { name: '', nameError: false, email: '', emailError: false };
  //   this.setState({ selectedPlayers });
  //   this.publishValid({ selectedPlayers, selectedNrPlayers: this.state.selectedNrPlayers });
  // };

  createBooking = async () => {
    const { selectedPlayers, accommodations, selectedIndex, comment } = this.state;
    const { golfClubId } = this.props;

    const { checkinDate, checkoutDate, acc } = this.props.editProps;
    const { priceInclVat } = this.props;
    const bookingUser = {
      name: selectedPlayers[0].name,
      email: selectedPlayers[0].email,
      phone: selectedPlayers[0].phone,
      golfId: this.state.noGolfId ? '' : selectedPlayers[0].golfId,
      plateNumber: selectedPlayers[0].plateNumber
    };
    const checkinDateSt = this.state.checkinDate;
    const checkoutDateSt = this.state.checkoutDate;
    const { accommodation } = accommodations[selectedIndex];
    const discountIds = this.state.appliedDiscounts.map(x => x.id);

    const booking = {
      accommodation: accommodation || acc.accommodation,
      golfClubId,
      bookingUser,
      checkinDate: isValidDate(checkinDateSt) ? checkinDateSt : checkinDate,
      checkoutDate: isValidDate(checkoutDateSt) ? checkoutDateSt : checkoutDate,
      priceInclVat,
      additionalGuests: [],
      comment,
      //      additionalGuests: [ {Guest: {name: "Kalle Kula"}}],
      //      numberOfPeople: ,
      status: 'Confirmed',
      discountIds,
      source: this.props.isWidgetRequest ? Source.WIDGET : Source.ADMIN,
      priceType: this.state.noGolfId ? PriceType.RACK : PriceType.SELL
    };
    if (this.props.data) {
      booking.Id = this.props.data.id;
    }

    this.props.bookingAction(golfClubId, booking, this.props.onSubmit);
  };

  fetchAccommodationAvailability = async (checkinDate, checkoutDate) => {
    const { golfClubId } = this.props;
    this.setState({ fetchingAvailability: true });
    const booking = this.props.data;
    const result = await accommodationBookingService.getAvailableAccommodations(
      golfClubId,
      checkinDate,
      checkoutDate,
      false
    );
    if (result.code === 200) {
      const { accommodations, pricings } = result.data;
      const accommodationsAvailable = this.getAvailableAccommodations(accommodations, booking);
      this.setState({ pricings, accommodations: accommodationsAvailable });
    }
    this.setState({ fetchingAvailability: false });
    this.publishValid({ selectedPlayers: this.state.selectedPlayers, noGolfId: this.state.noGolfId });
  };

  nullIfEmpty = s => (s === '' ? null : s);

  // findRateQuantity = (rateId, booking) => {
  //   if (!booking) {
  //     return 0;
  //   }
  //   const index = booking.products.findIndex((p) => p.rate.id === rateId);
  //   return index >= 0 ? booking.products[index].quantity : 0;
  // };

  validateDateChange = (e, { name, value }) => {
    let { checkinDate, checkoutDate } = this.state;
    if (name === 'checkinDate') {
      checkinDate = value;
    }
    if (name === 'checkoutDate') {
      checkoutDate = value;
    }
    this.setState({ checkinDate, checkoutDate });
    this.fetchAccommodationAvailability(checkinDate, checkoutDate);
  };

  validateGolfId = (e, { name, value }) => {
    const id = name.substring(6);
    const { selectedPlayers } = this.state;
    const player = selectedPlayers[id];
    selectedPlayers[id] = {
      ...player,
      golfId: value,
      golfIdError: id === 0 ? !value || !golfIdRegex.test(value) : !!value && !golfIdRegex.test(value)
    };
    this.setState({ selectedPlayers });
    this.publishValid({ selectedPlayers, noGolfId: this.state.noGolfId });
  };

  validatePhone = (e, { name, value }) => {
    const id = name.substring(5);
    const { selectedPlayers } = this.state;
    const player = selectedPlayers[id];
    selectedPlayers[id] = {
      ...player,
      phone: value
    };
    this.setState({ selectedPlayers });
    this.publishValid({ selectedPlayers, noGolfId: this.state.noGolfId });
  };

  validatePlateNumber = (e, { name, value }) => {
    const id = name.substring(11);
    const { selectedPlayers } = this.state;
    const player = selectedPlayers[id];
    selectedPlayers[id] = {
      ...player,
      plateNumber: value
    };
    this.setState({ selectedPlayers });
    this.publishValid({ selectedPlayers, noGolfId: this.state.noGolfId });
  };

  objectChanged = (e, data) => {
    const { checkinDate, checkoutDate } = this.state;
    const index = this.state.accommodations.findIndex(x => x.value === data.value);
    const acc = this.state.accommodations.find(x => x.value === data.value).accommodation;
    const price = calculatePrice(acc, checkinDate, checkoutDate, this.props.editProps.pricings);
    this.setState({ selectedIndex: index, price });
    this.publishValid({
      selectedPlayers: this.state.selectedPlayers,
      noGolfId: this.state.noGolfId,
      selectedIndex: index
    });
  };

  addToAppliedDiscount = discount => {
    const originalPrice = this.props.priceInclVat;
    const discountedPrice = calculateDiscountedPrice(originalPrice, discount);

    this.setState({ appliedDiscounts: [discount] });
    this.props.setPriceWithDiscount({ originalPrice, discountedPrice });
  };

  removeDiscount = () => {
    this.setState({ appliedDiscounts: [] });
    this.props.setPriceWithDiscount(null);
  };

  toggleNoGolfId = (e, { checked }) => {
    this.setState({ noGolfId: checked });
    this.publishValid({ selectedPlayers: this.state.selectedPlayers, noGolfId: checked });

    const priceInclVat = calculatePrice(
      this.props.data.accommodation,
      this.state.checkinDate ? this.state.checkinDate : this.props.editProps.checkinDate,
      this.state.checkoutDate ? this.state.checkoutDate : this.props.editProps.checkoutDate,
      this.props.editProps.pricings,
      checked ? PriceType.RACK : PriceType.SELL
    );

    this.props.setPriceInclVat(priceInclVat);

    if (this.state.appliedDiscounts?.length > 0) {
      this.addToAppliedDiscount(this.state.appliedDiscounts[0]);
    }
  };

  render = () => {
    const { error, data: existingBooking } = this.props;
    const { selectedPlayers, accommodations, selectedIndex, noGolfId } = this.state;
    const createBookingVisible = existingBooking || error;
    const { checkinDate, checkoutDate, checkinDateError, checkoutDateError, comment } = this.state;

    // Create dropdown options for each rat

    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, { disabled: false })
    );

    return (
      <>
        {!createBookingVisible && <Loader active />}
        {createBookingVisible && (
          <Translate>
            {({ translate }) => (
              <Form id={this.props.id} onSubmit={this.createBooking} error={!!error}>
                {this.props.data?.status !== 'Prebooked' && (
                  <Form.Group>
                    <Form.Field>
                      <label>
                        <Translate id="default.checkin" />
                      </label>
                      <DateInput
                        closable
                        dateFormat="YYYY-MM-DD"
                        name="checkinDate"
                        placeholder={translate('default.checkin')}
                        value={checkinDate}
                        error={checkinDateError}
                        onChange={this.validateDateChange}
                        localization="sv"
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>
                        <Translate id="default.checkout" />
                      </label>
                      <DateInput
                        closable
                        dateFormat="YYYY-MM-DD"
                        name="checkoutDate"
                        placeholder={translate('default.checkout')}
                        value={checkoutDate}
                        error={checkoutDateError}
                        onChange={this.validateDateChange}
                        localization="sv"
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>
                        <Translate id="default.object" />
                      </label>
                      <Dropdown
                        disabled={this.state.fetchingAvailability}
                        loading={this.state.fetchingAvailability}
                        selection
                        value={accommodations[selectedIndex].value}
                        onChange={this.objectChanged}
                        onOpen={() => this.fetchAccommodationAvailability(checkinDate, checkoutDate)}
                        error={accommodations[selectedIndex].disabled}
                        // placeholder={accommodations[selectedIndex].text}
                        options={accommodations}
                      />
                    </Form.Field>
                  </Form.Group>
                )}

                {/* <DiscountSelection
                  isAdminAccess={!this.props.isWidgetRequest}
                  rentalUnitTypeId={this.props.editProps.acc.accommodation.type.id}
                  golfClubId={this.props.golfClubId}
                  appliedDiscounts={this.state.appliedDiscounts}
                  onSelect={this.addToAppliedDiscount}
                  onClear={this.removeDiscount}
                /> */}

                <h3>
                  <Form.Field inline>
                    <Translate id="default.guest" />
                    <Checkbox
                      style={{ marginLeft: '1rem' }}
                      onChange={this.toggleNoGolfId}
                      label={translate('default.no-golf-id')}
                      checked={noGolfId}
                    />
                  </Form.Field>
                </h3>

                {!this.state.bookingSuccess && (
                  <>
                    {[...Array(1).keys()].map(slot => (
                      <Fragment key={slot}>
                        <Form.Group widths={2} key={slot} style={{ display: 'flex', alignItems: 'center' }}>
                          {this.props.isWidgetRequest ? (
                            <>
                              <Form.Field
                                control={Input}
                                icon={null}
                                type="text"
                                name={`name${slot}`}
                                mincharacters={2}
                                placeholder={translate('default.name')}
                                onChange={this.playersNameChanged}
                                error={selectedPlayers[slot] && selectedPlayers[slot].nameError}
                                value={selectedPlayers[slot].name}
                              />

                              <Form.Field
                                control={Input}
                                icon={null}
                                type="email"
                                name={`email${slot}`}
                                mincharacters={2}
                                placeholder={translate('default.email')}
                                onChange={this.playersEmailChanged}
                                error={selectedPlayers[slot] && selectedPlayers[slot].emailError}
                                value={selectedPlayers[slot].email}
                              />
                            </>
                          ) : (
                            <>
                              <Form.Field
                                control={Search}
                                icon={null}
                                type="text"
                                name={`name${slot}`}
                                mincharacters={2}
                                placeholder={translate('default.name')}
                                onSearchChange={debounce(this.playersNameChanged, 500, { leading: true })}
                                onResultSelect={(_e, data) => this.handleResultSelected(data, slot)}
                                results={this.state.searchResults[slot].name}
                                error={selectedPlayers[slot] && selectedPlayers[slot].nameError}
                                value={selectedPlayers[slot].name}
                              />
                              <Form.Field
                                control={Search}
                                icon={null}
                                type="email"
                                name={`email${slot}`}
                                mincharacters={2}
                                placeholder={translate('default.email')}
                                onSearchChange={debounce(this.playersEmailChanged, 500, { leading: true })}
                                onResultSelect={(_e, data) => this.handleResultSelected(data, slot)}
                                results={this.state.searchResults[slot].email}
                                error={selectedPlayers[slot] && selectedPlayers[slot].emailError}
                                value={selectedPlayers[slot].email}
                              />
                            </>
                          )}
                          {!noGolfId && (
                            <Form.Field
                              control={Input}
                              icon={null}
                              type="text"
                              name={`golfId${slot}`}
                              placeholder={`${translate('default.golf-id')}`}
                              onChange={this.validateGolfId}
                              error={selectedPlayers[slot] && selectedPlayers[slot].golfIdError}
                              value={selectedPlayers[slot].golfId || ''}
                            />
                          )}
                          <Form.Field
                            control={Input}
                            icon={null}
                            type="phone"
                            name={`phone${slot}`}
                            placeholder={translate('default.phone')}
                            onChange={this.validatePhone}
                            value={selectedPlayers[slot].phone || ''}
                          />
                          {existingBooking.accommodation.type.id === RentalUnitType.CAMPING.id && (
                            <Form.Field
                              control={Input}
                              icon={null}
                              type="text"
                              name={`plateNumber${slot}`}
                              placeholder={translate('default.plate-number')}
                              onChange={this.validatePlateNumber}
                              value={selectedPlayers[slot].plateNumber || ''}
                            />
                          )}
                        </Form.Group>
                        <DiscountSelection
                          isAdminAccess={!this.props.isWidgetRequest}
                          rentalUnitTypeId={this.props.editProps.acc.accommodation.type.id}
                          golfClubId={this.props.golfClubId}
                          appliedDiscounts={this.state.appliedDiscounts}
                          onSelect={this.addToAppliedDiscount}
                          onClear={this.removeDiscount}
                        />

                        {!this.props.isWidgetRequest && (
                          <Form.Group>
                            <Form.Field style={{ width: '100%' }}>
                              <TextArea
                                name={`comment${slot}`}
                                onChange={e =>
                                  this.setState({
                                    ...this.state,
                                    comment: e.target.value
                                  })
                                }
                                value={comment}
                                placeholder={translate('accommodation-sheet.comment')}
                                style={{ minHeight: 100 }}
                              />
                            </Form.Field>
                          </Form.Group>
                        )}
                      </Fragment>
                    ))}
                    {/* <ErrorMessage error={error} /> */}
                    {childrenWithProps}
                  </>
                )}
              </Form>
            )}
          </Translate>
        )}
      </>
    );
  };
}

class EditRentalUnitBooking extends React.Component {
  state = {
    bookingId: 0,
    selectedNrPlayers: 0,
    startTime: '',
    startTimeError: false,
    hasMedicalCertificate: false,
    selectedPlayers: [
      { name: '', email: '' },
      { name: '', email: '' },
      { name: '', email: '' },
      { name: '', email: '' }
    ],
    searchResults: [
      { name: [], email: [] },
      { name: [], email: [] },
      { name: [], email: [] },
      { name: [], email: [] }
    ],
    valid: false
  };

  componentDidMount = () => {
    // const startTimeError = this.validateTimeChange(null, { value: this.state.startTime });
    if (!this.props.data) {
      this.props.dataIsValid(false);
      return;
    }
    const booking = this.props.data;
    const { selectedPlayers } = this.state;
    const players = [booking.bookingUser];
    const { hasMedicalCertificate } = booking;
    players.forEach((p, i) => {
      selectedPlayers[i] = { name: p.name, email: p.email };
      this.playersNameChanged(null, { name: `name${i}`, value: p.name });
      this.playersEmailChanged(null, { name: `email${i}`, value: p.email });
    });
    const selectedNrPlayers = 1;
    this.setState({
      selectedPlayers,
      selectedNrPlayers,
      hasMedicalCertificate,
      startTime: booking.nominalStart?.substring(11),
      bookingId: booking.id
    });
    this.publishValid({ selectedPlayers, selectedNrPlayers });
  };

  getGolfClubPlayers = () => {
    const { players } = this.props.editProps;
    return players.map(x => ({
      title: `${x.player.firstName} ${x.player.lastName}`,
      description: x.player.email,
      key: `${x.player.firstName} ${x.player.lastName}${x.player.email}`
    }));
  };

  playersNameChanged = (e, { name: nameid, value }) => {
    const id = nameid.substring(4);
    const { selectedPlayers, searchResults } = this.state;
    const golfClubPlayers = this.getGolfClubPlayers();
    const player = selectedPlayers[id];
    selectedPlayers[id] = {
      ...player,
      name: value,
      nameError: id === 0 ? !value || value.length < 2 : !!value && value.length < 2
    };
    setTimeout(() => {
      const re = new RegExp(escapeRegExp(selectedPlayers[id].name), 'i');
      const isMatch = result => re.test(result.title);
      searchResults[id].name = filter(golfClubPlayers, isMatch);

      this.setState({ searchResults });
    }, 300);

    if (selectedPlayers[id].name === '' && selectedPlayers[id].email === '') {
      selectedPlayers.splice(id, 1);
      searchResults.splice(id, 1);
      selectedPlayers[3] = {
        name: '',
        nameError: false,
        email: '',
        emailError: false
      };
      searchResults[3] = { name: [], email: [] };
    }

    this.setState({ selectedPlayers });
    this.publishValid({
      selectedPlayers,
      selectedNrPlayers: this.state.selectedNrPlayers,
      startTimeError: this.state.startTimeError
    });
  };

  publishValid = ({ selectedNrPlayers, selectedPlayers, startTimeError }) => {
    const { valid } = this.validInfo({ selectedNrPlayers, selectedPlayers });
    const newValid = valid && !startTimeError;
    this.setState({ newValid });
    this.props.dataIsValid(newValid);
  };

  validInfo = ({ selectedPlayers }) => {
    const validBookingPlayer =
      selectedPlayers[0] &&
      selectedPlayers[0].name &&
      !selectedPlayers[0].nameError &&
      selectedPlayers[0].email &&
      !selectedPlayers[0].emailError;
    const validOtherPlayers =
      !selectedPlayers[1].nameError &&
      !selectedPlayers[1].emailError &&
      !selectedPlayers[2].nameError &&
      !selectedPlayers[2].emailError &&
      !selectedPlayers[3].nameError &&
      !selectedPlayers[3].emailError;
    const valid = validBookingPlayer && validOtherPlayers;
    return { valid, validBookingPlayer, validOtherPlayers };
  };

  playersEmailChanged = (e, { name: emailid, value }) => {
    const id = emailid.substring(5);
    const { selectedPlayers, searchResults } = this.state;
    const golfClubPlayers = this.getGolfClubPlayers();
    const player = selectedPlayers[id];
    selectedPlayers[id] = {
      ...player,
      email: value,
      emailError: id === 0 ? !value || !emailRegex.test(value) : !!value && !emailRegex.test(value)
    };
    setTimeout(() => {
      const re = new RegExp(escapeRegExp(selectedPlayers[id].email), 'i');
      const isMatch = result => re.test(result.description);

      searchResults[id].email = filter(golfClubPlayers, isMatch);
      this.setState({ searchResults });
    }, 300);

    if (selectedPlayers[id].name === '' && selectedPlayers[id].email === '') {
      selectedPlayers.splice(id, 1);
      searchResults.splice(id, 1);
      selectedPlayers[3] = {
        name: '',
        nameError: false,
        email: '',
        emailError: false
      };
      searchResults[3] = { name: [], email: [] };
    }

    this.setState({ selectedPlayers });
    this.publishValid({
      selectedPlayers,
      selectedNrPlayers: this.state.selectedNrPlayers,
      startTimeError: this.state.startTimeError
    });
  };

  handleResultSelected = (data, id) => {
    const name = data.result.title;
    const email = data.result.description;

    const { selectedPlayers, searchResults } = this.state;
    const golfClubPlayers = this.getGolfClubPlayers();
    const player = selectedPlayers[id];
    selectedPlayers[id] = {
      ...player,
      name,
      nameError: id === 0 ? !name || name.length < 2 : !!name && name.length < 2,
      email,
      emailError: id === 0 ? !email || !emailRegex.test(email) : !!email && !emailRegex.test(email)
    };
    setTimeout(() => {
      const re = new RegExp(escapeRegExp(selectedPlayers[id].name), 'i');
      const isMatch = result => re.test(result.title);
      searchResults[id].name = filter(golfClubPlayers, isMatch);
      const re2 = new RegExp(escapeRegExp(selectedPlayers[id].email), 'i');
      const isMatch2 = result => re2.test(result.description);
      searchResults[id].email = filter(golfClubPlayers, isMatch2);
      this.setState({ searchResults });
    }, 300);

    this.setState({ selectedPlayers });
    this.publishValid({
      selectedPlayers,
      selectedNrPlayers: this.state.selectedNrPlayers,
      startTimeError: this.state.startTimeError
    });
  };

  removeSelectedPlayer = slot => {
    const { selectedPlayers } = this.state;
    selectedPlayers.splice(slot, 1);
    selectedPlayers[3] = {
      name: '',
      nameError: false,
      email: '',
      emailError: false
    };
    this.setState({ selectedPlayers });
    this.publishValid({
      selectedPlayers,
      selectedNrPlayers: this.state.selectedNrPlayers,
      startTimeError: this.state.startTimeError
    });
  };

  createBooking = async () => {
    const { selectedPlayers, startTime, hasMedicalCertificate } = this.state;
    const { golfClubId } = this.props;

    const { checkinDate, priceInclVat, acc } = this.props.editProps;
    const booking = {
      rentalUnit: acc.rentalUnit,
      golfClubId,
      bookingUser: selectedPlayers[0],
      nominalstart: `${checkinDate}T${startTime}:00`,
      priceInclVat,
      additionalGuests: [],
      hasMedicalCertificate,
      hasAgreedToTerms: true,
      status: 'Confirmed',
      source: this.props.isWidgetRequest ? Source.WIDGET : Source.ADMIN
    };
    if (this.props.data) {
      booking.Id = this.props.data.id;
    }

    this.props.bookingAction(golfClubId, booking, this.props.onSubmit);
    this.clearForm();
  };

  clearForm = () => {
    const startTime = '';
    const startTimeError = this.validateTimeChange(null, { value: startTime });
    const selectedPlayers = [
      { name: '', email: '' },
      { name: '', email: '' },
      { name: '', email: '' },
      { name: '', email: '' }
    ];
    this.setState({
      selectedNrPlayers: 0,
      selectedPlayers,
      startTime,
      startTimeError,
      hasMedicalCertificate: false
    });
    this.props.dataIsValid(false);
  };

  nullIfEmpty = s => (s === '' ? null : s);

  findRateQuantity = (rateId, booking) => {
    if (!booking) {
      return 0;
    }
    const index = booking.products.findIndex(p => p.rate.id === rateId);
    return index >= 0 ? booking.products[index].quantity : 0;
  };

  validateTimeChange = (_e, { name, value: startTime }) => {
    const { acc } = this.props.editProps;
    const { timesAvailable, bookings } = acc;

    if (!isValidTime(startTime)) {
      this.setState({ startTimeError: 'Felaktigt tidsformat' });
      return;
    }

    // Quick fix - if it's an existing booking dont validate time.
    const existingBooking = bookings.find(b => b.id === this.state.bookingId);
    if (existingBooking) {
      this.setState({ startTime });
      return;
    }

    const isAvailable = timesAvailable.find(x => getTimeHHMM(x.from) <= startTime && getTimeHHMM(x.to) >= startTime);
    // const isAvailable = foundTime !== undefined;
    const startTimeError = !isAvailable && 'Vald tid är inte tillgänlig för bokning';
    this.setState({ startTime, startTimeError });

    if (name) {
      this.publishValid({
        selectedPlayers: this.state.selectedPlayers,
        selectedNrPlayers: this.state.selectedNrPlayers,
        startTimeError
      });
    }
    return startTimeError;
  };

  clearTime = () => {
    this.setState({ startTime: '' });
  };

  changeMedicalCertificate = (e, { checked }) => {
    this.setState({ hasMedicalCertificate: checked });
    this.publishValid({
      selectedPlayers: this.state.selectedPlayers,
      selectedNrPlayers: this.state.selectedNrPlayers,
      startTimeError: this.state.startTimeError
    });
  };

  render = () => {
    const { error } = this.props;
    const { startTime, startTimeError, selectedPlayers } = this.state;

    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, { disabled: false })
    );

    return (
      <>
        {true && (
          <Translate>
            {({ translate }) => (
              <>
                <h3>
                  <Translate id="default.guest" />
                </h3>
                {!this.state.bookingSuccess && (
                  <Form id={this.props.id} onSubmit={this.createBooking} error={!!error}>
                    <TimeInput
                      closable
                      name="startTime"
                      placeholder={translate('default.start-time')}
                      value={startTime}
                      error={!!startTimeError}
                      onChange={this.validateTimeChange}
                      clearable
                      onClear={this.clearTime}
                    />

                    {[...Array(1).keys()].map(slot => (
                      <Fragment key={slot}>
                        <Form.Group widths={2} key={slot} style={{ display: 'flex', alignItems: 'center' }}>
                          {this.props.isWidgetRequest ? (
                            <>
                              <Form.Field
                                control={Input}
                                icon={null}
                                type="text"
                                name={`name${slot}`}
                                mincharacters={2}
                                placeholder={translate('default.name')}
                                onChange={this.playersNameChanged}
                                error={selectedPlayers[slot] && selectedPlayers[slot].nameError}
                                value={selectedPlayers[slot].name}
                              />
                              <Form.Field
                                control={Input}
                                icon={null}
                                type="email"
                                name={`email${slot}`}
                                mincharacters={2}
                                placeholder={translate('default.email')}
                                onChange={this.playersEmailChanged}
                                error={selectedPlayers[slot] && selectedPlayers[slot].emailError}
                                value={selectedPlayers[slot].email}
                              />
                            </>
                          ) : (
                            <>
                              <Form.Field
                                control={Search}
                                icon={null}
                                type="text"
                                name={`name${slot}`}
                                mincharacters={2}
                                placeholder={translate('default.name')}
                                onSearchChange={debounce(this.playersNameChanged, 500, { leading: true })}
                                onResultSelect={(_e, data) => this.handleResultSelected(data, slot)}
                                results={this.state.searchResults[slot].name}
                                error={selectedPlayers[slot] && selectedPlayers[slot].nameError}
                                value={selectedPlayers[slot].name}
                              />
                              <Form.Field
                                control={Search}
                                icon={null}
                                type="email"
                                name={`email${slot}`}
                                mincharacters={2}
                                placeholder={translate('default.email')}
                                onSearchChange={debounce(this.playersEmailChanged, 500, { leading: true })}
                                onResultSelect={(_e, data) => this.handleResultSelected(data, slot)}
                                results={this.state.searchResults[slot].email}
                                error={selectedPlayers[slot] && selectedPlayers[slot].emailError}
                                value={selectedPlayers[slot].email}
                              />
                            </>
                          )}
                        </Form.Group>
                        {/* // <Form.Checkbox
                        //   inline
                        //   label={translate('edit-booking.medical-cert')}
                        //   onChange={this.changeMedicalCertificate}
                        //   checked={this.state.hasMedicalCertificate}
                        // /> */}
                      </Fragment>
                    ))}
                    <ErrorMessage error={error} />
                    {childrenWithProps}
                  </Form>
                )}
              </>
            )}
          </Translate>
        )}
      </>
    );
  };
}

export { EditBooking, EditAccommodationBooking, EditRentalUnitBooking };
