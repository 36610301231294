/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Translate } from 'react-localize-redux';
import moment from 'moment';
import { DateInput } from 'semantic-ui-calendar-react';
import { Button, Checkbox, Dropdown, Form, Grid, Header, Icon, Input, Label, TextArea } from 'semantic-ui-react';
import { RentalUnitType } from '../../core/constants';
import { ActiveRequest, BookingStatus, DiscountTypes, PriceType, Source } from '../../core/enums';
import { IBooking } from '../../pages/AccommodationOverView';
import { calculateBookingPrice } from '../../utils';
import PriceSelection from '../PriceSelection';
import {
  getDiscountedPrice,
  getAvailableAccommodation,
  getValidDiscounts,
  getPriceExclVat,
  getAvailablePriceTypes
} from '../../utils';
import { accommodationBookingService } from '../../core/services';
import { getAvailableObjects, hasBookingChanged } from './BookingModalView';
import DiscountModal, { IDiscount } from '../Discounts/DiscountModal';
import ProductAddonModal from '../ProductAddons/ProductAddonModal';
import ProductAddonsList from '../ProductAddons/ProductAddonsList';
import PackageSelectModal from '../Packages/PackageSelectModal';
import { IPackageSelect } from '../../core/services/product.service';
import SelectedPackage from '../Packages/SelectedPackage';
import { getPublicHolidays } from '../../containers/AccommodationSheet/ducks';
import { connect } from 'react-redux';
import BookingHistoryModal from '../BookingHistory/BookingHistoryModal';

interface IGolfClub {
  id: number;
  currency: string;
  players: any[];
  useOnlinePayment: boolean;
}

interface IBookingErrors {
  accommodation: boolean;
  // golfId: boolean;
  name: boolean;
  numberOfGuests: boolean;
}

let isAccommodationAvailable = true;
let accommodationAvailability: any = null;

function Booking({
  booking,
  publicHolidays,
  translate,
  isWidgetRequest,
  isCustomerDeleteRequest,
  golfClub,
  pendingTransaction,
  calculateOrderPrice,
  setIsChanged,
  setGolfers,
  noGolfId,
  onChange,
  isSuperAdmin
}: {
  booking: IBooking;
  translate: any;
  isWidgetRequest: boolean;
  isCustomerDeleteRequest: boolean;
  golfClub: IGolfClub;
  pendingTransaction: boolean;
  calculateOrderPrice: () => void;
  setIsChanged: (isChanged: boolean) => void;
  setGolfers: (isGolfers: boolean) => void;
  publicHolidays: any;
  noGolfId: boolean;
  onChange: (changedPart: string) => void;
  isSuperAdmin: boolean;
}) {
  const [activeRequest, setActiveRequest] = useState<string | null>(null);
  const [accommodations, setAccommodations] = useState<any[]>([]);
  const [availablePriceTypes, setAvailablePriceTypes] = useState<any[]>([]);
  const [bookingChanged, setBookingChanged] = useState<boolean>(false);
  const [bookingErrors, setBookingErrors] = useState<IBookingErrors>({
    name: false,
    accommodation: false,
    numberOfGuests: false
  });
  // const [hasValidNumberOfGuests, setHasValidNumberOfGuests] = useState(true);
  // const [noGolfId, setNoGolfId] = useState(false);
  const [showBookingHistory, setShowBookingHistory] = useState<boolean>(false);
  const [showDiscounts, setShowDiscounts] = useState<boolean>(false);
  const [showProductAddons, setShowProductAddons] = useState(false);
  const [showPackages, setShowPackages] = useState(false);
  const [holidays, setHolidays] = useState<string[]>([]);

  const hasVariablePricing =
    accommodationAvailability?.pricings.map((x: any) =>
      x.pricings?.filter((y: any) => y.priceCategory?.id === booking?.accommodation?.priceCategory?.id)
    )[0]?.length > 1;

  const isHoliday = holidays.includes(booking.checkInDate);

  useEffect(() => {
    if (publicHolidays) {
      setHolidays(publicHolidays.map((h: string) => h.substring(0, 10)));
    }
  }, [publicHolidays]);

  useEffect(() => {
    // console.log('Booking.tsx useEffect on booking');
    // console.log(booking);

    const { accommodation } = booking;

    ReactDOM.unstable_batchedUpdates(() => {
      setAccommodations([{ value: accommodation.id, text: accommodation.name, disabled: false }]);

      setGolfers(false);

      booking.days = moment(booking.checkOutDate).diff(booking.checkInDate, 'day');
      booking.discountIds = booking.accommodationBookingDiscounts?.map(d => d.discount.id);
      booking.sourceText = Object.keys(Source)
        .find((key: string) => (Source as any)[key] === booking.source)
        ?.toLowerCase();

      setActiveRequest(ActiveRequest.FETCHING_OBJECTS);
    });

    const initializeAccommodationAndOrder = async () => {
      const result = await accommodationBookingService.getAvailableAccommodations(
        golfClub.id,
        booking.checkInDate,
        booking.checkOutDate
      );

      if ('data' in result) {
        accommodationAvailability = result.data;

        if (accommodationAvailability) {
          const availableAccommodation = accommodationAvailability.accommodations.find(
            (a: any) => a.accommodation.id === booking.accommodation.id
          );

          // set product addon availability
          booking.hasAddonsAvailable = availableAccommodation.hasAddonsAvailable;
          booking.hasPackagesAvailable = availableAccommodation.hasPackagesAvailable;

          const acc = getAvailableObjects(accommodationAvailability, booking);

          const isGolfersOnly = acc?.find((a: any) => a.value === booking?.accommodation.id)?.golfersonly;
          setGolfers(isGolfersOnly);

          setAccommodations(acc);
          setAvailablePriceTypes(
            getAvailablePriceTypes(accommodationAvailability.pricings, booking.accommodation.priceCategoryId, translate)
          );
        }
      }

      setActiveRequest(null);

      // run validate booking on every booking change
      let numberOfGuestsInvalid = false;

      if (
        hasVariablePricing &&
        booking?.accommodation?.numberOfBeds &&
        (booking.numberOfGuests === null ||
          Number(booking.numberOfGuests) > Number(booking?.accommodation?.numberOfBeds))
      ) {
        numberOfGuestsInvalid = true;
      }

      const errors = {
        accommodation: !isAccommodationAvailable,
        // golfId:
        //   (!isWidgetRequest && booking.bookingUser.golfId && !golfIdRegex.test(booking.bookingUser.golfId)) ||
        //   (isWidgetRequest && !booking.bookingUser.noGolfId && !booking.bookingUser.golfId),
        name: booking.bookingUser?.name?.length > 0 && booking.bookingUser?.name?.length < 3,
        numberOfGuests: numberOfGuestsInvalid
      };

      setBookingErrors(errors);

      booking.hasErrors = errors.accommodation || errors.name || errors.numberOfGuests;
    };

    if (!isCustomerDeleteRequest) {
      initializeAccommodationAndOrder();
    } else {
      setActiveRequest(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking, golfClub, translate, isWidgetRequest, isCustomerDeleteRequest, hasVariablePricing]);

  useEffect(() => {
    if (accommodationAvailability && isWidgetRequest) {
      const priceType = noGolfId ? PriceType.RACK : PriceType.SELL;
      // const { pricing } = getAvailableAccommodation({
      //   accommodationId: booking.accommodation.id,
      //   defaultAvailability: accommodationAvailability,
      //   numberOfGuests: booking.numberOfGuests,
      //   priceType
      // });
      // const { discountedPrice } = getDiscountedPrice(pricing.price, booking.accommodationBookingDiscounts);

      // booking.originalPrice = pricing.price;
      // booking.priceInclVat = discountedPrice || pricing.price;

      booking.priceType = priceType;
      booking.bookingUser.noGolfId = noGolfId;

      if (noGolfId) {
        booking.bookingUser.golfId = '';
      }

      const price = calculateBookingPrice(booking, accommodationAvailability, isHoliday);
      booking.priceInclVat = price; //discountedPrice || pricing.price;

      updateActiveBookingPrice();
      validateBooking();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noGolfId, isWidgetRequest]);

  const updateDiscountedPrice = (discounts: IDiscount[]) => {
    // TODO: implement multiple discount selection
    const discount = discounts.length > 0 ? discounts[0] : null;

    booking.discountIds = discount ? [discount.id] : [];
    booking.accommodationBookingDiscounts = discount ? [{ discount }] : [];

    const price = calculateBookingPrice(booking, accommodationAvailability, isHoliday);

    booking.priceInclVat = price;
    booking.isChanged = true;
    setIsChanged(true);

    updateActiveBookingPrice();
  };

  const onConfirmSelectPackage = (selectedPackages: IPackageSelect[]) => {
    booking.packages = selectedPackages.filter(p => p.count > 0);

    const price = calculateBookingPrice(booking, accommodationAvailability, isHoliday);

    booking.priceInclVat = price;
    booking.isChanged = true;
    setIsChanged(true);

    if (onChange) {
      onChange('packages');
    }

    updateActiveBookingPrice();
    setShowPackages(false);
    validateBooking();
  };

  const onConfirmSelectAddons = (addons: any, totalPrice: any) => {
    const productAddons =
      totalPrice === 0
        ? []
        : addons
            .filter((addon: any) => addon.itemsCount > 0)
            .map((addon: any) => {
              return {
                chargeAmount: addon.chargeAmount,
                chargeType: addon.chargeType,
                productId: addon.product.id,
                product: addon.product,
                itemsCount: addon.itemsCount
              };
            });

    booking.productAddons = productAddons;

    const price = calculateBookingPrice(booking, accommodationAvailability, isHoliday);
    booking.priceInclVat = price;

    booking.isChanged = true;
    setIsChanged(true);

    if (onChange) {
      onChange('addons');
    }

    updateActiveBookingPrice();
    setShowProductAddons(false);
    validateBooking();
  };

  const updateActiveBookingPrice = () => {
    booking.priceExclVat = getPriceExclVat(booking.priceInclVat, booking.accommodation.typeId, golfClub);
    calculateOrderPrice();
  };

  const accommodationChanged = async (e: any, { value: accommodationId }: any) => {
    const { availableAccommodation, pricing } = getAvailableAccommodation({
      accommodationId,
      defaultAvailability: accommodationAvailability,
      priceType: booking.priceType
    });

    const discounts = getValidDiscounts(availableAccommodation?.accommodation?.type?.id, booking);
    // const selectedPackage = getValidPackage(availableAccommodation?.accommodation?.type?.id, booking);
    // const { discountedPrice } = getDiscountedPrice(pricing.price, discounts);

    const priceTypes = getAvailablePriceTypes(
      accommodationAvailability.pricings,
      availableAccommodation.accommodation.priceCategoryId,
      translate
    );

    booking.isChanged = true;
    setIsChanged(true);

    if (onChange) {
      onChange('booking');
    }

    booking.originalPrice = pricing.price;
    booking.priceType = priceTypes.length > 1 ? booking.priceType : PriceType.SELL;
    booking.accommodation = availableAccommodation.accommodation;
    booking.accommodationId = accommodationId;
    booking.accommodationBookingDiscounts = discounts;
    // booking.selectedPackage = selectedPackage;
    booking.numberOfGuests =
      availableAccommodation.accommodation.typeId === RentalUnitType.LODGE.id ||
      availableAccommodation.accommodation.typeId === RentalUnitType.ROOMS.id
        ? booking.numberOfGuests
        : null;

    booking.hasAddonsAvailable = availableAccommodation.hasAddonsAvailable;
    booking.hasPackagesAvailable = availableAccommodation.hasPackagesAvailable;

    const price = calculateBookingPrice(booking, accommodationAvailability, isHoliday);
    booking.priceInclVat = price; //discountedPrice || pricing.price;

    isAccommodationAvailable = !availableAccommodation.disabled;

    updateActiveBookingPrice();
    setAvailablePriceTypes(priceTypes);
    validateBooking();
  };

  const dateChanged = async (e: any, { name, value }: any) => {
    /*console.log('value :>> ', value);

    if (!moment(value, 'YYYY-MM-DD', true).isValid()) {
      // invalid date
      console.log('invalidDate :>> ', value);

      if (name === 'checkInDate') {
        booking.checkInDate = value;
      } else if (name === 'checkOutDate') {
        booking.checkOutDate = value;
      }

      booking.isChanged = true;
      setIsChanged(true);

      updateActiveBookingPrice();
      // validateBooking();

      return;
    }
*/

    setActiveRequest(ActiveRequest.FETCHING_OBJECTS);

    const newCheckInDate = name === 'checkInDate' ? moment(value) : moment(booking.checkInDate);
    let newCheckOutDate = name === 'checkOutDate' ? moment(value) : moment(booking.checkOutDate);

    if (newCheckOutDate.isSame(newCheckInDate) || newCheckOutDate.isBefore(newCheckInDate)) {
      newCheckOutDate = newCheckInDate.clone().add(1, 'day');
    }

    const nrOfDays = newCheckOutDate.diff(newCheckInDate, 'day');
    const formattedCheckinDate = newCheckInDate.format('YYYY-MM-DD');
    const formattedCheckoutDate = newCheckOutDate.format('YYYY-MM-DD');

    booking.checkInDate = formattedCheckinDate;
    booking.checkOutDate = formattedCheckoutDate;
    booking.days = nrOfDays;
    booking.isChanged = true;
    setIsChanged(true);

    if (onChange) {
      onChange('booking');
    }

    const result = await accommodationBookingService.getAvailableAccommodations(
      golfClub.id,
      formattedCheckinDate,
      formattedCheckoutDate
    );

    if ('data' in result) {
      accommodationAvailability = result.data;
    }

    const acc = getAvailableObjects(accommodationAvailability, booking);
    const { availableAccommodation, pricing } = getAvailableAccommodation({
      accommodationId: booking.accommodation.id,
      defaultAvailability: accommodationAvailability,
      priceType: booking.priceType,
      numberOfGuests: booking.numberOfGuests as any
    });

    const discounts = getValidDiscounts(availableAccommodation?.accommodation?.typeId, booking);

    isAccommodationAvailable = !acc.find((a: any) => a.value === booking.accommodation.id)?.disabled;

    booking.originalPrice = pricing.price;

    // reapply the original discount
    booking.discountIds = discounts && discounts.length > 0 ? [discounts[0].discountId] : [];
    booking.accommodationBookingDiscounts = discounts;

    // always clear selected package when changing dates
    booking.hasAddonsAvailable = availableAccommodation.hasAddonsAvailable;
    booking.hasPackagesAvailable = availableAccommodation.hasPackagesAvailable;
    booking.packages = [];

    const price = calculateBookingPrice(booking, accommodationAvailability, isHoliday);
    booking.priceInclVat = price;

    updateActiveBookingPrice();

    const isGolfersOnly = acc?.find((a: any) => a.value === booking?.accommodation.id)?.golfersonly;
    setGolfers(isGolfersOnly);

    setAccommodations(acc);
    setAvailablePriceTypes(
      getAvailablePriceTypes(accommodationAvailability.pricings, booking.accommodation?.priceCategoryId, translate)
    );

    validateBooking();
    setActiveRequest(null);
  };

  const onCheckedInChange = (e: any, { checked }: any) => {
    booking.checkedIn = checked;
    booking.isChanged = true;
    setIsChanged(true);

    if (onChange) {
      onChange('checkedIn');
    }

    validateBooking();
  };

  const commentChanged = async (e: any, { value }: any) => {
    booking.comment = value;
    booking.isChanged = true;
    setIsChanged(true);

    if (onChange) {
      onChange('comment');
    }

    validateBooking();
  };

  const customerDataChanged = (e: any, { name, value }: any) => {
    if (booking) {
      if (name === 'name') {
        booking.bookingUser.name = value;
      } else if (name === 'golfId') {
        booking.bookingUser.golfId = value;
      } else {
        booking.bookingUser.plateNumber = value;
      }

      booking.isChanged = true;
      setIsChanged(true);

      if (onChange) {
        onChange('booking');
      }

      validateBooking();
    }

    updateActiveBookingPrice();
  };

  const validateBooking = () => {
    let numberOfGuestsInvalid = false;

    if (
      hasVariablePricing &&
      booking?.accommodation?.numberOfBeds &&
      (booking.numberOfGuests === null || Number(booking.numberOfGuests) > Number(booking?.accommodation?.numberOfBeds))
    ) {
      numberOfGuestsInvalid = true;
    }

    const errors = {
      accommodation: !isAccommodationAvailable,
      // golfId:
      //   (!isWidgetRequest && booking.bookingUser.golfId && !golfIdRegex.test(booking.bookingUser.golfId)) ||
      //   (isWidgetRequest && !booking.bookingUser.noGolfId && !booking.bookingUser.golfId),
      name: booking.bookingUser.name.length > 0 && booking.bookingUser.name.length < 3,
      numberOfGuests: numberOfGuestsInvalid
    };

    setBookingErrors(errors);

    booking.hasErrors = errors.accommodation || errors.name || errors.numberOfGuests;
  };

  const validateBookingChanged = (data: any) => {
    const validateBooking = data?.booking || booking;
    const cust = data?.customer;
    const [parentBookingDiscount] = booking.accommodationBookingDiscounts || [];
    const [bookingDiscount] = booking.accommodationBookingDiscounts || [];

    setBookingChanged(hasBookingChanged(validateBooking, booking, cust, parentBookingDiscount, bookingDiscount));
  };

  /*
  const onNoGolfIdClick = (_e: any, { checked }: any) => {
    const priceType = checked ? PriceType.RACK : PriceType.SELL;
    const { pricing } = getAvailableAccommodation({
      accommodationId: booking.accommodation.id,
      defaultAvailability: accommodationAvailability,
      numberOfGuests: booking.numberOfGuests,
      priceType
    });
    const { discountedPrice } = getDiscountedPrice(pricing.price, booking.accommodationBookingDiscounts);

    booking.priceType = priceType;
    booking.originalPrice = pricing.price;
    booking.priceInclVat = discountedPrice || pricing.price;
    booking.bookingUser.noGolfId = checked;

    if (checked) {
      booking.bookingUser.golfId = '';
    }

    setNoGolfId(checked);
    updateActiveBookingPrice();
    validateBooking();
  };
  */

  const priceTypeChange = (priceType: any) => {
    const { pricing } = getAvailableAccommodation({
      accommodationId: booking.accommodation.id,
      defaultAvailability: accommodationAvailability,
      numberOfGuests: booking.numberOfGuests as any,
      priceType
    });
    const { discountedPrice } = getDiscountedPrice(pricing.price, booking.accommodationBookingDiscounts);

    booking.priceType = priceType;
    booking.originalPrice = pricing.price;
    booking.priceInclVat = discountedPrice || pricing.price;
    booking.isChanged = true;
    setIsChanged(true);

    if (onChange) {
      onChange('booking');
    }

    updateActiveBookingPrice();
    validateBooking();
  };

  const numberOfGuestsChange = async (e: any, { value }: any) => {
    if (+value < 1) {
      return;
    }
    // setHasValidNumberOfGuests(true);

    const { pricing } = getAvailableAccommodation({
      accommodationId: booking.accommodation.id,
      defaultAvailability: accommodationAvailability,
      priceType: booking.priceType,
      numberOfGuests: +value as any
    });

    booking.originalPrice = pricing.price;
    booking.numberOfGuests = +value;
    booking.isChanged = true;

    if (onChange) {
      onChange('booking');
    }

    const price = calculateBookingPrice(booking, accommodationAvailability, isHoliday);
    booking.priceInclVat = price;

    setIsChanged(true);

    updateActiveBookingPrice();
    validateBookingChanged({ booking });
    validateBooking();
  };

  const addDiscount = (discount: IDiscount) => {
    // TODO: validation when in CanBeCombined is selected or existing in selectedDiscount
    updateDiscountedPrice([discount]);

    setShowDiscounts(false);

    booking.isChanged = true;
    setIsChanged(true);
  };

  const removeDiscount = () => {
    updateDiscountedPrice([]);

    booking.isChanged = true;
    setIsChanged(true);
  };

  const hasActiveRequest = !!activeRequest;
  // const discountApplied = (booking.originalPrice || 0) > (booking.priceInclVat || 0);
  const discountApplied = booking.discountIds && booking.discountIds.length > 0;
  const validMaxGuests =
    (booking?.accommodation?.type?.id === RentalUnitType.LODGE.id ||
      booking?.accommodation?.type?.id === RentalUnitType.ROOMS.id) &&
    booking?.accommodation?.maxGuests
      ? (booking?.numberOfGuests || 1) <= booking?.accommodation?.maxGuests
      : true;

  const now = moment();
  const checkOutDate = moment(booking?.checkOutDate);
  // const readonlyBooking = checkOutDate.startOf('day').isBefore(now.startOf('day')) || Number(booking?.source || -1) === 0;
  const readonlyBooking = isSuperAdmin
    ? booking?.status === BookingStatus.CANCELLED
    : checkOutDate.isBefore(now) || Number(booking?.source || -1) === 0;
  const allowCheckOutExtension = checkOutDate.startOf('day').isSame(now.startOf('day'));

  const priceTypeText = availablePriceTypes?.find(o => o.value === Number(booking.priceType))?.text;

  let checkinDay = moment(booking.checkInDate).day();
  if (checkinDay === 0) {
    checkinDay = 7;
  }

  const bedsNumbers =
    (booking.accommodation.typeId === RentalUnitType.LODGE.id ||
      booking.accommodation.typeId === RentalUnitType.ROOMS.id) &&
    hasVariablePricing
      ? [...Array(booking.accommodation.numberOfBeds).keys()].map(i => ({ text: i + 1, value: i + 1 }))
      : [];

  return (
    booking && (
      <React.Fragment>
        <Grid>
          {false && (
            <Grid.Column computer={16}>
              <Label>
                Not set yet
                {false && (
                  <React.Fragment>
                    Libor Zajicek:
                    <Label.Detail>walker1@email.cz</Label.Detail>
                  </React.Fragment>
                )}
              </Label>
              <Button color="green" size="mini" onClick={() => setShowDiscounts(!showDiscounts)}>
                Change guest
              </Button>
            </Grid.Column>
          )}

          <Grid.Column width={16}>
            <Header dividing as="h4">
              <Grid>
                <Grid.Row>
                  <Grid.Column computer={4} mobile={8}>
                    <Translate id="default.booking" /> {booking.id}
                    {!isWidgetRequest && !isCustomerDeleteRequest && (
                      <Button
                        size="tiny"
                        color="grey"
                        onClick={() => setShowBookingHistory(true)}
                        style={{ padding: 5, marginLeft: 10 }}
                      >
                        🗎 {translate('edit-booking.history')}
                      </Button>
                    )}
                  </Grid.Column>
                  <Grid.Column computer={12} mobile={8} textAlign="right">
                    {translate('default.price')}: {`${booking.priceInclVat} ${golfClub.currency}`}
                    {discountApplied && (
                      <span
                        style={
                          discountApplied ? { color: 'red', textDecoration: 'line-through', marginLeft: '5px' } : {}
                        }
                      >
                        {
                          /*booking.selectedPackage?.price || */ (booking.originalPrice || 0) +
                            booking.productAddons?.reduce((acc, curr) => acc + curr.chargeAmount, 0)
                        }{' '}
                        {golfClub.currency}
                      </span>
                    )}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Column width={16}></Grid.Column>
              </Grid>
            </Header>
          </Grid.Column>

          <Grid.Column mobile={16} computer={4}>
            <Form.Field>
              <label>
                <Translate id="default.checkin" />
              </label>
              <DateInput
                closable
                dateFormat="YYYY-MM-DD"
                hideMobileKeyboard
                name="checkInDate"
                placeholder={translate('default.checkin')}
                value={booking.checkInDate}
                onChange={dateChanged}
                localization="sv"
                disabled={hasActiveRequest}
                readOnly={readonlyBooking || isCustomerDeleteRequest}
              />
            </Form.Field>
          </Grid.Column>
          <Grid.Column mobile={16} computer={4}>
            <Form.Field>
              <label>
                <Translate id="default.checkout" />
              </label>
              <DateInput
                closable
                dateFormat="YYYY-MM-DD"
                hideMobileKeyboard
                name="checkOutDate"
                placeholder={translate('default.checkout')}
                value={booking.checkOutDate}
                onChange={dateChanged}
                localization="sv"
                disabled={hasActiveRequest}
                readOnly={(readonlyBooking || isCustomerDeleteRequest) && !allowCheckOutExtension}
              />
            </Form.Field>
          </Grid.Column>
          <Grid.Column mobile={16} computer={4}>
            <Form.Field
              label={translate('default.nights')}
              control={Input}
              value={booking.days || 0}
              readOnly
              disabled={hasActiveRequest}
            />
          </Grid.Column>

          <Grid.Column mobile={16} computer={8}>
            {isWidgetRequest || readonlyBooking ? (
              <Form.Field
                label={translate('default.object')}
                control={Input}
                value={booking.accommodation.name}
                readOnly
                error={bookingErrors.accommodation}
              />
            ) : (
              <Form.Field>
                <label>
                  <Translate id="default.object" />
                </label>
                <Dropdown
                  disabled={hasActiveRequest}
                  loading={activeRequest === ActiveRequest.FETCHING_OBJECTS}
                  selection
                  value={booking.accommodation.id}
                  onChange={accommodationChanged}
                  options={accommodations}
                  error={bookingErrors.accommodation}
                />
              </Form.Field>
            )}
          </Grid.Column>
          {(booking.accommodation.typeId === RentalUnitType.LODGE.id ||
            booking.accommodation.typeId === RentalUnitType.ROOMS.id) &&
            hasVariablePricing && (
              <Grid.Column mobile={16} computer={4}>
                <Form.Field>
                  <label>
                    <Translate id="default.no-of-guests" />
                  </label>
                  <Dropdown
                    disabled={hasActiveRequest}
                    loading={activeRequest === ActiveRequest.FETCHING_OBJECTS}
                    selection
                    value={booking.numberOfGuests || ''}
                    onChange={(_e, d) => numberOfGuestsChange(_e, d)}
                    options={bedsNumbers}
                    error={!validMaxGuests || bookingErrors.numberOfGuests}
                  />
                </Form.Field>
              </Grid.Column>
            )}
          {availablePriceTypes && !isWidgetRequest && (
            <Grid.Column mobile={16} computer={4}>
              <PriceSelection
                label={translate('default.select-price')}
                value={booking.priceType}
                textValue={priceTypeText || ''}
                onChange={priceTypeChange}
                options={availablePriceTypes}
                loading={activeRequest === ActiveRequest.FETCHING_OBJECTS}
                disabled={hasActiveRequest}
                readonlyBooking={readonlyBooking}
              />
            </Grid.Column>
          )}
          {false && (
            <Grid.Column mobile={16} computer={4}>
              <Form.Field>
                <label>{translate('default.price')}</label>
                <p>
                  {`${booking.priceInclVat} ${golfClub.currency}`}
                  {discountApplied && (
                    <span
                      style={discountApplied ? { color: 'red', textDecoration: 'line-through', marginLeft: '5px' } : {}}
                    >
                      {booking.originalPrice} {golfClub.currency}
                    </span>
                  )}
                </p>
              </Form.Field>
            </Grid.Column>
          )}
        </Grid>
        <Grid>
          <Grid.Column computer={16} mobile={16}>
            {booking.accommodationBookingDiscounts?.map(d => {
              const discount = d.discount || d;
              return (
                <Label color="green" key={discount.id}>
                  {`${discount.code} - ${discount.amount}${
                    discount.discountType === DiscountTypes.PERCENT ? '%' : ` ${golfClub.currency}`
                  }`}
                  {/* TODO: remove only the selected discount */}
                  {!isCustomerDeleteRequest && <Icon color="red" name="delete" onClick={removeDiscount} />}
                </Label>
              );
            })}
            {booking.source !== 0 &&
              !readonlyBooking &&
              !isCustomerDeleteRequest &&
              booking.status !== BookingStatus.CANCELLED && (
                <React.Fragment>
                  <Button
                    color="green"
                    size="mini"
                    onClick={() => setShowDiscounts(true)}
                    className="mb-1 button-mobile-full"
                  >
                    <Icon name="add" />
                    <Translate id="discount.add-discount-code" />
                  </Button>
                  {booking.hasAddonsAvailable && (
                    <Button
                      color="blue"
                      size="mini"
                      onClick={() => setShowProductAddons(true)}
                      className="mb-1 button-mobile-full"
                    >
                      <Icon name="add" />
                      <Translate id="product-addons.select-addons" />
                    </Button>
                  )}
                  {booking.hasPackagesAvailable && (
                    <Button
                      color="orange"
                      size="mini"
                      onClick={() => setShowPackages(true)}
                      className="mb-1 button-mobile-full"
                    >
                      <Icon name="add" />
                      <Translate id="packages.select-package" />
                    </Button>
                  )}
                </React.Fragment>
              )}
          </Grid.Column>
          {booking.status && booking.status === BookingStatus.CONFIRMED && bookingChanged && (
            <Grid.Column computer={16} textAlign="right">
              <Label color="yellow">{translate('edit-booking.confirmed-booking-changed')}</Label>
            </Grid.Column>
          )}
          <Grid.Column computer={16} className="mobile-hidden"></Grid.Column>
        </Grid>

        {(booking?.productAddons?.length > 0 || booking?.packages?.length > 0) && (
          <Grid>
            <Grid.Column computer={8} mobile={16}>
              <ProductAddonsList golfClubCurrency={golfClub.currency} productAddons={booking?.productAddons} />
            </Grid.Column>
            <Grid.Column computer={8} mobile={16}>
              {booking?.packages && booking?.packages.length > 0 && (
                <>
                  <h3>
                    <Translate id="packages.selected-package" />
                  </h3>
                  <Grid>
                    <Grid.Row className="pb-0">
                      <Grid.Column computer={8} mobile={8}>
                        <div className="field">
                          <label>
                            <Translate id="packages.info" />
                          </label>
                        </div>
                      </Grid.Column>
                      <Grid.Column computer={7} mobile={7}>
                        <div className="field" style={{ textAlign: 'center' }}>
                          <label>
                            <Translate id="product-addons.headers.price" />
                          </label>
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </>
              )}
              {booking?.packages?.map((selectedPackage: IPackageSelect) => (
                <SelectedPackage
                  golfClubCurrency={golfClub.currency}
                  selectedPackage={selectedPackage}
                  checkinDate={booking.checkInDate}
                  isHoliday={isHoliday}
                  key={selectedPackage.id}
                />
              ))}
            </Grid.Column>
          </Grid>
        )}

        {!isWidgetRequest && (
          <>
            <Header as="h3">
              <Translate id="default.guest" />
              <Header.Subheader>
                <Translate id="default.guest-info" />
              </Header.Subheader>
            </Header>

            <Grid stackable>
              {/* {isWidgetRequest && isGolfersOnly !== undefined && !isGolfersOnly && (
            <Grid.Row>
              <Grid.Column width={16}>
                <Form.Checkbox label={translate('default.no-golfid')} checked={noGolfId} onClick={onNoGolfIdClick} />
              </Grid.Column>
            </Grid.Row>
          )} */}

              <Grid.Column width={8}>
                <Form.Field
                  control={Input}
                  type="text"
                  name="name"
                  label={`${translate('default.name')}`}
                  placeholder={`${translate('default.name')}`}
                  onChange={customerDataChanged}
                  error={!!booking.bookingUser?.name && bookingErrors.name}
                  value={booking.bookingUser?.name}
                  disabled={hasActiveRequest}
                  readOnly={isCustomerDeleteRequest || booking?.status === BookingStatus.CANCELLED}
                />
              </Grid.Column>
            </Grid>

            <Grid doubling>
              <Grid.Column width={16}>
                <Form.Field style={{ marginRight: 30 }}>
                  <Checkbox
                    name="checkedIn"
                    toggle
                    onChange={onCheckedInChange}
                    checked={booking.checkedIn}
                    label={translate('edit-booking.checked-in')}
                    disabled={hasActiveRequest}
                    readOnly={isCustomerDeleteRequest || booking?.status === BookingStatus.CANCELLED}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={16}>
                <Form.Field style={{ width: '100%' }}>
                  <TextArea
                    name="comment"
                    onChange={commentChanged}
                    value={booking.comment}
                    placeholder={translate('accommodation-sheet.comment')}
                    style={{ minHeight: 100, fontFamily: 'Lato' }}
                    disabled={hasActiveRequest || booking?.status === BookingStatus.CANCELLED}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid>
          </>
        )}

        <DiscountModal
          show={showDiscounts}
          checkinDate={booking.checkInDate}
          createdDate={booking.created}
          golfClubCurrency={golfClub.currency}
          golfClubId={golfClub.id}
          isAdminAccess={!isWidgetRequest}
          rentalUnitTypeId={booking?.accommodation?.typeId}
          translate={translate}
          onCloseDiscountModal={() => setShowDiscounts(false)}
          onSelect={addDiscount}
        />

        {showProductAddons && (
          <ProductAddonModal
            booking={booking}
            golfClubCurrency={golfClub.currency}
            onClose={() => setShowProductAddons(false)}
            onConfirm={onConfirmSelectAddons}
            show={showProductAddons}
          />
        )}

        {showPackages && (
          <PackageSelectModal
            booking={booking}
            golfClubCurrency={golfClub.currency}
            checkinDay={checkinDay}
            isHoliday={isHoliday}
            onClose={() => setShowPackages(false)}
            onConfirm={onConfirmSelectPackage}
            show={showPackages}
          />
        )}

        {showBookingHistory && (
          <BookingHistoryModal
            golfClubCurrency={golfClub.currency}
            golfClubId={booking.golfClubId || 0}
            id={booking.id}
            show={showBookingHistory}
            onClose={() => setShowBookingHistory(false)}
          />
        )}
      </React.Fragment>
    )
  );
}

function mapStateToProps(state: any) {
  return {
    publicHolidays: getPublicHolidays(state)
  };
}

export default connect(mapStateToProps, null)(Booking);
