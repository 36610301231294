import React from 'react';
import { Button, Checkbox, Icon, Modal, Segment, Table } from 'semantic-ui-react';
import { Translate } from 'react-localize-redux';
import { searchOrders } from '../core/services';
import { CaravanIcon } from '../utils/icons';

// interface SearchResult {
//   orderId: number;
//   createdAt: Date;
//   units: string;
//   bookingUserName: string;
//   priceInclVat: number;
// }

interface SearchBookingResult {
  id: number;
  checkInDate: Date;
  checkOutDate: Date;
  customerName: string;
  customerEmail: string;
  price: number;
  accommodationName: string;
  accommodationTypeId: number;
  status: string;
}

interface OrderSearchResultProps {
  isLoading: boolean;
  searchQuery: string;
  onClose: () => void;
  golfClubId: number;
  onClickBooking: (booking: any, showCancelled: boolean) => void;
}

export class OrderSearchResults extends React.Component<OrderSearchResultProps> {
  state = {
    loading: false,
    displayCancelled: false,
    items: [] as SearchBookingResult[]
  };

  componentDidMount() {
    this.initializeData(false);
  }

  initializeData = async (displayCancelled: boolean) => {
    this.setState({ loading: true });

    try {
      const orders = await searchOrders(this.props.golfClubId, this.props.searchQuery, displayCancelled);

      if ('data' in orders) {
        this.setState({
          loading: false,
          items: orders.data
        });
      }
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  onDisplayCancelledChange = (checked: boolean | undefined) => {
    this.setState({
      displayCancelled: checked
    });

    this.initializeData(checked || false);
  };

  render() {
    const { loading, items } = this.state;
    const { onClose } = this.props;

    return (
      <Translate>
        {({ translate }) => (
          <Modal open size="large" className="position-unset">
            <Modal.Header>
              <div style={{ flexDirection: 'row' }} className="d-flex">
                <span style={{ flex: 1 }} className="d-flex">
                  <Translate
                    id="search-orders.title"
                    data={{ searchBy: this.props.searchQuery, orderCount: items.length }}
                  />
                </span>
                <span style={{ flex: 1, justifyContent: 'flex-end', alignItems: 'center' }} className="d-flex">
                  <Checkbox
                    label={translate('search-orders.show-cancelled')}
                    toggle
                    onChange={(_e, data) => this.onDisplayCancelledChange(data.checked)}
                  />
                </span>
              </div>
            </Modal.Header>
            <Modal.Content scrolling>
              <Segment loading={loading}>
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>
                        <Translate id="search-orders.id" />
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        <Translate id="default.checkin" />
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        <Translate id="default.checkout" />
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        <Translate id="search-orders.customer" />
                      </Table.HeaderCell>
                      <Table.HeaderCell>&nbsp;</Table.HeaderCell>
                      <Table.HeaderCell>
                        <Translate id="default.object" />
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                        <Translate id="search-orders.price" />
                      </Table.HeaderCell>
                      <Table.HeaderCell>&nbsp;</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {items?.map((item: SearchBookingResult, i: number) => {
                      return (
                        <Table.Row key={i}>
                          <Table.Cell>
                            <span style={item.status === 'Cancelled' ? { textDecoration: 'line-through' } : {}}>
                              {item.id}
                            </span>
                          </Table.Cell>
                          <Table.Cell>{item.checkInDate}</Table.Cell>
                          <Table.Cell>{item.checkOutDate}</Table.Cell>
                          <Table.Cell>{item.customerName}</Table.Cell>
                          <Table.Cell>
                            {item.accommodationTypeId === 1 && <CaravanIcon />}
                            {item.accommodationTypeId === 2 && <Icon name="home" />}
                            {item.accommodationTypeId === 7 && <Icon name="bed" />}
                          </Table.Cell>
                          <Table.Cell>{item.accommodationName}</Table.Cell>
                          <Table.Cell singleLine textAlign="right">
                            {item.price.toFixed(2).toString().replace('.', ',')}
                          </Table.Cell>
                          <Table.Cell>
                            <Button
                              icon="folder open outline"
                              loading={this.props.isLoading}
                              size="tiny"
                              positive={item.status === 'Confirmed'}
                              negative={item.status === 'Cancelled'}
                              onClick={() => this.props.onClickBooking(item, item.status === 'Cancelled')}
                            />
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              </Segment>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={onClose}>
                <Translate id="default.close" />
              </Button>
            </Modal.Actions>
          </Modal>
        )}
      </Translate>
    );
  }
}
