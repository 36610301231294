import React, { useEffect, useState } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { Container, Segment, Loader, Dimmer, Label, Icon, Modal, Button, Grid } from 'semantic-ui-react';
import moment from 'moment';
import { connect } from 'react-redux';
import { YearInput, MonthInput } from 'semantic-ui-calendar-react';
import Helmet from '../components/Helmet';
import { getGolfClubs } from '../containers/AccommodationSheet/ducks';
import * as api from '../utils/api';
import { ErrorMessage } from '../components/ErrorMessage';
import { CaravanIcon, GolfBikeIcon, GolfCartIcon } from '../utils';
import AccommodationOverView from './AccommodationOverView';
import RentalUnitOverView from './RentalUnitOverView';
import { ActiveRequest } from '../core/enums';
import { closeOpenOrder, fetchOpenOrder } from '../core/services';

/**
 * Returns all days in passed in year/month
 *
 * @param {object} yearMonth Moment date.
 */
const getDaysInMonth = yearMonth => {
  const days = [];
  const dateStart = yearMonth.clone().startOf('month');
  const dateEnd = yearMonth.clone().endOf('month');
  do {
    days.push({ day: dateStart.date(), isWeekend: [0, 6].includes(dateStart.day()) });
    dateStart.add(1, 'days');
  } while (dateStart.isBefore(dateEnd));
  return days;
};

/**
 * Returns start and end date in month for date
 *
 * @param {object|string} date.
 */
const getMonthPeriodDates = date => {
  const d = typeof date === 'string' ? moment(date) : date;
  return {
    checkInDate: d.clone().startOf('month').format('YYYY-MM-DD'),
    checkOutDate: d.clone().startOf('month').add(1, 'month').format('YYYY-MM-DD')
  };
};

let golfclub = null;
let daysInMonth = [];

function BookingsOverView({ golfClubs, translate }) {
  const [selectedYearMonth, setSelectedYearMonth] = useState(moment());
  const [activeRequest, setActiveRequest] = useState(null);
  const [error, setError] = useState('');
  const [showInformation, setShowInformation] = useState(false);
  const [dateRange, setDateRange] = useState(null);
  const [accommodationTypes, setAccommodationTypes] = useState(null);
  const [rentalUnitTypes, setRentalUnitTypes] = useState(null);
  const [activeOrder, setActiveOrder] = useState(null);
  const [hasRentalUnits, setHasRentalUnits] = useState(false);
  // const [isStickyHeader, setIsStickyHeader] = useState(false);

  // const myRef = useRef();

  [golfclub] = golfClubs;

  useEffect(() => {
    // fetch open order first, then continue loading data
    fetchOpenOrders().then(() => {
      fetchCategories();
      initializeDateRange();
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [golfclub?.id]);

  const fetchOpenOrders = async () => {
    if (golfclub) {
      await fetchOpenOrder(golfclub.id).then(data => {
        if (data && data.code === 200 && data.data) {
          setActiveOrder(data.data);

          return data.data;
        }
      });
    }
  };

  const fetchCategories = async () => {
    if (!accommodationTypes && golfclub) {
      setActiveRequest('fetch');
      const { code, data } = await api.fetchCategories();
      if (code === 200) {
        // categories = data;
        setAccommodationTypes(
          data.accommodationTypes.map(a => ({
            value: a.id,
            text: translate(`accommodation.${a.name}`),
            icon: a.id === 1 ? <CaravanIcon /> : a.id === 2 ? <Icon name="home" /> : <Icon name="bed" />
          }))
        );
        setRentalUnitTypes(
          data.rentalUnitTypes.map(a => ({
            value: a.id,
            text: translate(`rentalUnit.${a.name}`),
            // isAvailableForClub: a.isAvailableForClub,
            // cart icons
            icon: a.id === 3 ? <GolfCartIcon /> : <GolfBikeIcon />
          }))
        );

        setHasRentalUnits(data.rentalUnitTypes?.some(unitType => unitType.isAvailableForClub === true));
      }
    }
  };

  const initializeDateRange = () => {
    setActiveRequest(ActiveRequest.FETCHING_OBJECTS);

    daysInMonth = getDaysInMonth(moment());

    const dates = getMonthPeriodDates(selectedYearMonth);
    const dateFrom = dates.checkInDate;
    const dateTo = dates.checkOutDate;

    setDateRange({ dateFrom, dateTo });
    setActiveRequest(null);
  };

  const onCalendarChange = (event, { name, value }) => {
    setError('');
    const d = name === 'month' ? parseInt(value, 10) - 1 : value; // Month number starts at 0
    const updatedYearMonth = selectedYearMonth.clone().set(name, d);
    setSelectedYearMonth(updatedYearMonth);

    const { checkInDate, checkOutDate } = getMonthPeriodDates(updatedYearMonth);

    setAvailabilityDateRange(checkInDate, checkOutDate);
  };

  const onArrowClick = direction => {
    setError('');
    const d = direction === 'left' ? -1 : 1;
    const updatedYearMonth = selectedYearMonth.clone().add(d, 'month');
    setSelectedYearMonth(updatedYearMonth);
    const { checkInDate, checkOutDate } = getMonthPeriodDates(updatedYearMonth);

    setAvailabilityDateRange(checkInDate, checkOutDate);
  };

  const setAvailabilityDateRange = (checkInDate, checkOutDate) => {
    daysInMonth = getDaysInMonth(checkInDate ? moment(checkInDate) : moment());

    let dateFrom;
    let dateTo;
    if (!checkInDate || !checkOutDate) {
      const dates = getMonthPeriodDates(selectedYearMonth);
      dateFrom = dates.checkInDate;
      dateTo = dates.checkOutDate;
    } else {
      dateFrom = checkInDate;
      dateTo = checkOutDate;
    }

    setDateRange({ dateFrom, dateTo });
  };

  const setAnActiveOrder = order => {
    setActiveOrder(order);
  };

  const onCancelOrderClick = async () => {
    if (activeOrder) {
      setActiveRequest(ActiveRequest.ORDER_CANCEL);

      // close the active order
      await closeOpenOrder(golfclub.id).then(() => {
        // set active order to null
        setActiveOrder(null);

        // need to reload the grids
        initializeDateRange();

        setActiveRequest(null);
      });
    }
  };

  /*
  const onScroll = () => {
    console.log(`window.scrollY: ${myRef.current.scrollTop}`);
    setIsStickyHeader(myRef.current.scrollTop > 110);
  };
  */

  return (
    <>
      <div className="app overview-schedule">
        <Helmet title="OnTee" description="" />
        <div style={{ flex: 1, flexDirection: 'column' }} className="d-flex">
          <div className="mb-0 d-flex" style={{ paddingLeft: '15px' }}>
            <div className="d-flex" style={{ width: '75%', alignItems: 'center' }}>
              <h3 style={{ display: 'inline-block', whiteSpace: 'nowrap', paddingTop: 15, marginRight: 20 }}>
                {golfclub?.name}
              </h3>
              <Icon name="angle left" size="big" onClick={() => onArrowClick('left')} />
              <YearInput
                closable
                name="year"
                dateFormat="YYYY"
                value={selectedYearMonth.format('YYYY')}
                onChange={onCalendarChange}
                localization="sv"
                hideMobileKeyboard
                style={{ width: '100px', marginRight: '10px' }}
              />
              <MonthInput
                closable
                name="month"
                dateFormat="MM"
                value={selectedYearMonth.format('MMM')}
                onChange={onCalendarChange}
                localization="sv"
                hideMobileKeyboard
                style={{ width: '100px', marginRight: 5 }}
              />
              <Icon name="angle right" size="big" onClick={() => onArrowClick('right')} />
            </div>
            <div style={{ alignItems: 'flex-end', width: '25%', textAlign: 'right', paddingRight: '15px' }}>
              <Button
                color="green"
                style={{ marginTop: '20px', marginBottom: 20 }}
                onClick={() => setShowInformation(true)}
              >
                <Icon name="info circle" />
                <Translate id="schema-description.show-information" />
              </Button>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="information mb-1 d-flex">
              <ErrorMessage error={error} />
            </div>
            <AccommodationOverView
              daysInMonth={daysInMonth}
              selectedYearMonth={selectedYearMonth}
              accommodationTypes={accommodationTypes}
              dateRange={dateRange}
              golfclub={golfclub}
              translate={translate}
              activeOrder={activeOrder}
              setActiveOrder={setAnActiveOrder}
              onCancelOrderClick={onCancelOrderClick}
              // isStickyHeader={isStickyHeader}
              hasRentalUnitTypes={rentalUnitTypes && hasRentalUnits}
            />
            {rentalUnitTypes && hasRentalUnits && (
              <RentalUnitOverView
                daysInMonth={daysInMonth}
                selectedYearMonth={selectedYearMonth}
                rentalUnitTypes={rentalUnitTypes}
                dateRange={dateRange}
                golfclub={golfclub}
                translate={translate}
              />
            )}
          </div>
        </div>
        {false && (
          <Container>
            <Segment>
              <Dimmer active={!!activeRequest} inverted>
                <Loader />
              </Dimmer>
              <Grid style={{ height: '100vh' }}>
                <Grid.Row style={{ padding: 0 }}>
                  <Grid.Column />
                </Grid.Row>
                <Grid.Row style={{ height: '90%', paddingTop: 0 }}>
                  <Grid.Column style={{ overflow: 'scroll-y' }} />
                </Grid.Row>
              </Grid>
            </Segment>
          </Container>
        )}
      </div>

      {/* TODO: consider the placement. To be place below the 2 overview or at the top? */}
      {showInformation && (
        <Modal open>
          <Modal.Header>
            <Translate id="schema-description.modal-header" />
          </Modal.Header>
          <Modal.Content>
            <div>
              <table className="schema-table">
                <tbody>
                  <tr>
                    <td>
                      <Label style={{ backgroundColor: '#ffdf00', color: '#000', marginRight: '10px' }}>
                        <Translate id="schema-description.yellow-label" />
                      </Label>
                    </td>
                    <td>
                      <Translate id="schema-description.yellow-description" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Label style={{ backgroundColor: '#ffa500', color: '#000', marginRight: '10px' }}>
                        <Translate id="schema-description.orange-label" />
                      </Label>
                    </td>
                    <td>
                      <Translate id="schema-description.orange-description" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Label style={{ backgroundColor: '#7dbbee', color: '#fff', marginRight: '10px' }}>
                        <Translate id="schema-description.blue-label" />
                      </Label>
                    </td>
                    <td>
                      <Translate id="schema-description.blue-description" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Label style={{ backgroundColor: '#66ddcc', color: '#fff', marginRight: '10px' }}>
                        <Translate id="schema-description.turqoise-label" />
                      </Label>
                    </td>
                    <td>
                      <Translate id="schema-description.turqoise-description" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Label style={{ backgroundColor: '#19c351', color: '#fff', marginRight: '10px' }}>
                        <Translate id="schema-description.green-label" />
                      </Label>
                    </td>
                    <td>
                      <Translate id="schema-description.green-description" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Label style={{ backgroundColor: '#961E1E', color: '#fff', marginRight: '10px' }}>
                        <Translate id="schema-description.red-label" />
                      </Label>
                    </td>
                    <td>
                      <Translate id="schema-description.red-description" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Label style={{ backgroundColor: '#E3E3E3', color: '#fff', marginRight: '10px' }}>
                        <Translate id="schema-description.gray-label" />
                      </Label>
                    </td>
                    <td>
                      <Translate id="schema-description.gray-description" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Label
                        className="has-cleaning"
                        style={{
                          backgroundColor: '#FFF',
                          border: '1px solid #E3E3E3',
                          color: '#000',
                          marginRight: '10px'
                        }}
                      >
                        <Translate id="schema-description.cleaning-label" />
                      </Label>
                    </td>
                    <td>
                      <Translate id="schema-description.cleaning-description" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Label style={{ backgroundColor: '#FFF', color: '#000', marginRight: '10px' }}>
                        <Translate id="schema-description.white-label" />
                      </Label>
                    </td>
                    <td>
                      <Translate id="schema-description.white-description" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => setShowInformation(false)}>{translate('default.close')}</Button>
          </Modal.Actions>
        </Modal>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    golfClubs: getGolfClubs(state)
  };
}

export default withLocalize(connect(mapStateToProps, null)(BookingsOverView));
