import React, { Fragment } from 'react';
import { Translate } from 'react-localize-redux';
import { Accordion, Form, Icon, List, Label, Message, Segment, Table, Button, Grid } from 'semantic-ui-react';
import moment from 'moment';
import { DateInput } from 'semantic-ui-calendar-react';
import 'moment/locale/sv';
import 'moment/locale/de';
import { groupBy, remove } from 'lodash-es';
import { EditableSubListComponent } from './EditableSubListComponent';

import {
  weekdays,
  isValidDate,
  isPositiveInteger,
  todaysDate,
  endOfThisYearDate,
  headerColors,
  colors,
  isSuperAdmin,
  getRandomString
} from '../utils';
import { RentalUnitType, RentalUnitTypeIdsForAccommodations, RentalUnitTypeIdsForEquipments } from '../core/constants';

// moment.locale('sv');
moment.updateLocale('en', {
  week: {
    dow: 1 // Monday is the first day of the week.
  }
});

let newItemIdCounter = -1;

export const accWeekSetupEmpty = () => {
  return {
    id: newItemIdCounter--,
    daysOfWeek: weekdays.map(x => ({ day: x }))
  };
};

const priceWeekSetupEmpty = dataList => {
  const checkedDays = dataList ? dataList.flatMap(x => x.daysOfWeek) : [];
  const daysLeft = weekdays.filter(x => !checkedDays.some(y => y.day === x));
  return {
    id: newItemIdCounter--,
    daysOfWeek: daysLeft.map(x => ({ day: x })),
    pricings: []
  };
};

export const accPricePeriodEmpty = () => ({
  id: newItemIdCounter--,
  startDate: todaysDate(),
  endDate: endOfThisYearDate(),
  priceWeekSetups: [priceWeekSetupEmpty()]
});

const getColorIndex = (dataList, i) => {
  // The last color in colors is grey, which is used
  // if we have multiple items that hash to the same color
  const indexes = dataList.map(x => mod(x.id, colors.length - 1));
  const uniqueAmongSaved = dataList.filter((x, j) => x.id > 0 && indexes[j] === indexes[i]).length === 1;
  const isSaved = dataList[i].id > 0;
  const unique = indexes.filter(x => x === indexes[i]).length === 1;
  if (isSaved && uniqueAmongSaved) {
    return indexes[i];
  }
  return unique ? indexes[i] : colors.length - 1;
};

function mod(n, m) {
  return ((n % m) + m) % m;
}

class AccommodationScheduleViewTitleItem extends React.Component {
  render() {
    const { data } = this.props;
    const start = data.startDate;
    const end = data.endDate;
    return (
      <List as="ol" style={{ margin: 0, display: 'inline-block' }}>
        <List.Item>
          <h3 style={{ marginTop: 15, marginBottom: 0, display: 'inline-block' }}>
            <Translate id="schedule.period" />: {start} - {end}{' '}
          </h3>
          <Label style={{ marginLeft: 15 }} color={data.isActivated ? 'green' : 'orange'} size="medium">
            {data.isActivated ? <Translate id="default.active" /> : <Translate id="default.not-active" />}
          </Label>
          <Label color={data.isValid ? 'green' : 'orange'} size="medium">
            {data.isValid && <Icon name="check" />}
            {data.isValid ? <Translate id="default.valid" /> : <Translate id="default.not-valid" />}
          </Label>
        </List.Item>
      </List>
    );
  }
}

class AccommodationScheduleViewContentItem extends React.Component {
  state = { activeIndex: null };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  getWeekSetupIndex = (day, accommodationWeekSetups) => {
    const setups = accommodationWeekSetups.filter(x => x.daysOfWeek.findIndex(y => y.day === day) >= 0);
    if (setups.length === 0 || setups.length > 1) {
      return 6;
    } else {
      return getColorIndex(accommodationWeekSetups, accommodationWeekSetups.indexOf(setups[0]));
    }
  };

  getWeekdayInformation = (day, accommodationWeekSetups) => {
    const setups = accommodationWeekSetups.filter(x => x.daysOfWeek.findIndex(y => y.day === day) >= 0);
    let result = null;
    if (setups.length === 0) {
      result = {
        value: 'Empty',
        element: (
          <p>
            <Translate id="schedule.no-teetimes" />
          </p>
        )
      };
    } else if (setups.length > 1) {
      result = {
        value: 'Conflict',
        element: (
          <p>
            <Translate id="schedule.conflict" />
          </p>
        )
      };
    } else {
      result = {
        value: setups[0],
        element: <div style={{ display: 'table', margin: 'auto' }}></div>
      };
    }
    return result;
  };

  getPriceInformation = (day, priceWeekSetups) => {
    const setups = priceWeekSetups.filter(x => x.daysOfWeek.findIndex(y => y.day === day) >= 0);
    let result = null;
    if (setups.length === 0) {
      result = {
        value: 'Empty',
        element: (
          <p>
            <Translate id="schedule.no-prices" />
          </p>
        )
      };
    } else if (setups.length > 1) {
      result = {
        value: 'Conflict',
        element: (
          <p>
            <Translate id="schedule.conflict-prices" />
          </p>
        )
      };
    } else {
      result = {
        value: setups[0],
        element: (
          <div style={{ display: 'table', margin: 'auto' }}>
            {setups[0].pricings
              .sort((a, b) => a.priceCategory.id - b.priceCategory.id)
              .map((x, i) => (
                <List key={i}>
                  <Translate>
                    {({ translate }) => <List.Item>{translate(`accommodation.${x.priceCategory.name}`)}</List.Item>}
                  </Translate>
                  {x.rackPriceInclVat && (
                    <List.Item>
                      <Translate id="default.rack-price" />: {x.rackPriceInclVat}
                    </List.Item>
                  )}
                  <List.Item>
                    <Translate id="default.sale-price" />: {x.sellPriceInclVat}
                  </List.Item>
                  {x.memberPriceInclVat >= 0 && x.memberPriceInclVat !== null && x.memberPriceInclVat !== undefined && (
                    <List.Item>
                      <Translate id="default.member-price" />: {x.memberPriceInclVat}
                    </List.Item>
                  )}
                </List>
              ))}
          </div>
        )
      };
    }
    return result;
  };

  createColumns = (weekdays, period, infoFunction, weekSetupProp) => {
    const weekSetups = period[weekSetupProp];
    let colSpan = 1;
    const dayInfo = weekdays.map(day => infoFunction(day, weekSetups));
    const cols = [];
    let i = 0;
    let finished = false;
    const lastIndex = weekdays.length - 1;
    do {
      let same = false;
      for (let j = i + 1; j < weekdays.length; j++) {
        if (dayInfo[i].value === dayInfo[j].value) {
          colSpan += 1;
          same = true;
        } else {
          cols.push(this.getTableCell(i, dayInfo, weekSetups, colSpan));
          i = j;
          colSpan = 1;
          same = false;
          break;
        }
        if (j === lastIndex && same) {
          cols.push(this.getTableCell(i, dayInfo, weekSetups, colSpan));
          finished = true;
        }
      }
      if (i === lastIndex) {
        cols.push(this.getTableCell(i, dayInfo, weekSetups, colSpan));
        finished = true;
      }
    } while (i < weekdays.length && !finished);
    return cols;
  };

  getTableCell = (i, dayInfo, weekSetups, colSpan) => {
    const info = dayInfo[i].element;
    return (
      <Table.Cell
        key={i}
        colSpan={colSpan}
        style={{ backgroundColor: colors[this.getWeekSetupIndex(weekdays[i], weekSetups)] }}
      >
        <span>{info}</span>
      </Table.Cell>
    );
  };

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <List as="ol" style={{ margin: 0 }}>
            <List.Item>
              {this.props.data.pricePeriods.length > 1 && (
                <h3 style={{ marginTop: 15, marginBottom: 0 }}>
                  <Translate id="schedule.price-periods" />:
                </h3>
              )}
              <List size="small" bulleted>
                {this.props.data.pricePeriods.map((x, i) => {
                  return (
                    <Fragment key={i}>
                      <List.Item>
                        {this.props.data.pricePeriods.length > 1 ? `${x.startDate} - ${x.endDate}` : ''}
                        <Table celled fixed columns={7}>
                          <Table.Header>
                            <Table.Row>
                              {weekdays.map((day, i) => (
                                <Table.HeaderCell
                                  key={i}
                                  style={{
                                    backgroundColor: headerColors[this.getWeekSetupIndex(day, x.priceWeekSetups)]
                                  }}
                                >
                                  {translate(`default.${day}`)}
                                </Table.HeaderCell>
                              ))}
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            <Table.Row>
                              {this.createColumns(weekdays, x, this.getPriceInformation, 'priceWeekSetups')}
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      </List.Item>
                    </Fragment>
                  );
                })}
              </List>
            </List.Item>
          </List>
        )}
      </Translate>
    );
  }
}

class AccommodationScheduleEditItem extends React.Component {
  state = {
    isActivated: true,
    startDate: todaysDate(),
    startDateValid: true,
    endDate: endOfThisYearDate(),
    endDateValid: true,
    weekSetups: [],
    weekSetupsValid: false,
    pricePeriods: [],
    pricePeriodsValid: false,
    accordionActiveIndex: [-1]
  };

  componentDidMount = () => {
    const { isActivated, startDate, endDate, weekSetups } = this.props.data;

    this.setState({ isActivated, startDate, endDate, weekSetups });
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { pricePeriodsValid } = this.state;
    if (prevState.pricePeriodsValid !== this.state.pricePeriodsValid) {
      this.props.dataIsValid(pricePeriodsValid);
    }
  };

  pricePeriodsUpdated = (dataList, dataListValid) => {
    this.setState({ pricePeriods: dataList, pricePeriodsValid: dataListValid });
  };

  subcomponentStateUpdated = (dList, dListValid, i) => {
    const { dataList, dataListValid } = this.state;
    dataList[i][this.props.subComponentListName] = dList;
    dataListValid[i][this.props.subComponentListName] = dListValid;
    this.updateState(dataList, dataListValid);
  };

  updateState = (dataList, dataListValid) => {
    const allValid = this.props.allValid(dataList, dataListValid);
    this.setState({ dataList, dataListValid, allValid });
    this.props.stateUpdated(dataList, allValid);
  };

  saveItem = () => {
    const { isActivated, startDate, endDate, weekSetups, pricePeriods } = this.state;
    const data = { isActivated, startDate, endDate, weekSetups, pricePeriods };
    const golfClubId = this.props.data.golfClubId;
    data.golfClubId = golfClubId;
    if (Object.prototype.hasOwnProperty.call(this.props.data, 'id')) {
      data.id = this.props.data.id;
    }
    this.props.saveAction(golfClubId, data, this.props.onSubmit);
  };

  handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps;
    const { accordionActiveIndex } = this.state;
    const expandedIndex = accordionActiveIndex.indexOf(index);
    let removed = false;
    if (expandedIndex >= 0) {
      accordionActiveIndex.splice(expandedIndex, 1);
      removed = true;
    } else {
      accordionActiveIndex.push(index);
    }
    if (!e.ctrlKey && !removed) {
      const otherIndex = 1 - index;
      const expandedOtherIndex = accordionActiveIndex.indexOf(otherIndex);
      if (expandedOtherIndex >= 0) {
        accordionActiveIndex.splice(expandedOtherIndex, 1);
      }
    }
    this.setState({ accordionActiveIndex });
  };

  handleButtonAccordionClick = (indexClicked, otherIndex) => {
    const { accordionActiveIndex } = this.state;
    const expandedIndex = accordionActiveIndex.indexOf(indexClicked);
    if (expandedIndex >= 0) {
      accordionActiveIndex.splice(expandedIndex, 1);
    } else {
      accordionActiveIndex.push(indexClicked);
    }
    const expandedOtherIndex = accordionActiveIndex.indexOf(otherIndex);
    if (expandedOtherIndex >= 0) {
      accordionActiveIndex.splice(expandedOtherIndex, 1);
    }
    this.setState({ accordionActiveIndex });
  };

  validateDatetimeChange = (_e, { name, value }) => {
    let { startDate, endDate, startDateValid, endDateValid } = this.state;

    if (name === 'startDate') {
      startDate = value;
      startDateValid = isValidDate(startDate);
      if (startDateValid && endDate < startDate) {
        endDate = startDate;
      }
    } else {
      endDate = value;
    }
    endDateValid = isValidDate(endDate) && endDate >= startDate;
    this.setState({ startDate, endDate, startDateValid, endDateValid });
  };

  render() {
    const priceTitle = (
      <Accordion.Title>
        <Icon name="dropdown" />
        <h3 style={{ margin: 0, display: 'inline-block' }}>
          <Translate id="schedule.price-periods" />
        </h3>
      </Accordion.Title>
    );
    const priceContent = (
      <Accordion.Content>
        <PricePeriodListComponent
          priceCategories={this.props.priceCategories}
          data={this.props.data.pricePeriods}
          stateUpdated={this.pricePeriodsUpdated}
          login={this.props.login}
        />
      </Accordion.Content>
    );
    const panels = [
      {
        key: 'price',
        title: priceTitle,
        content: priceContent
      }
    ];

    return (
      <Translate>
        {({ translate }) => (
          <Form id={this.props.id} onSubmit={this.saveItem}>
            <List>
              <List.Item>
                <Form.Field>
                  <Form.Checkbox
                    inline
                    label={translate('default.active')}
                    onChange={(e, { checked }) => this.setState({ isActivated: checked })}
                    checked={this.state.isActivated}
                  />
                </Form.Field>
                <Form.Group>
                  <Form.Field>
                    <label>
                      <Translate id="default.start-date" />
                    </label>
                    <DateInput
                      closable
                      dateFormat="YYYY-MM-DD"
                      name={'startDate'}
                      placeholder={translate('default.start-date')}
                      value={this.state.startDate}
                      error={!this.state.startDateValid}
                      onChange={this.validateDatetimeChange}
                      localization="sv"
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>
                      <Translate id="default.end-date" />
                    </label>
                    <DateInput
                      closable
                      dateFormat="YYYY-MM-DD"
                      minDate={this.state.startDate}
                      name={'endDate'}
                      placeholder={translate('default.start-date')}
                      value={this.state.endDate}
                      error={!this.state.endDateValid}
                      onChange={this.validateDatetimeChange}
                      localization="sv"
                    />
                  </Form.Field>
                </Form.Group>
              </List.Item>

              <Message color="green" content={translate('schedule.price-periods-error')} />

              <Accordion
                style={{ marginTop: 15 }}
                activeIndex={this.state.accordionActiveIndex}
                onTitleClick={this.handleAccordionClick}
                styled
                panels={panels}
                exclusive={false}
                fluid
              />
            </List>
          </Form>
        )}
      </Translate>
    );
  }
}

class PricePeriodListComponent extends React.Component {
  validate = (dataList, dataListValid) => {
    dataList.forEach((x, i) => {
      this.validateEndDatetime(dataList, dataListValid, i);
    });
  };

  allValid = (dataList, dataListValid) => {
    return dataList.length > 0 && dataListValid.every(x => this.validItem(x));
  };

  validItem = item => {
    return item.startDate && item.endDate && item.priceWeekSetups;
  };

  emptyValidationItem = () => {
    return { startDate: true, endDate: true };
  };

  validateEndDatetime = (dataList, dataListValid, i) => {
    dataListValid[i].endDate = dataList[i].endDate >= dataList[i].startDate;
    dataListValid[i].startDate = isValidDate(dataList[i].startDate);
    dataListValid[i].endDate = dataListValid[i].endDate && isValidDate(dataList[i].endDate);
  };

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <EditableSubListComponent
            data={this.props.data}
            itemName={translate('club-admin.price-period')}
            addHelpText={translate('schedule.price-periods-descr')}
            stateUpdated={this.props.stateUpdated}
            error={this.props.error}
            validate={this.validate}
            emptyItem={accPricePeriodEmpty}
            emptyValidationItem={this.emptyValidationItem}
            allValid={this.allValid}
            subComponentListName="priceWeekSetups"
            subComponent={
              <PricePeriodComponent
                priceCategories={this.props.priceCategories}
                validateEndDatetime={this.validateEndDatetime}
                login={this.props.login}
              />
            }
          />
        )}
      </Translate>
    );
  }
}

class PricePeriodComponent extends React.Component {
  validateDatetimeChange = (_e, { name, value }) => {
    const { dataList, dataListValid } = this.props;
    const propNameLength = name.startsWith('start') ? 9 : 7;
    const i = name.substring(propNameLength);
    const prop = name.substring(0, propNameLength);
    dataList[i][prop] = value;
    this.props.validateEndDatetime(dataList, dataListValid, i);
    this.props.updateState(dataList, dataListValid);
  };

  render() {
    const i = this.props.index;
    const item = this.props.dataList[i];
    const itemValid = this.props.dataListValid[i];
    return (
      <Translate>
        {({ translate }) => (
          <Segment color={item.id < 0 ? 'green' : null}>
            {this.props.dataList.length !== 1 && (
              <Icon
                style={{ float: 'right' }}
                link
                color="red"
                name="delete"
                size="large"
                onClick={e => {
                  e.preventDefault();
                  this.props.removeItem(i);
                }}
              />
            )}
            <Form.Group style={{ display: 'flex', alignItems: 'center' }}>
              <Form.Field>
                <label>{translate('default.start-date')}</label>
                <DateInput
                  closable
                  dateFormat="YYYY-MM-DD"
                  name={'startDate' + i}
                  placeholder={translate('default.start-date')}
                  value={item.startDate}
                  error={!itemValid.startDate}
                  onChange={this.validateDatetimeChange}
                  localization="sv"
                />
              </Form.Field>
              <Form.Field>
                <label>{translate('default.end-date')}</label>
                <DateInput
                  closable
                  dateFormat="YYYY-MM-DD"
                  minDate={item.startDate}
                  name={'endDate' + i}
                  placeholder={translate('default.end-date')}
                  value={item.endDate}
                  error={!itemValid.endDate}
                  onChange={this.validateDatetimeChange}
                  localization="sv"
                />
              </Form.Field>
            </Form.Group>
            <PriceWeekSetupListComponent
              priceCategories={this.props.priceCategories}
              data={item.priceWeekSetups}
              stateUpdated={(dataList, dataListValid) => this.props.stateUpdated(dataList, dataListValid, i)}
              login={this.props.login}
            />
          </Segment>
        )}
      </Translate>
    );
  }
}

class PriceWeekSetupListComponent extends React.Component {
  validate = (dataList, dataListValid) => {
    dataList.forEach((x, i) => {
      dataListValid[i].daysOfWeek = dataList[i].daysOfWeek.length > 0;
    });
  };

  allValid = (dataList, dataListValid) => {
    return dataList.length > 0 && dataListValid.every(x => this.validItem(x));
  };

  validItem = item => {
    return item.daysOfWeek && item.pricings;
  };

  emptyValidationItem = () => {
    return {};
  };

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <EditableSubListComponent
            data={this.props.data}
            listLabel={translate('club-admin.week-setup-label')}
            itemName={translate('club-admin.week-setup')}
            addHelpText={translate('schedule.pricing-info')}
            stateUpdated={this.props.stateUpdated}
            error={this.props.error}
            validate={this.validate}
            emptyItem={priceWeekSetupEmpty}
            emptyValidationItem={this.emptyValidationItem}
            allValid={this.allValid}
            subComponentListName="priceDayTimespans"
            subComponent={
              <WeekSetupComponent
                type="PricingComponent"
                validate={this.validate}
                priceCategories={this.props.priceCategories}
                login={this.props.login}
              />
            }
          />
        )}
      </Translate>
    );
  }
}

/**
 * dataList, dataListValid, index, updateState, removeItem, stateUpdated (injected by EditableSubListComponent)
 * priceCategories
 */
class WeekSetupComponent extends React.Component {
  validateDayChange = ({ checked }, day, i) => {
    const { dataList, dataListValid } = this.props;
    if (checked) {
      dataList[i].daysOfWeek.push({ day });
    } else {
      const index = dataList[i].daysOfWeek.findIndex(y => y.day === day);
      dataList[i].daysOfWeek.splice(index, 1);
    }
    this.props.validate(dataList, dataListValid);
    this.props.updateState(dataList, dataListValid);
  };

  subcomponentStateUpdated = (dList, dListValid, i) => {
    const { dataList, dataListValid } = this.props;
    dataList[i].pricings = dList;
    dataListValid[i].pricings = dListValid;
    this.props.validate(dataList, dataListValid);
    this.props.updateState(dataList, dataListValid);
  };

  render() {
    const i = this.props.index;
    const item = this.props.dataList[i];
    const color = colors[getColorIndex(this.props.dataList, i)];
    return (
      <Translate>
        {({ translate }) => (
          <Segment style={{ backgroundColor: color }} color={item.id < 0 ? 'green' : null}>
            {this.props.dataList.length !== 1 && (
              <Icon
                style={{ float: 'right' }}
                link
                color="red"
                name="delete"
                size="large"
                onClick={e => {
                  e.preventDefault();
                  this.props.removeItem(i);
                }}
              />
            )}
            <Form.Group style={{ display: 'flex', alignItems: 'center' }}>
              {weekdays.map((day, j) => (
                <Form.Checkbox
                  key={j}
                  label={translate(`default.${day}`)}
                  checked={item.daysOfWeek.findIndex(y => y.day === day) >= 0}
                  onChange={(_e, data) => this.validateDayChange(data, day, i)}
                />
              ))}
            </Form.Group>
            {this.props.type === 'PricingComponent' && (
              <PricingComponent
                priceCategories={this.props.priceCategories}
                data={item.pricings}
                index={i}
                stateUpdated={this.subcomponentStateUpdated}
                login={this.props.login}
                translate={translate}
              />
            )}
          </Segment>
        )}
      </Translate>
    );
  }
}

class PricingComponent extends React.Component {
  state = {
    dataList: [],
    dataListValid: [],
    allValid: false,
    groupedPricings: {}
  };

  componentDidMount = () => {
    if (!this.props.data) {
      return;
    }
    const { data } = this.props;
    const { dataList, dataListValid } = this.state;
    data.forEach((x, i) => {
      dataList[i] = x;
      dataListValid[i] = this.initializeDatalistValid(x.priceCategory.id);
    });
    const groupedPricings = this.groupPricings();

    if (!dataList.every(x => x.id < 0)) {
      this.validateAll(dataList, dataListValid, groupedPricings);
    }

    this.updateState(dataList, dataListValid, this.props.index);
  };

  updateState = (dataList, dataListValid, index) => {
    const allValid = this.allValid(dataList, dataListValid);
    this.props.stateUpdated(dataList, allValid, index);
  };

  initializeDatalistValid = categoryId => ({
    sellPriceInclVat: true,
    memberPriceInclVat: true,
    rackPriceInclVat: true,
    numberOfGuests: true,
    categoryId
  });

  validateAll = (dataList, dataListValid, groupedPricings) => {
    dataList.forEach((x, i) => {
      this.validatePricingInternal(
        {
          rackPriceInclVat: x.rackPriceInclVat,
          sellPriceInclVat: x.sellPriceInclVat,
          memberPriceInclVat: x.memberPriceInclVat,
          numberOfGuests: x.numberOfGuests
        },
        x.priceCategory,
        dataList,
        dataListValid,
        x.id || x.tempId,
        groupedPricings[x.priceCategory.id]
      );
    });
  };

  allValid = (dataList, dataListValid) => {
    return dataList.length > 0 && dataListValid.every(x => this.validItem(x));
  };

  validItem = item =>
    item.rackPriceInclVat &&
    item.sellPriceInclVat &&
    item.memberPriceInclVat !== null &&
    item.memberPriceInclVat !== undefined &&
    (item.numberOfGuests === null || item.numberOfGuests === undefined || !!item.numberOfGuests);

  validatePricingInternal = (
    { rackPriceInclVat, sellPriceInclVat, memberPriceInclVat, numberOfGuests },
    priceCategory,
    dataList,
    dataListValid,
    dataListId = null,
    groupedPricings = null
  ) => {
    const index =
      dataListId !== null
        ? dataList.findIndex(x => x.id === dataListId || x.tempId === dataListId)
        : dataList.findIndex(x => x.priceCategory.id === priceCategory.id);
    if (index >= 0) {
      const pricing = dataList[index];
      const pricingValid = dataListValid[index];
      const groupedDataListValid = dataListValid.filter(x => x.categoryId === priceCategory.id);

      if (sellPriceInclVat !== null && sellPriceInclVat !== undefined) {
        pricing.sellPriceInclVat = sellPriceInclVat;
        pricingValid.sellPriceInclVat = isPositiveInteger(sellPriceInclVat);
      }
      if (rackPriceInclVat !== null && rackPriceInclVat !== undefined) {
        pricing.rackPriceInclVat = rackPriceInclVat;
        if (groupedDataListValid?.length > 1) {
          groupedDataListValid.forEach(x => {
            x.rackPriceInclVat = this.validateGroupedRackPrice(rackPriceInclVat, groupedPricings);
          });
        } else {
          pricingValid.rackPriceInclVat = isPositiveInteger(rackPriceInclVat) || rackPriceInclVat === '';
        }
      }
      if (memberPriceInclVat !== null && memberPriceInclVat !== undefined) {
        pricing.memberPriceInclVat = memberPriceInclVat;
        if (groupedDataListValid?.length > 1) {
          groupedDataListValid.forEach(x => {
            x.memberPriceInclVat = this.validateGroupedMemberPrice(memberPriceInclVat, groupedPricings);
          });
        } else {
          pricingValid.memberPriceInclVat =
            isPositiveInteger(memberPriceInclVat) || Number(memberPriceInclVat) === 0 || memberPriceInclVat === '';
        }
      }
      if (numberOfGuests !== null && numberOfGuests !== undefined) {
        pricing.numberOfGuests = numberOfGuests;
        if (groupedDataListValid?.length > 1) {
          groupedDataListValid.forEach(x => {
            x.numberOfGuests = this.validateGroupedGuestsNumber(groupedPricings);
          });
        } else {
          pricingValid.numberOfGuests = isPositiveInteger(numberOfGuests);
        }
      }
    }
  };

  validateGroupedMemberPrice = (currentPrice, pricings) =>
    (currentPrice >= 0 && pricings.every(x => +x.memberPriceInclVat >= 0)) ||
    (!currentPrice && pricings.every(x => !x.memberPriceInclVat));

  validateGroupedRackPrice = (currentPrice, pricings) =>
    (currentPrice > 0 && pricings.every(x => +x.rackPriceInclVat > 0)) ||
    (!currentPrice && pricings.every(x => !x.rackPriceInclVat));

  validateGroupedGuestsNumber = pricings => {
    const numberOfGuests = pricings.map(x => +x.numberOfGuests);

    return (
      numberOfGuests.includes(1) && numberOfGuests.every(x => x > 0) && new Set(numberOfGuests).size === pricings.length
    );
  };

  removePricing = (event, id, index) => {
    event.preventDefault();
    const { dataList, dataListValid } = this.state;
    const pricings = dataList;

    const pricingIndex = pricings.findIndex(x => x.id === id || x.tempId === id);
    pricings.splice(pricingIndex, 1);
    dataListValid.splice(pricingIndex, 1);

    const groupedPricings = this.groupPricings(dataList);
    this.validateAll(dataList, dataListValid, groupedPricings);

    this.updateState(dataList, dataListValid, index);
  };

  handleGuestPricingAddButtonClick = (event, index, priceCategory, numberOfGuests = 0) => {
    event.preventDefault();
    const { dataList, dataListValid } = this.state;
    const pricings = dataList;

    const currentPricing = dataList.find(x => x.priceCategory.id === priceCategory.id);
    if (!currentPricing.numberOfGuests) {
      currentPricing.numberOfGuests = 1;
    }

    const newPrice = {
      tempId: getRandomString(),
      priceCategory,
      sellPriceInclVat: currentPricing.sellPriceInclVat,
      memberPriceInclVat: currentPricing.memberPriceInclVat,
      rackPriceInclVat: currentPricing.rackPriceInclVat,
      numberOfGuests: +numberOfGuests + 1
    };
    pricings.push(newPrice);
    dataListValid.push(this.initializeDatalistValid(priceCategory.id));

    this.updateState(dataList, dataListValid, index);
    const groupedPricings = this.groupPricings();

    this.validatePriceCategory(
      { numberOfGuests: newPrice.numberOfGuests },
      index,
      priceCategory,
      newPrice.tempId,
      groupedPricings[priceCategory.id]
    );
  };

  groupPricings = dataList => {
    const groupedPricings = groupBy(dataList || this.state.dataList, 'priceCategory.id');
    this.orderPricings(groupedPricings);
    this.setState({ groupedPricings });
    return groupedPricings;
  };

  orderPricings = pricings => {
    Object.entries(pricings).forEach(([_key, value]) => {
      if (value.length > 1) {
        value = value.sort((a, b) => a.numberOfGuests - b.numberOfGuests);
      }
    });
  };

  getPricingRackPrice = category => {
    const pricings = this.state.dataList;
    const pricing = pricings.find(x => x.priceCategory.id === category.id);

    return pricing?.rackPriceInclVat || '';
  };

  getPricingSellPrice = category => {
    const pricings = this.state.dataList;
    const pricing = pricings.find(x => x.priceCategory.id === category.id);

    return pricing?.sellPriceInclVat || '';
  };

  getPricingMemberPrice = category => {
    const pricings = this.state.dataList;
    const pricing = pricings.find(x => x.priceCategory.id === category.id);

    return pricing?.memberPriceInclVat !== undefined && pricing?.memberPriceInclVat !== null
      ? pricing.memberPriceInclVat
      : '';
  };

  getIsGolfersOnly = category => {
    const pricings = this.state.dataList;
    const pricing = pricings.find(x => x.priceCategory.id === category.id);
    return !!pricing?.isGolfersOnly;
  };

  getVariableGuestPricing = category => {
    const pricings = this.state.dataList;
    const pricing = pricings.find(x => x.priceCategory.id === category.id);
    return pricing?.variableGuestPricings;
  };

  hasPriceCategory = category => {
    const pricings = this.state.dataList;
    const pricing = pricings.find(x => x.priceCategory.id === category.id);
    return !!pricing;
  };

  addRemovePriceCategory = ({ checked }, index, priceCategory) => {
    const { dataList, dataListValid } = this.state;

    if (checked) {
      const newPrice = { priceCategory, sellPriceInclVat: '0' };
      dataList.push(newPrice);
      dataListValid.push(this.initializeDatalistValid(priceCategory.id));
    } else {
      remove(dataList, x => x.priceCategory.id === priceCategory.id);
      remove(dataListValid, x => x.categoryId === priceCategory.id);
      delete this.state.groupedPricings[priceCategory.id];
    }
    this.updateState(dataList, dataListValid, index);
  };

  validatePriceCategory = (value, index, category, dataListId = null, groupPricings = null) => {
    const { dataList, dataListValid } = this.state;
    this.validatePricingInternal(value, category, dataList, dataListValid, dataListId, groupPricings);
    this.updateState(dataList, dataListValid, index);
    this.groupPricings();
  };

  updateIsGolfersOnly = ({ checked }, i, category) => {
    const { dataList, dataListValid } = this.state;
    const index = dataList.findIndex(x => x.priceCategory.id === category.id);
    if (index >= 0) {
      const pricing = dataList[index];
      pricing.isGolfersOnly = checked;
      this.updateState(dataList, dataListValid, i);
    }
  };

  hasValidSellPrice = category => {
    return this.hasValidPrice(category, 'sellPriceInclVat');
  };

  hasValidRackPrice = category => {
    return this.hasValidPrice(category, 'rackPriceInclVat');
  };

  hasValidMemberPrice = category => {
    return this.hasValidPrice(category, 'memberPriceInclVat');
  };

  hasValidPrice = (category, prop) => {
    const { dataList, dataListValid } = this.state;
    const index = dataList.findIndex(x => x.priceCategory.id === category.id);
    if (index >= 0) {
      return dataListValid[index][prop];
    }
    return true;
  };

  getGuestsNumberText = (groupedPricing, index) => {
    const currentPricing = groupedPricing[index];
    const nextPricing = index < groupedPricing.length ? groupedPricing[index + 1] : null;

    if (index === 0) {
      return `1${nextPricing.numberOfGuests < 3 ? '' : `-${nextPricing.numberOfGuests - 1}`}`;
    }
    if (index === groupedPricing.length - 1) {
      return `${currentPricing.numberOfGuests} ${this.props.translate('default.or-more')}`;
    }
    return `${currentPricing.numberOfGuests}${
      currentPricing.numberOfGuests === nextPricing.numberOfGuests - 1 ||
      currentPricing.numberOfGuests === nextPricing.numberOfGuests
        ? ''
        : `-${nextPricing.numberOfGuests - 1}`
    }`;
  };

  isGroupingValid = categoryId => {
    const { dataListValid } = this.state;
    const groupedPriceCategory = dataListValid.filter(x => x.categoryId === categoryId);
    const allValid = groupedPriceCategory.every(x => !!x.numberOfGuests);
    return allValid;
  };

  render() {
    const { index } = this.props;

    return (
      <Translate>
        {({ translate }) => (
          <div>
            <List.Item>
              <Form.Field>
                <h3>Priser</h3>
                <Message info>
                  Försäljningspris är obligatoriskt för de priser som ska aktiveras. "Rack" pris är valfritt och används
                  om man vill använda ett högre pris till icke golfare. Är "Rack" pris ifyllt behöver kunden ange ett
                  golf-id vid bokningen för att ta del av det lägre försäljningspriset.
                </Message>

                <Grid columns={8}>
                  <Grid.Row>
                    <Grid.Column>Category</Grid.Column>
                    <Grid.Column>
                      <Translate id="default.golfers-only" />
                    </Grid.Column>
                    <Grid.Column width={2}>
                      <Translate id="default.sale-price" />
                    </Grid.Column>
                    <Grid.Column style={{ margin: '0px 35px' }}>
                      <Translate id="default.member-price" />
                    </Grid.Column>
                    <Grid.Column>
                      <Translate id="default.rack-price" />
                    </Grid.Column>
                    <Grid.Column style={{ marginLeft: '20px' }}>
                      <Translate id="default.no-of-guests" />
                    </Grid.Column>
                    <Grid.Column />
                    <Grid.Column />
                  </Grid.Row>
                  {this.props.priceCategories.map(category => {
                    const disableCategory = category.accommodationTypeId === 6 && !isSuperAdmin(this.props); // Golfbikes
                    return (
                      <Fragment key={category.id}>
                        {category.accommodationTypeId === 6 && !isSuperAdmin(this.props) ? (
                          <span />
                        ) : (
                          <Grid.Row>
                            <Grid.Column>
                              <Form.Checkbox
                                inline
                                label={`${translate(`accommodation.${category.name}`)}: `}
                                onChange={(_e, d) => this.addRemovePriceCategory(d, index, category)}
                                checked={this.hasPriceCategory(category)}
                                width={3}
                                disabled={disableCategory}
                              />
                            </Grid.Column>

                            {this.state.groupedPricings[category.id]?.length > 1 && (
                              <Grid.Column width={14}>
                                {this.state.groupedPricings[category.id].map((x, i) => (
                                  <Grid key={`${x.id || x.tempId}`}>
                                    <Grid.Row>
                                      <Grid.Column width={2}>
                                        <Form.Checkbox
                                          label={translate('default.golfers-only')}
                                          checked={
                                            RentalUnitTypeIdsForEquipments.includes(category.accommodationTypeId) ||
                                            this.getIsGolfersOnly(category)
                                          }
                                          disabled={
                                            RentalUnitTypeIdsForEquipments.includes(category.accommodationTypeId) ||
                                            disableCategory
                                          }
                                          onChange={(_e, d) => this.updateIsGolfersOnly(d, index, category)}
                                        />
                                      </Grid.Column>
                                      <Grid.Column width={3}>
                                        <Form.Input
                                          type="number"
                                          name="sellPrice"
                                          placeholder={translate('default.sale-price')}
                                          onChange={(_e, d) =>
                                            this.validatePriceCategory(
                                              { sellPriceInclVat: d.value },
                                              index,
                                              category,
                                              x.id || x.tempId
                                            )
                                          }
                                          value={x.sellPriceInclVat}
                                          error={!this.hasValidSellPrice(category)}
                                        />
                                      </Grid.Column>
                                      <Grid.Column width={3}>
                                        <Form.Input
                                          type="number"
                                          name="memberPrice"
                                          placeholder={translate('default.member-price')}
                                          onChange={(_e, d) =>
                                            this.validatePriceCategory(
                                              { memberPriceInclVat: d.value },
                                              index,
                                              category,
                                              x.id || x.tempId,
                                              this.state.groupedPricings[category.id]
                                            )
                                          }
                                          value={x.memberPriceInclVat}
                                          error={
                                            !this.validateGroupedMemberPrice(
                                              x.memberPriceInclVat,
                                              this.state.groupedPricings[category.id]
                                            )
                                          }
                                        />
                                      </Grid.Column>
                                      <Grid.Column width={3}>
                                        <Form.Input
                                          type="number"
                                          name="rackPrice"
                                          disabled={
                                            !this.hasPriceCategory(category) ||
                                            !RentalUnitTypeIdsForAccommodations.includes(
                                              category.accommodationTypeId
                                            ) ||
                                            disableCategory
                                          }
                                          placeholder={translate('default.rack-price')}
                                          onChange={(_e, d) =>
                                            this.validatePriceCategory(
                                              { rackPriceInclVat: d.value },
                                              index,
                                              category,
                                              x.id || x.tempId,
                                              this.state.groupedPricings[category.id]
                                            )
                                          }
                                          value={x.rackPriceInclVat || ''}
                                          error={
                                            !this.validateGroupedRackPrice(
                                              x.rackPriceInclVat,
                                              this.state.groupedPricings[category.id]
                                            )
                                          }
                                        />
                                      </Grid.Column>
                                      <Grid.Column width={3} style={{ padding: '0' }}>
                                        <Form.Field>
                                          <div style={{ display: 'inline-block', width: '65px', marginRight: '5px' }}>
                                            <Form.Input
                                              type="number"
                                              name="numberOfGuests"
                                              min={1}
                                              disabled={i === 0 && x.numberOfGuests > 0}
                                              placeholder={translate('default.no-of-guests')}
                                              onChange={(_e, d) =>
                                                this.validatePriceCategory(
                                                  { numberOfGuests: +d.value },
                                                  index,
                                                  category,
                                                  x.id || x.tempId,
                                                  this.state.groupedPricings[category.id]
                                                )
                                              }
                                              value={x.numberOfGuests}
                                              error={
                                                !this.validateGroupedGuestsNumber(
                                                  this.state.groupedPricings[category.id]
                                                )
                                              }
                                            />
                                          </div>
                                          <span>
                                            {this.getGuestsNumberText(this.state.groupedPricings[category.id], i)}
                                          </span>
                                        </Form.Field>
                                      </Grid.Column>

                                      {(category.accommodationTypeId === RentalUnitType.LODGE.id ||
                                        category.accommodationTypeId === RentalUnitType.ROOMS.id) && (
                                        <Grid.Column width={2}>
                                          <Button
                                            icon
                                            style={{ margin: '2px', padding: '7px' }}
                                            onClick={e =>
                                              this.handleGuestPricingAddButtonClick(
                                                e,
                                                index,
                                                category,
                                                x.numberOfGuests
                                              )
                                            }
                                          >
                                            <Icon name="add" />
                                          </Button>
                                          {i !== 0 && (
                                            <Button
                                              icon
                                              style={{ margin: '2px', padding: '7px' }}
                                              onClick={e => this.removePricing(e, x.id || x.tempId, index)}
                                            >
                                              <Icon name="minus" />
                                            </Button>
                                          )}
                                        </Grid.Column>
                                      )}
                                    </Grid.Row>
                                  </Grid>
                                ))}
                                {!this.isGroupingValid(category.id) && (
                                  <Message
                                    style={{ padding: '3px' }}
                                    negative
                                    content={translate('error.number-of-guests-error')}
                                  />
                                )}
                              </Grid.Column>
                            )}
                            {(!this.state.groupedPricings[category.id]?.length ||
                              this.state.groupedPricings[category.id].length < 2) && (
                              <Grid.Column width={14}>
                                <Grid>
                                  <Grid.Row>
                                    <Grid.Column width={2}>
                                      <Form.Checkbox
                                        inline
                                        label={translate('default.golfers-only')}
                                        checked={
                                          RentalUnitTypeIdsForEquipments.includes(category.accommodationTypeId) ||
                                          this.getIsGolfersOnly(category)
                                        }
                                        disabled={
                                          RentalUnitTypeIdsForEquipments.includes(category.accommodationTypeId) ||
                                          disableCategory
                                        }
                                        onChange={(_e, d) => this.updateIsGolfersOnly(d, index, category)}
                                      />
                                    </Grid.Column>
                                    <Grid.Column width={3}>
                                      <Form.Input
                                        type="number"
                                        name="sellPrice"
                                        disabled={!this.hasPriceCategory(category) || disableCategory}
                                        placeholder={translate('default.sale-price')}
                                        onChange={(_e, d) =>
                                          this.validatePriceCategory({ sellPriceInclVat: d.value }, index, category)
                                        }
                                        value={this.getPricingSellPrice(category)}
                                        error={!this.hasValidSellPrice(category)}
                                      />
                                    </Grid.Column>
                                    <Grid.Column width={3}>
                                      <Form.Input
                                        type="number"
                                        name="memberPrice"
                                        disabled={!this.hasPriceCategory(category) || disableCategory}
                                        placeholder={translate('default.member-price')}
                                        onChange={(_e, d) =>
                                          this.validatePriceCategory({ memberPriceInclVat: d.value }, index, category)
                                        }
                                        value={this.getPricingMemberPrice(category)}
                                        error={!this.hasValidMemberPrice(category)}
                                      />
                                    </Grid.Column>
                                    <Grid.Column width={3}>
                                      <Form.Input
                                        type="number"
                                        name="rackPrice"
                                        disabled={
                                          !this.hasPriceCategory(category) ||
                                          !RentalUnitTypeIdsForAccommodations.includes(category.accommodationTypeId) ||
                                          disableCategory
                                        }
                                        placeholder={translate('default.rack-price')}
                                        onChange={(_e, d) =>
                                          this.validatePriceCategory({ rackPriceInclVat: d.value }, index, category)
                                        }
                                        value={this.getPricingRackPrice(category)}
                                        error={!this.hasValidRackPrice(category)}
                                      />
                                    </Grid.Column>
                                    {this.hasPriceCategory(category) &&
                                      (category.accommodationTypeId === RentalUnitType.LODGE.id ||
                                        category.accommodationTypeId === RentalUnitType.ROOMS.id) &&
                                      (!this.state.groupedPricings[category.id]?.length ||
                                        this.state.groupedPricings[category.id].length < 2) && (
                                        <Button
                                          icon
                                          onClick={e => this.handleGuestPricingAddButtonClick(e, index, category)}
                                        >
                                          <Icon name="add" />
                                        </Button>
                                      )}
                                  </Grid.Row>
                                </Grid>
                              </Grid.Column>
                            )}
                          </Grid.Row>
                        )}
                      </Fragment>
                    );
                  })}
                </Grid>
              </Form.Field>
            </List.Item>
          </div>
        )}
      </Translate>
    );
  }
}

export { AccommodationScheduleViewTitleItem, AccommodationScheduleViewContentItem, AccommodationScheduleEditItem };
