import React, { Fragment } from 'react';
import { Translate } from 'react-localize-redux';

import { Form, Icon, List, Label, Message, Segment } from 'semantic-ui-react';
import { DateInput, TimeInput } from 'semantic-ui-calendar-react';
import { EditableSubListComponent } from './EditableSubListComponent';
import { isValidDate, isValidTime, todaysDate } from '../utils';

import moment from 'moment';
import 'moment/locale/sv';
import 'moment/locale/de';
// moment.locale('sv');
moment.updateLocale('en', {
  week: {
    dow: 1 // Monday is the first day of the week.
  }
});

let newItemIdCounter = -1;

export const blockedTimespanEmpty = () => {
  return {
    id: newItemIdCounter--,
    startDate: todaysDate(),
    startTime: '06:00',
    endDate: todaysDate(),
    endTime: '20:00'
  };
};

class UnavailableTeetimeViewItem extends React.Component {
  render() {
    return (
      <Fragment>
        <List>
          <List.Item>
            <Label size="medium">
              <Translate id="uteetimes.description" />
              <Label.Detail>{this.props.data.description}</Label.Detail>
            </Label>
          </List.Item>
          <List.Item>
            <Label size="medium">
              <Translate id="uteetimes.periods" />:
            </Label>
            <List size="small" bulleted>
              {this.props.data.blockedTimespans.map((x, i) => {
                return (
                  <List.Item key={i}>
                    {x.startDate} {x.startTime} - {x.endDate} {x.endTime}
                  </List.Item>
                );
              })}
            </List>
          </List.Item>
        </List>
      </Fragment>
    );
  }
}
class UnavailableTeetimeEditItem extends React.Component {
  state = {
    description: '',
    descriptionValid: true,
    blockedTimespans: [],
    blockedTimespansValid: false
  };

  componentDidMount = () => {
    const { data } = this.props;
    this.setState({ description: data.description });
    // if (!this.props.data) {
    //   return;
    // }
    // const { data } = this.props;
    // this.validateDescription(null, {value : data.description})
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { descriptionValid, blockedTimespansValid } = this.state;
    if (
      prevState.descriptionValid !== this.state.descriptionValid ||
      prevState.blockedTimespansValid !== this.state.blockedTimespansValid
    ) {
      const allValid = descriptionValid && blockedTimespansValid;
      this.props.dataIsValid(allValid);
    }
  };

  validateDescription = (event, { value }) => {
    let { description, descriptionValid } = this.state;
    description = value;
    descriptionValid = description && description.length > 0;
    this.setState({ description, descriptionValid });
  };

  subcomponentStateUpdated = (blockedTimespans, blockedTimespansValid) => {
    this.setState({ blockedTimespans, blockedTimespansValid });
  };

  saveItem = () => {
    const { description, blockedTimespans } = this.state;
    const data = { description, blockedTimespans };
    data.golfCourse = this.props.data.golfCourse;
    if (Object.prototype.hasOwnProperty.call(this.props.data, 'id')) {
      data.id = this.props.data.id;
    }
    this.props.saveAction(data, this.props.onSubmit);
  };

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <Form id={this.props.id} onSubmit={this.saveItem}>
            <List>
              <List.Item>
                <Form.Field>
                  <Form.Input
                    required
                    label={translate('uteetimes.description')}
                    type="text"
                    name="description"
                    placeholder={translate('uteetimes.description')}
                    onChange={this.validateDescription}
                    value={this.state.description}
                    error={!this.state.descriptionValid}
                  />
                </Form.Field>
              </List.Item>

              <UnavailableTimespanListComponent
                data={this.props.data.blockedTimespans}
                stateUpdated={this.subcomponentStateUpdated}
              />
              {!this.state.descriptionValid && (
                <Message color="orange" visible content={translate('uteetimes.description-missing')} />
              )}
            </List>
          </Form>
        )}
      </Translate>
    );
  }
}

class UnavailableTimespanListComponent extends React.Component {
  validate = (dataList, dataListValid) => {
    dataList.forEach((x, i) => {
      this.validateEndDatetime(dataList, dataListValid, i);
    });
  };

  allValid = (dataList, dataListValid) => {
    return dataList.length > 0 && dataListValid.every(x => this.validItem(x));
  };

  validItem = item => {
    return item.endDate && item.endTime && item.startDate && item.startTime;
  };

  emptyValidationItem = () => {
    return { startDate: true, startTime: true, endDate: true, endTime: true };
  };

  validateEndDatetime = (dataList, dataListValid, i) => {
    dataListValid[i].endDate = dataList[i].endDate >= dataList[i].startDate;
    dataListValid[i].endTime =
      dataList[i].endDate > dataList[i].startDate || dataList[i].endTime >= dataList[i].startTime;
    dataListValid[i].endTime = dataListValid[i].endTime && isValidTime(dataList[i].endTime);
    dataListValid[i].startTime = isValidTime(dataList[i].startTime);
    dataListValid[i].startDate = isValidDate(dataList[i].startDate);
    dataListValid[i].endDate = dataListValid[i].endDate && isValidDate(dataList[i].endDate);
  };

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <EditableSubListComponent
            data={this.props.data}
            listLabel={translate('uteetimes.periods')}
            itemName="time period"
            stateUpdated={this.props.stateUpdated}
            error={this.props.error}
            validate={this.validate}
            emptyItem={blockedTimespanEmpty}
            emptyValidationItem={this.emptyValidationItem}
            allValid={this.allValid}
            subComponent={<UnavailableTimespanComponent validateEndDatetime={this.validateEndDatetime} />}
          />
        )}
      </Translate>
    );
  }
}

class UnavailableTimespanComponent extends React.Component {
  validateDatetimeChange = (_e, { name, value }) => {
    const { dataList, dataListValid } = this.props;
    const propNameLength = name.startsWith('start') ? 9 : 7;
    const i = name.substring(propNameLength);
    const prop = name.substring(0, propNameLength);
    dataList[i][prop] = value;
    this.props.validateEndDatetime(dataList, dataListValid, i);
    this.props.updateState(dataList, dataListValid);
  };

  render() {
    const i = this.props.index;
    const item = this.props.dataList[i];
    const itemValid = this.props.dataListValid[i];
    return (
      <Translate>
        {({ translate }) => (
          <Segment color={item.id < 0 ? 'green' : null}>
            {this.props.dataList.length !== 1 && (
              <Icon
                style={{ float: 'right' }}
                link
                color="red"
                name="delete"
                size="large"
                onClick={e => {
                  e.preventDefault();
                  this.props.removeItem(i);
                }}
              />
            )}
            <Form.Group style={{ display: 'flex', alignItems: 'center' }}>
              <Form.Field>
                <label>Start</label>
                <DateInput
                  closable
                  dateFormat="YYYY-MM-DD"
                  name={'startDate' + i}
                  placeholder={translate('default.start-time')}
                  value={item.startDate}
                  error={!itemValid.startDate}
                  onChange={this.validateDatetimeChange}
                  localization="sv"
                />

                <TimeInput
                  closable
                  name={'startTime' + i}
                  placeholder={translate('default.start-time')}
                  value={item.startTime}
                  error={!itemValid.startTime}
                  onChange={this.validateDatetimeChange}
                />
              </Form.Field>
              <Form.Field>
                <label>End</label>
                <DateInput
                  closable
                  dateFormat="YYYY-MM-DD"
                  minDate={item.startDate}
                  name={'endDate' + i}
                  placeholder={translate('default.end-date')}
                  value={item.endDate}
                  error={!itemValid.endDate}
                  onChange={this.validateDatetimeChange}
                  localization="sv"
                />

                <TimeInput
                  closable
                  name={'endTime' + i}
                  placeholder={translate('default.end-time')}
                  value={item.endTime}
                  error={!itemValid.endTime}
                  onChange={this.validateDatetimeChange}
                />
              </Form.Field>
            </Form.Group>
          </Segment>
        )}
      </Translate>
    );
  }
}

export { UnavailableTeetimeViewItem, UnavailableTeetimeEditItem };
