import React from 'react';
import { Translate } from 'react-localize-redux';

import { Form, List, Label, Message, Radio, Table } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import { isValidDate, isValidTime, todaysDate } from '../utils';

import 'moment/locale/sv';
import 'moment/locale/de';
import { BlockedMode } from '../core/enums';
// moment.locale('sv');
moment.updateLocale('en', {
  week: {
    dow: 1 // Monday is the first day of the week.
  }
});

let newItemIdCounter = -1;

export const unavailableAccommodationEmpty = () => {
  return {
    id: newItemIdCounter--,
    startDate: todaysDate(),
    endDate: todaysDate(),
    allBlocked: true
  };
};

class UnavailableAccommodationViewItem extends React.Component {
  render() {
    return (
      <Translate>
        {({ translate }) => (
          <>
            <Table celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    <Translate id="default.description" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Translate id="default.start-date" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Translate id="default.end-date" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>Enheter</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>{this.props.data.description}</Table.Cell>
                  <Table.Cell>{this.props.data.startDate}</Table.Cell>
                  <Table.Cell>{this.props.data.endDate}</Table.Cell>
                  <Table.Cell>
                    {this.props.data.allBlocked
                      ? translate('uaccommodations.all-units-uav') // 'All rental units'
                      : this.props.data.allAccommodationsBlocked
                      ? translate('uaccommodations.all-accommodations-uav') // 'All accommodations'
                      : this.props.data.allOtherRentalUnitsBlocked
                      ? translate('uaccommodations.all-other-uav') // 'All other rental units'
                      : 'Enheter: '}
                    <Label.Detail>
                      {this.props.data.rentalUnits.map((acc, i) => {
                        let name = 'Missing name';
                        if (acc.accommodationId) {
                          const x = this.props.accommodations.find(x => x.id === acc.accommodationId);
                          name = x !== undefined ? x.name : name;
                        } else if (acc.rentalUnitId) {
                          const x = this.props.rentalUnits.find(x => x.id === acc.rentalUnitId);
                          name = x !== undefined ? x.name : name;
                        }
                        return <p key={acc.accommodationId}>{name}</p>;
                      })}
                    </Label.Detail>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </>
        )}
      </Translate>
      //   <List.Item>
      //     <Label size="medium">
      //       <Translate id="default.start-date" />
      //       <Label.Detail>{this.props.data.startDate}</Label.Detail>
      //     </Label>
      //     <Label size="medium">
      //       <Translate id="default.end-date" />
      //       <Label.Detail>{this.props.data.endDate}</Label.Detail>
      //     </Label>
      //   </List.Item>
      //   <List.Item>
      //     <Label size="medium">
      //       {this.props.data.allBlocked
      //         ? 'All rental units'
      //         : this.props.data.allAccommodationsBlocked
      //         ? 'All accommodations'
      //         : this.props.data.allOtherRentalUnitsBlocked
      //         ? 'All other rental units'
      //         : 'Rental units: '}
      //       <Label.Detail>
      //         {this.props.data.rentalUnits.map((acc, i) => {
      //           let name = 'Missing name';
      //           if (acc.accommodationId) {
      //             const x = this.props.accommodations.find(x => x.id === acc.accommodationId);
      //             name = x !== undefined ? x.name : name;
      //           } else if (acc.rentalUnitId) {
      //             const x = this.props.rentalUnits.find(x => x.id === acc.rentalUnitId);
      //             name = x !== undefined ? x.name : name;
      //           }
      //           return <p key={i}>{name}</p>;
      //         })}
      //       </Label.Detail>
      //     </Label>

      //   </List.Item>
      // </List>
    );
  }
}
class UnavailableAccommodationEditItem extends React.Component {
  state = {
    description: '',
    descriptionValid: true,
    startDate: todaysDate(),
    startDateValid: true,
    endDate: todaysDate(),
    endDateValid: true,
    blockedMode: BlockedMode.ALL_BLOCKED,
    unavailableAccommodations: [],
    unavailableAccommodationsValid: true,
    accommodationIds: [],
    rentalUnitIds: []
  };

  componentDidMount = () => {
    const { data, accommodations, rentalUnits } = this.props;
    const blockedMode = data.allBlocked
      ? BlockedMode.ALL_BLOCKED
      : data.allAccommodationsBlocked
      ? BlockedMode.ALL_ACCOMMODATIONS_BLOCKED
      : data.allOtherRentalUnitsBlocked
      ? BlockedMode.ALL_RENTAL_UNITS_BLOCKED
      : BlockedMode.SELECTED_UNITS;

    const accommodationIds = accommodations.map(x => ({ accommodationId: x.id }));
    const rentalUnitIds = rentalUnits.map(x => ({ rentalUnitId: x.id }));
    this.setState({
      description: data.description,
      startDate: data.startDate,
      endDate: data.endDate,
      unavailableAccommodations: data.rentalUnits,
      blockedMode,
      accommodationIds,
      rentalUnitIds
    });
    this.props.dataIsValid(true);
    // if (!this.props.data) {
    //   return;
    // }
    // const { data } = this.props;
    // this.validateDescription(null, {value : data.description})
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { descriptionValid, startDateValid, endDateValid, unavailableAccommodationsValid } = this.state;
    if (
      prevState.descriptionValid !== this.state.descriptionValid ||
      prevState.startDateValid !== this.state.startDateValid ||
      prevState.endDateValid !== this.state.endDateValid ||
      prevState.unavailableAccommodationsValid !== this.state.unavailableAccommodationsValid
    ) {
      const allValid = descriptionValid && startDateValid && endDateValid && unavailableAccommodationsValid;

      this.props.dataIsValid(allValid);
    }
  };

  validateDescription = (event, { value }) => {
    let { description, descriptionValid } = this.state;
    description = value;
    descriptionValid = description && description.length > 0;
    this.setState({ description, descriptionValid });
  };

  validateEndDatetime = (dataList, dataListValid, i) => {
    dataListValid[i].endDate = dataList[i].endDate >= dataList[i].startDate;
    dataListValid[i].endTime =
      dataList[i].endDate > dataList[i].startDate || dataList[i].endTime >= dataList[i].startTime;
    dataListValid[i].endTime = dataListValid[i].endTime && isValidTime(dataList[i].endTime);
    dataListValid[i].startTime = isValidTime(dataList[i].startTime);
    dataListValid[i].startDate = isValidDate(dataList[i].startDate);
    dataListValid[i].endDate = dataListValid[i].endDate && isValidDate(dataList[i].endDate);
  };

  validateDatetimeChange = (_e, { name, value }) => {
    let { startDate, startDateValid, endDate, endDateValid } = this.state;
    const isStartDate = name.startsWith('start');
    if (isStartDate) {
      startDate = value;
      startDateValid = isValidDate(startDate);
      if (startDateValid && endDate < startDate) {
        endDate = startDate;
      }
    } else {
      endDate = value;
    }
    endDateValid = isValidDate(endDate) && endDate >= startDate;
    this.setState({ startDate, startDateValid, endDate, endDateValid });
  };

  subcomponentStateUpdated = (unavailableAccommodations, unavailableAccommodationsValid) => {
    this.setState({ unavailableAccommodations, unavailableAccommodationsValid });
  };

  saveItem = () => {
    const { description, startDate, endDate, blockedMode } = this.state;
    const unavailableAccommodations = this.getUnavailableAccommodations(blockedMode);
    const allBlocked = blockedMode === BlockedMode.ALL_BLOCKED;
    const allAccommodationsBlocked = blockedMode === BlockedMode.ALL_ACCOMMODATIONS_BLOCKED;
    const allOtherRentalUnitsBlocked = blockedMode === BlockedMode.ALL_RENTAL_UNITS_BLOCKED;
    const data = {
      description,
      startDate,
      endDate,
      allBlocked,
      allAccommodationsBlocked,
      allOtherRentalUnitsBlocked,
      unavailableAccommodations
    };
    data.golfClubId = this.props.data.golfClubId;
    data.rentalUnits = unavailableAccommodations;
    if (Object.prototype.hasOwnProperty.call(this.props.data, 'id')) {
      data.id = this.props.data.id;
    }
    this.props.saveAction(data.golfClubId, data, this.props.onSubmit);
  };

  changeUnavailableAccommodation = (acc, checked, rentalUnit = false) => {
    const { unavailableAccommodations } = this.state;
    const index = rentalUnit
      ? unavailableAccommodations.findIndex(x => x.rentalUnitId === acc.id)
      : unavailableAccommodations.findIndex(x => x.accommodationId === acc.id);
    if (checked && index <= 0) {
      const unit = rentalUnit ? { rentalUnitId: acc.id } : { accommodationId: acc.id };
      unavailableAccommodations.push(unit);
    } else if (!checked && index >= 0) {
      unavailableAccommodations.splice(index, 1);
    }
    this.setState({ unavailableAccommodations });
  };

  hasAccommodationChecked = acc => {
    return this.state.unavailableAccommodations.findIndex(x => x.accommodationId === acc.id) >= 0;
  };

  hasRentalUnitChecked = acc => {
    return this.state.unavailableAccommodations.findIndex(x => x.rentalUnitId === acc.id) >= 0;
  };

  getUnavailableAccommodations = blockedMode => {
    switch (blockedMode) {
      case BlockedMode.ALL_BLOCKED:
        return [...this.state.accommodationIds, ...this.state.rentalUnitIds];
      case BlockedMode.ALL_ACCOMMODATIONS_BLOCKED:
        return this.state.accommodationIds;
      case BlockedMode.ALL_RENTAL_UNITS_BLOCKED:
        return this.state.rentalUnitIds;
      case BlockedMode.SELECTED_UNITS:
        return this.props.data.rentalUnits;
      default:
        return [];
    }
  };

  handleBlockedModeChange = (_e, { value }) => {
    this.setState({ blockedMode: value, unavailableAccommodations: this.getUnavailableAccommodations(value) });
  };

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <>
            <Form id={this.props.id} onSubmit={this.saveItem}>
              <List>
                <List.Item>
                  <Form.Field>
                    <Form.Input
                      required
                      label={translate('default.description')}
                      type="text"
                      name="description"
                      placeholder={translate('default.description')}
                      onChange={this.validateDescription}
                      value={this.state.description}
                      error={!this.state.descriptionValid}
                    />
                  </Form.Field>
                </List.Item>
                <List.Item>
                  <Form.Group style={{ display: 'flex', alignItems: 'center' }}>
                    <Form.Field>
                      <label>
                        <Translate id="default.start-date" />
                      </label>
                      <DateInput
                        closable
                        dateFormat="YYYY-MM-DD"
                        name="startDate"
                        placeholder={translate('default.start-date')}
                        value={this.state.startDate}
                        error={!this.state.startDateValid}
                        onChange={this.validateDatetimeChange}
                        localization="sv"
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>
                        <Translate id="default.end-date" />
                      </label>
                      <DateInput
                        closable
                        dateFormat="YYYY-MM-DD"
                        minDate={this.state.startDate}
                        name="endDate"
                        placeholder={translate('default.end-date')}
                        value={this.state.endDate}
                        error={!this.state.endDateValid}
                        onChange={this.validateDatetimeChange}
                        localization="sv"
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Radio
                      disabled={!this.props.accommodations?.length && !this.props.rentalUnits?.length}
                      label={translate('uaccommodations.all-units-uav')}
                      value={BlockedMode.ALL_BLOCKED}
                      onChange={this.handleBlockedModeChange}
                      checked={this.state.blockedMode === BlockedMode.ALL_BLOCKED}
                    />
                    <Radio
                      disabled={!this.props.accommodations?.length}
                      label={translate('uaccommodations.all-accommodations-uav')}
                      value={BlockedMode.ALL_ACCOMMODATIONS_BLOCKED}
                      onChange={this.handleBlockedModeChange}
                      checked={this.state.blockedMode === BlockedMode.ALL_ACCOMMODATIONS_BLOCKED}
                    />
                    <Radio
                      disabled={!this.props.rentalUnits?.length}
                      label={translate('uaccommodations.all-other-uav')}
                      value={BlockedMode.ALL_RENTAL_UNITS_BLOCKED}
                      onChange={this.handleBlockedModeChange}
                      checked={this.state.blockedMode === BlockedMode.ALL_RENTAL_UNITS_BLOCKED}
                    />
                    <Radio
                      disabled={!this.props.accommodations?.length && !this.props.rentalUnits?.length}
                      label={translate('uaccommodations.select-uav')}
                      value={BlockedMode.SELECTED_UNITS}
                      onChange={this.handleBlockedModeChange}
                      checked={this.state.blockedMode === BlockedMode.SELECTED_UNITS}
                    />
                  </Form.Group>
                  {this.state.blockedMode === BlockedMode.SELECTED_UNITS &&
                    this.props.accommodations.map((x, i) => {
                      return (
                        <Form.Checkbox
                          key={i}
                          inline
                          label={x.name}
                          onChange={(e, { checked }) => this.changeUnavailableAccommodation(x, checked)}
                          checked={this.hasAccommodationChecked(x)}
                        />
                      );
                    })}
                  {this.state.blockedMode === BlockedMode.SELECTED_UNITS &&
                    this.props.rentalUnits.map((x, i) => {
                      return (
                        <Form.Checkbox
                          key={i}
                          inline
                          label={x.name}
                          onChange={(e, { checked }) => this.changeUnavailableAccommodation(x, checked, true)}
                          checked={this.hasRentalUnitChecked(x)}
                        />
                      );
                    })}
                  {!this.state.descriptionValid && (
                    <Message color="orange" visible content={translate('uaccommodations.description-missing')} />
                  )}
                </List.Item>
              </List>
            </Form>
          </>
        )}
      </Translate>
    );
  }
}

export { UnavailableAccommodationViewItem, UnavailableAccommodationEditItem };
